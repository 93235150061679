@import '../../styles/common';

.form {
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 30px;

    .image {
      margin: 30px 16px 10px;
      max-height: 400px;
    }

    .title {
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      margin: 20px 16px;
    }

    .errorText {
      margin-top: 10px;
      font-size: 10px;
      color: $color-red1;
    }
  }

  .button {
    margin: 40px 16px 0;
    border-radius: 8px;
    font-weight: bold;
    color: $color-white;
    font-size: 16px;
    height: 60px;
  }
}