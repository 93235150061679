@import '../../../../styles/common';

.page {
  height: 100%;
  background-color: $color-gray3;

  .header {
    background-color: $color-gray3;
    font-weight: 700;
    font-size: 18px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    background-color: $color-gray3;

    .empty {
      position: absolute;
      top: 50%;
      color: $color-gray4;
    }
  }

  .moderationBlock {
    position: fixed;
    width: 100%;
  }
}