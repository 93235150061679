@import 'src/styles/common';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .starImage{
    margin-top: 163px;
    width: 160px;
    height: 160px;
  }

  .reward {
    font-weight: bold;
    font-size: 24px;
    margin-top: 135px;
  }

  .description {
    margin: 10px 0 40px 0;
    font-size: 18px;
    line-height: 21px;
  }

  .acceptButton {
    border-radius: 5px;
    width: 100%;
    height: 60px;
    margin: 0 16px 40px 16px;
    font-size: 16px;
    font-weight: bold;
    color: $color-white;
  }
}
