@import '../../styles/common';

$header_and_button_background_color: $color-aquamarin;
$background_white_color: $color-white;
$text_white_color: $color-white;
$text_black_color: $color-black;

.shop-is-not-available-page {
  background-color: $background_white_color;
  width: 100%;

  &__bottom-text {
    width: 100%;
  }

  &__content-area {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    &-caption {
      margin: 0 16px 10px 16px;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: $text_black_color;
      font-weight: bold;
    }

    &-schedule-container {
      min-height: 285px;
      width: 100%;
    }
    @media screen and (max-height: 600px) {
      &-schedule-container {
        max-height: 285px;
      }
    }
    &-description {
      font-size: 18px;
      line-height: 21px;
      text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.0305423);
      font-weight: normal;
      margin: 0 16px 40px 16px;
    }
    @media screen and (max-height: 600px) {
      &-description {
        margin: 0 16px 25px 16px;
      }
    }
    .btn-component {
      border-radius: 8px;
      height: 60px;
      width: calc(100% - 32px);
      margin: auto;
      background: $header_and_button_background_color;
      font-size: 17px;
      color: $text_white_color;
    }
  }
  @media screen and (max-height: 600px) {
    &__bottom-text {
      margin-bottom: 30px;
    }
  }
  &__button-container {
    width: 100%;
    display: flex;
    margin-top: 30px;
  }
}
