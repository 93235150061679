.notificationMessageBlock {
  display: block;
  position: fixed;
  width: 65%;
  left: 50%;
  bottom: 75px;
  z-index: 1000;
  text-align: center;
  transform: translateX(-50%);
  background-color: #262a27;
  color: #fff;
  padding: 8px 14px;
  border-radius: 10px;
}
