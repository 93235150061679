@import "src/styles/common";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .backButton {
    background: transparent;
    margin: 20px 0;

    .header-arrow {
      fill: $color_black;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 16px;
    margin-bottom: 40px;

    .title {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 10px;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    .content {
      display: flex;
      flex-direction: column;
      margin: 0 16px;
      flex: 1;

      .label {
        position: relative;
        color: $color-gray6;
        font-size: 10px;
        margin: 0;
      }


      .input {
        position: relative;
        margin-bottom: 40px;
        width: 100%;

        input::placeholder {
          color: $color-black;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          opacity: 0.2;
        }
      }
    }
  }
}
