@import '../../../../styles/common';

.card {
  width: 80%;
  height: 87px;
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  margin-bottom: 10px;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 3px rgba(0, 0, 0, 0.1);

  .infoWithCheckbox {
    display: flex;
    align-items: center;

    .checkboxContainer {
      margin-right: 10px;

      .label {
        display: flex;

        .input {
          display: none;

          &:checked ~ .checkmark:after {
            display: block;
          }

          &:checked ~ .checkmark {
            border: 1px solid $color-aquamarin;
          }
        }

        .checkmark {
          min-height: 25px;
          min-width: 25px;
          border-radius: 4px;
          border: 1px solid $color-gray4;

          &:after {
            content: '';
            display: none;
            position: relative;
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid $color-black;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
      }
    }

    .userInfo {
      .name {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 5px;
      }

      .outlet {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .status {
    font-weight: 700;
    font-size: 10px;
    border-radius: 28px;
    padding: 5px 10px;
  }

  .activeStatus {
    border: 1px solid $color-green5;
  }

  .blockedStatus {
    border: 1px solid $color-red1;
  }

  .waitingModerationStatus {
    border: 1px dashed $color-orange;
  }
}