@import 'src/styles/common';

.fadingBlock {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 15px;
  position: relative;
  height: 90px;
  margin: 0 16px 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 16px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    width: 230px;
  }

  .timeLeft {
    margin-top: 4px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: $color-white;
  }

  .arrow {
    position: absolute;
    right: 17px;
    top: 37px;
  }
}