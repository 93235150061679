@import '../../../../styles/colors';

.challengeCard {
  position: relative;
  width: 90%;
  display: flex;
  border-radius: 16px;
  padding: 10px;
  margin-bottom: 10px;
  height: 80px;

  .link {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .challenge {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    .challengeName {
      margin-top: 10px;
      color: $color-white;
      font-size: 16px;
      font-weight: bold;
    }

    .timeRemains {
      color: $color-white;
      font-size: 10px;
      font-weight: bold;
    }
  }
}
