@import 'src/styles/common';

.topWrapper {
  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    margin-bottom: 10px;
  }

  .description {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
  }
}
.middleWrapper {
  margin: 20px 0;
  position: relative;
  min-height: 203px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 360px) and (min-height: 615px) {
    margin: 40px 0;
    min-height: 297px;
  }
  @media (min-width: 375px) and (min-height: 615px) {
    margin: 40px 0;
    min-height: 343px;
  }

  img {
    max-width: 100%;
  }

  .focusWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bottomWrapper {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;

  .button :global .themedButton {
    background-color: $color-blue3;
  }
}

.spinnerWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  .spinnerBackGround {
    backdrop-filter: blur(11px);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
