@import '../../styles/common';

.knowledge-bank-article-page {
  display: flex;

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-component {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: 10px;

      .header-arrow {
        height: 21px;
        fill: $color-white;
      }
    }
  }

  &__header-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin: 0;
  }

  &__header {
    background-color: white !important;

    &-hungaryText {
      color: $color-black;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: $footer-height;
  }

  &__hungary-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    padding: 20px 0 119px 0;
    margin: 0 auto;
    width: calc(100% - 32px);
  }
}
