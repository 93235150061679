@import '../../../../styles/common';

.chatMessage {
  display: flex;

  .button {
    margin-top: 10px;
    width: 100%;
    border: 1px solid $color-white;
    border-radius: 8px;
  }

  .upperBlock {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;

    .senderName {
      margin-right: 5px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }

    .time {
      color: $color-gray5;
    }
  }

  .textBlock {
    padding: 15px;

    .image {
      width: 100%;
      margin-top: 20px;
    }

    .link {
      display: flex;
      justify-content: center;

      color: $color-white;
      text-decoration: underline;
    }
  }
}

.chatMessage.opponent {
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-gray8;
    color: $color-white;
    border-radius: 50%;
    height: 40px;
    min-width: 40px;
    margin-right: 10px;
  }

  .textBlock {
    border-radius: 3px 16px 16px 16px;
    color: $color-white;
    white-space: pre-line
  }
}

.chatMessage.myself {
  align-self: flex-end;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .textBlock {
      border-radius: 16px 16px 3px 16px;
      background-color: $color-gray3;
    }
  }
}
