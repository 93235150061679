@import "../../styles/common";

$background_white_color: $color-white;
$text_white_color: $color-white;
$text_black_color: $color-black;
$theme_border_color: $color-white;
$button_background_color: $color-white;
$border-color: $color-gray1;

.profile-settings-page {
  background-color: $background_white_color;
  width: 100%;
  overflow: hidden;

  &__input {
    margin-bottom: 35px;

    &-default {
      margin-bottom: 35px;
      opacity: 0.9;
    }

    &::placeholder {
      color: $color-white;
    }
  }
  :disabled {
    opacity: 1;
  }
  &__info-icon {
    position: absolute;
    top: 0;
    right: 10px;
  }

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-component {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: 10px;

      .header-arrow {
        height: 21px;
        fill: $color-white;
      }
    }
  }

  &__header-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: $text_white_color;
    margin: 0;
  }

  &__content {
    padding-bottom: 49px;
    .edit-profile-form {
      transition: all 0.5s linear;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &__photo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
      }
    }

    &-fields {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      margin-bottom: -5px;

      .your-data-caption {
        margin: 60px 0 30px 0;
      }
      .jti-caption {
        margin: 35px 0 30px 0;
      }
    }

    &-color-themes {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      border-bottom: 1px solid $border-color;

      &-caption {
        margin: 35px 0 0 0;
        color: $text_white_color;
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
      }

      &-selector {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        overflow: auto;
        justify-content: space-evenly;
        height: 80px;
        align-items: center;

        &-theme {
          border-radius: 50%;
          min-width: 49px;
          height: 49px;
          border: 2px solid $theme_border_color;
        }

        .selected {
          border: 5px solid $theme_border_color;
          box-sizing: border-box;
          box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.25);
        }
      }
      .btn-component {
        transition: all 0.5s linear;
        border-radius: 8px;
        height: 60px;
        width: 100%;
        background: $button_background_color;
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
        color: $text_black_color;
        margin-bottom: 60px;
      }

      .disabled {
        opacity: 0.5;
      }
    }

    &-invite-block {
      display: flex;
      flex-direction: column;
      padding: 30px 16px 15px;

      &-caption {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 15px;
      }

      .text {
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
      }

      &-code {
        height: 100px;
        display: flex;
        border: 1px dashed;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;

        &-text {
          margin: 0;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
        }
      }

      .logout-delete-account-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-caption {
          margin: 0;
          font-size: 17px;
          line-height: 20px;
        }
      }
    }
  }

  &__arrow-icon {
    position: absolute;
    top: 0;
    right: 10px;
    fill: $color-white;
    transform: rotateY(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
}
