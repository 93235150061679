@import '../../styles/common';

$header-with-balance-height: 110px;
$footer-height: calc(67px + env(safe-area-inset-bottom));

.root {
  :global {
    .header {
      position: fixed;
      top: 0;
      width: 100%;
    }

    hr {
      border-width: 0;
      height: 1px;
      background: rgb(216, 216, 216);
    }

    .content {
      padding: 1rem;
      padding-top: 120px;
      padding-bottom: 120px;
      width: 100%;
      box-sizing: border-box;
      overflow: auto;
      overflow: auto;

      img {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        border-radius: 0.5rem;
        object-fit: cover;
        aspect-ratio: 1;
      }

      .title {
        font-weight: bold;
        font-size: 1.5rem;
        color: black;
        margin: 1rem 0;
      }

      .description {
        font-weight: bold;
        font-size: 1.25rem;
        color: rgb(0, 0, 0);
        margin: 1rem 0;
      }

      .info {
        font-weight: bold;
        font-size: 1rem;
        color: rgb(125, 125, 125);
        margin: 1rem 0;
      }

      .passcode {
        border: 1px solid rgb(216, 216, 216);
        border-radius: 0.5rem;
        padding: 0.25rem 0.5rem;
        color: black;
      }

      button {
        color: white;
        border-radius: 0.5rem;
        font-weight: bold;
        font-size: 1rem;
        width: 100%;
        padding: 1rem;
      }
    }
  }
}
