.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.contentHeader {
  min-height: 68px;
}

.mainContent {
  flex-grow: 1;
  flex-direction: column;
  padding: 30px;
}

.contentFooter {
  min-height: 68px;
}
