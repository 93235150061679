@import '../../styles/common';

.invite-page {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__request {

    &-username {
      margin-top: 20px;
    }

    &-profile {
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }

    &-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 100px;
      height: 100px;
      border: 3px solid;
      border-radius: 50px;
      box-sizing: border-box;

      &-img {
        width: 100%;
        height: 100%;
        border-radius: 50px;
      }
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    &-back-button {
      background: transparent;
    }
  }

  .header-arrow {
    margin: 30px 0 0 16px;
    height: 25px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    &-invite-block {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 90%;
      position: absolute;

      &-text {
        font-size: 18px;
        line-height: 21px;
        font-weight: bold;
      }

      &-buttons {
        z-index: 2;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    &-button {
      height: 60px;
      width: calc(50% - 5px);
      color: $color-white;
      border-radius: 8px;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;

      &-accept {
        background: $color-green5
      }

      &-decline {
        background: $color-red1
      }
    }
  }
}
