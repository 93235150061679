$default_gap: 15px;

.switchButton {
  height: 44px;
  padding: 8px;
  background-color: #f2f2f2;
  flex: 1;
  margin-right: $default_gap;
  border-radius: 8px;
  border: none;
  color: #ffb480;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  &:first-child {
    margin-left: $default_gap;
  }
}

.active {
  color: #fff;
}
