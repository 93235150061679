@import '../../styles/common';

.container {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 16px;
  border-bottom: 1px solid $color-gray3;

  .icon {
    width: 40px;
    height: 40px;
  }

  .name {
    width: 100%;
    margin-left: 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .unreadBadge {
    position: unset;
  }
}
