@import '../../styles/common';

.product-info-block {
  display: flex;
  flex-direction: column;
  background: $color-white;
  padding: 0 16px;
  align-items: center;
  justify-content: center;

  &__price-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__price {
    font-weight: bold;
    width: 100%;
    font-size: 36px;
    line-height: 42px;
    margin: 30px 20px;

    &-promo {
      font-weight: bold;
      width: 100%;
      font-size: 36px;
      line-height: 42px;
      color: $color_red1;
      margin: 30px 20px;
    }
  }

  &__sale {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95px;
    height: 21px;
    font-weight: 500;
    font-size: 11px;
    border-radius: 16px;
    color: $color-white;

    &Icon {
      margin-right: 5px;
    }
  }

  &__product_name {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__description {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }

  &__button {
    height: 60px;
    width: 100%;
    border-radius: 8px;
    background: $color-aquamarin;
    font-size: 17px;
    font-weight: 700;
    color: $color-white;
  }

  &__button-disabled {
    height: 60px;
    width: 100%;
    border-radius: 8px;
    background: $color-aquamarin;
    font-size: 17px;
    font-weight: 700;
    color: $color-white;
    opacity: 0.5;
  }

  &__non-promo-price {
    font-size: 14px;
    line-height: 16px;
    color: $color_black;
    margin-top: 5px;
    opacity: 0.2;
    text-decoration-line: line-through;
  }
  &__remains_amount {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    border-radius: 20px;
    background-color: rgba(255, 228, 232, 1);
  }
  &__remains_amount_count {
    margin-left: 10px;
    font-weight: 800;
    color: rgba(255, 63, 91, 1);
  }
}
