@import 'src/styles/common';

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input {
      text-align: center;
      border-radius: 16px;
      width: 64px;
      height: 80px;
      padding: 0;
      border: none;
      background-color: $color-gray3;
    }

    .dot {
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      top: 73px;
      left: 67px;

      &.dotWithFourInputs {
        left: 141px;
      }
    }

    .passedQuestionDot {
      left: 69px;
      &.dotWithFourInputs {
        left: 145px;
      }
    }

    .questionHint {
      margin-top: 20px;
    }

    .correctAnswer {
      border: 1px solid $color-green5;
    }

    .incorrectAnswer {
      border: 1px solid $color-red1;
    }
  }
}
