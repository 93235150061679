@import '../../../styles/common';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  .title {
    color: $color-black;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .imgContainer {
    margin-bottom: 30px;

    .image {

    }
  }

  .description {
    margin: 10px 0 40px 0;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  .acceptButton {
    width: 100%;

    .buttonComponent {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-size: 16px;
      font-weight: 600;
      line-height: 120%;
    }
  }
}