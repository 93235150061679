.progressBarContainer {
  margin-top: 16px;
}

.progressBar {
  display: flex;
  height: 18px;
  border: 0.5px solid rgb(194, 189, 189);
  border-radius: 12px;
}

.colorProgress {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  border-radius: 12px;
  background-image: linear-gradient(to right, #de0909, #a7a70b, #28b328);
}

.notAchievedProgress {
  background: #ededed;
  height: '100%';
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.goalProgress {
  display: flex;
  position: relative;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.progressBadgeContainer {
  position: relative;
  height: 32px;
}

.progressBadge {
  position: absolute;
  width: 32px;
  height: 32px;
  z-index: 10;
  left: -20px;
}
