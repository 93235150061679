@import 'src/styles/common';

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;

  .backButton {
    background: transparent;
    margin: 20px 0;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 16px;

    .textWrapper {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 40px;

      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 120%;
        margin: 0 0 20px;
      }

      .description {
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        margin: 0;
      }
    }

    .button {
      margin-bottom: 65px;
      height: 60px;
      width: 100%;
      border-radius: 8px;
      font-weight: bold;
      font-size: 17px;
      color: $color-white;
    }
  }
}