@import 'src/styles/common';

.navigationWrapper {
  background-color: $color-white;
}

.chat {
  flex: 1;

  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 16px;

  .loaderContainer {
    height: 100%;
  }
}
