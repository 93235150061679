@import '../../../styles/common';

.radioBlock {
  display: flex;
  flex-direction: column;

  .radioContainer {
    border-radius: 8px;
    padding: 20px 15px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      display: none;

      &:checked ~ .radioCheckmark {
        border-color: $color-black;

        &:after {
          display: block;
        }
      }
    }

    .radioCheckmark {
      min-height: 24px;
      min-width: 24px;
      background-color: $color-white;
      border-radius: 50%;

      &:after {
        top: 7px;
        left: 7px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $color-black;

        content: '';
        display: none;
        position: relative;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .radioValue {
      margin-left: 10px;
    }
  }

  .invalidInput {
    border: 1px solid $color-red1;
    padding: 19px 14px;
  }
}

.errorMessage {
  position: absolute;
  color: $color-red1;
  font-size: 11px;
  line-height: 13px;
}
