@import '../../styles/common';

$text_white_color: $color-white;

.transfer-to-phone-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__counter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 72px);
    margin-top: 55px;
    font-size: 18px;
    line-height: 21px;
    text-align: center;

    &-rate {
      &-coins {
        font-size: 12px;
        line-height: 14px;
        margin: 0 0 4px 0;
      }

      &-money {
        font-size: 16px;
        line-height: 19px;
        margin: 0;
      }
    }
  }

  &__btn {
    margin: 50px 0 20px;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    font-size: 17px;
    color: $text_white_color;

    &-disabled {
      margin: 50px 0 20px;
      width: 100%;
      height: 60px;
      border-radius: 8px;
      font-size: 17px;
      color: $text_white_color;
      opacity: 0.5;
    }
  }
  &__amountIcons {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 72px);
    margin-top: 50px;

    &-icon {
      padding: 10px 0;
      width: calc((100% - 20px) / 3);
      border: 1px solid $color-green1;
      border-radius: 15px;
      text-align: center;
      background: none;

      &:disabled {
        color: $color-black;
        opacity: 0.2;
      }
    }
  }
}
