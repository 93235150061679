@import '../../../../styles/colors';

.personalChallenge {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .titleBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .personalChallengeTitle {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      margin-bottom: 10px;
    }

    .personalChallengeDescription {
      font-size: 14px;
      text-align: center;
    }
  }
  .challengePhoto {
    margin: 30px 0;
    width: 130px;
  }
}
