@import 'src/styles/common';

.wrapper {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 0;

  .spinnerWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 100;

    .spinnerBackGround {
      backdrop-filter: blur(11px);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .arButton {
    position: absolute;
    left: calc(50% - 29px);
    top: calc(50% - 29px);
    width: 58px;
    height: 58px;
  }

  model-viewer {
    margin: auto;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    display: block;

    .bottomWrapper {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 50px;
      z-index: 0;

      .infoWrapper {
        display: block;
        min-height: 56px;
        max-width: 281px;
        margin: 0 auto;
        border-radius: 8px;
        background-color: $color-white;
        overflow: hidden;
        color: $color-black;
        padding: 15px;
        text-align: left;
        letter-spacing: 0px;
        position: relative;
        margin-bottom: 10px;

        .title {
          font-size: 12px;
          line-height: 20px;
          font-weight: 700;
          padding-bottom: 5px;
          position: relative;
        }

        .description {
          font-size: 10px;
          line-height: 12px;
          font-style: normal;
          font-weight: 400;
          display: grid;
          grid-template-columns: 40% 60%;
          grid-gap: 3px 10px;
          position: relative;
          z-index: 1;

          .strong {
            font-weight: 700;
          }
          .weak {
            font-weight: 500;
            color: $color-black;
          }
        }

        .backGroundBlock {
          position: absolute;
          top: 0px;
          width: 100%;
          right: 0;
          z-index: 0;
        }

        .nextButton {
          background-image: url('../../images/next-button_active.svg');
          width: 11px;
          height: 18px;
          background-repeat: no-repeat;
          position: absolute;
          right: 10px;
          bottom: 5px;
        }
      }

      .button :global .themedButton {
        background-color: $color-blue3;
      }
    }
  }
}
