@import '../../../styles/common';

.no-bonus-survey {
  &__page {
    width: 100%;
    background-color: $color-white;
  }
  &__header {
    background-color: $color-green1;
  }
  &__btn-container {
    width: 100%;
    display: flex;
    &-btn-component {
      border-radius: 8px;
      height: 60px;
      width: 100%;
      margin: auto;
      background: $color-green1;
      font-size: 17px;
      font-weight: bold;
      color: $color-white;
    }
  }
  &__content-area {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    &-schedule-container {
      min-height: 285px;
      width: 100%;
    }
    &-bottom-text {
      width: calc(100% - 32px);
      text-align: center;
      &-caption {
        margin: 0 16px 10px;
        font-size: 22px;
        line-height: 26px;
        color: $color-black;
        font-weight: 700;
      }
      &-description {
        font-size: 16px;
        line-height: 21px;
        text-shadow: 0 5px 5px $color-shadow1;
        font-weight: 400;
        margin: 0 16px 40px;
      }
    }
  }
}

@media screen and (max-height: 600px) {
  .no-bonus-survey {
    &__content-area {
      &-schedule-container {
        max-height: 285px;
      }
      &-bottom-text {
        margin-bottom: 30px;
        &-description {
          margin: 0 16px 25px 16px;
        }
      }
    }
  }
}
