@import '../../../styles/common';

.container {
  display: flex;

  .headerText {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: $color-white;
    margin: 0;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: $footer-height;

    .unavailableContent {
      display: flex;
      align-items: center;
      justify-content: center;

      .description {
        font-size: 18px;
        line-height: 21px;
        color: $color-gray4;
        max-width: calc(100% - 32px);
        text-align: center;
      }
    }
  }
}