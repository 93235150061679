.goalInfoContainer {
  display: flex;
  flex-direction: column;
}

.goalTrackerTitle {
  margin: 12px 0 16px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}

.goalInformation {
  display: flex;
  justify-content: space-between;
}

.goalsDescription {
  display: flex;
}

.goalDate {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 35%;

  img {
    width: 32px;
    height: 32px;
  }
}

.dateTitle {
  font-size: 9px;
  color: #848484;
  font-weight: 900;
}

.progressTitle {
  margin-top: 16px;
  line-height: 14px;
}

.purchasingTitle {
  font-size: 12px;
}

.lastUpdate {
  font-size: 8px;
  color: #848484;
}

@media screen and (max-width: 360px) {
  .goalInformation {
    display: flex;
    flex-direction: column;
  }
  .goalDate {
    margin-top: 16px;
    width: auto;
    justify-content: flex-start;
  }
}
