@import './../../../../styles/colors';

.challengesPage {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .headerTitle {
    color: $color-white;
    font-size: 18px;
    font-weight: bold;
  }

  .challengeCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px 73px;
  }

  .loaderContainer {
    height: 100%;
    flex: 1;
  }
}
