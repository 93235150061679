$space_between_elements: 2px;
.goalDetailsContainer {
  display: flex;
  margin-top: 3px;
  line-height: 18px;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.goalNumber {
  text-align: right;
  display: inline-block;
  margin-right: $space_between_elements;
}

.goalAmount {
  margin-right: $space_between_elements;
  display: inline-block;
  font-weight: 900;
}

.goalReward {
  display: inline-block;
  margin-right: $space_between_elements;
  font-weight: 900;
}

.goalWrapper {
  display: flex;
  white-space: nowrap;
  min-width: 120px;
  font-size: 14px;
}

.rewardWrapper {
  display: flex;
  white-space: nowrap;
  font-size: 14px;
}

.rewardIcon {
  margin-right: $space_between_elements;
}
