@import '../../styles/common';

$background_color: $color-white;
$text_color: $color-black;

.brand-block {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  height: 160px;
  border-radius: 8px;
  margin-bottom: 15px;
  position: relative;

  &__layer {
    width: 100%;
    height: 160px;
    border-radius: 8px;
    position: absolute;
  }

  &__name {
    opacity: 1;
    z-index: 1;
    height: 40px;
    font-size: 32px;
    font-weight: bold;
    line-height: 38px;
    margin: 20px 0 45px 15px;
    max-width: 260px;
    &__question_of_the_day {
      max-width: 160px;
    }
  }

  &__footer {
    opacity: 1;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;

    &-info {
      display: flex;
      align-items: center;

      &-bonus-games-amount {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 5px;
        width: 37px;
        height: 27px;
        background: $background_color;
        color: $text_color;
        border-radius: 0px 15px 15px 0px;

        &-caption {
          font-size: 14px;
          font-weight: bold;
          line-height: 17px;
          margin-right: 5px;
        }
      }

      &-caption {
        font-size: 14px;
        line-height: 16px;
      }

      &-no-bonus-games-caption {
        margin-left: 15px;
        max-width: 200px;
      }
      &-question-of-the-day-caption {
        margin-left: 15px;
        max-width: 150px;
      }
    }

    &-logo {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      margin-right: 16px;
      margin-top: -30px;
      max-width: 90px;
      max-height: 60px;
    }
  }
}

@media screen and (max-width: 340px) {
  .brand-block {
    &__footer-info-no-bonus-games-caption {
      font-size: 11px;
    }
  }
}
