@import '../../../../styles/common';

.notifications {
  flex: 1;

  position: relative;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  .noItemsLabel {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $color-gray4;
  }

  .loaderContainer {
    flex: 1;
  }
}
