@import '../../../styles/common';

.OPPA-SMS-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-weight: bold;
    text-align: center;
    margin: 30px 20px 0;
    font-size: 24px;
    line-height: 29px;
  }

  &__form {
    display: flex;
    flex-flow: column;
    background: inherit;
    align-items: center;
    flex-grow: 1;

    &-input-error {
      width: 30px;
      text-align: center;
      border-color: $color_red;
    }

    &-error {
      color: $color-red1;
      font-size: 12px;
    }
  }

  &__btn {
    width: calc(100% - 32px);
    height: 60px;
    margin-top: 30px;
    border-radius: 8px;
    color: $color_white;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    border: none;

    &:disabled {
      opacity: 0.2;
    }
  }

  &__codeInput {
    width: 30px;
    text-align: center;
    border-color: $color_green1;
  }
}
