@import '../../../styles/common';

$border_color: $color-aquamarin;
$background_white_color: $color-white;

.transfer-tutorial-first-step {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 600;

  &__skip-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 62px;
    height: 29px;
    right: 20px;
    top: 60px;
    
    background: $background_white_color;
    border-radius: 42px;

    &-content {
      display: flex;

      &-text {
        margin: 0 6px 0 0;
        font-size: 10px;
        line-height: 100%;
      }
    }
  }

  &__content {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__description {
    width: calc(100% - 64px);
    padding: 0 16px;
    margin-bottom: 30px;
    background: $background_white_color;
    border: 1px solid $border_color;
    box-sizing: border-box;
    border-radius: 16px;

    &-title {
      margin: 50px 0 0 0;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }
    &-text1 {
      margin: 10px 0 0 0;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
    }
    &-text2 {
      margin: 20px 0 50px 0;
      font-size: 12px;
      line-height: 13px;
      text-align: center;
    }
  }
  &__btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.start-button {
  height: 50px;
  width: calc(100% - 96px);
  font-weight: bold;
  border-radius: 8px;
  background: $background_white_color;
  font-size: 14px;
  line-height: 17px;
}
