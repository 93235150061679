@import '../../../styles/common';

.esign-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__image {
    width: 100%;
    max-height: 466px;
    height: auto;
    margin-top: 100px;
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-top: 10px;
    text-align: center;
  }
  &__description {
    margin-top: 10px;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  &__btn {
    width: 100%;
    border-radius: 8px;
    background-color: $color_green1;
    color: $color_white;
    height: 60px;
    margin: 30px 0 20px 0;
    font-size: 17px;
    line-height: 20px;
  }
}

@media screen and (max-height: 570px) {
  .esign-modal {
    &__image {
      margin: auto;
      margin-top: 20px;
      height: 200px;
      width: 200px;
    }
  }
}

@media screen and (max-height: 670px) {
  .esign-modal {
    &__image {
      margin: auto;
      margin-top: 20px;
      height: 250px;
      width: 250px;
    }
  }
}
