.root {
  position: absolute;
  color: white;
  opacity: 0.3;
  top: 0.2rem;
  left: 0.2rem;
  z-index: 999;
  pointer-events: none;
  font-size: 8px;
  font-weight: bold;
}
