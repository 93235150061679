.container {
  .backButton {
    background: transparent;
    position: absolute;
    top: 25px;
    left: 0;
  }

  .image {
    width: 100%;
  }
}