@import '../../../../styles/common';

.support {
  position: relative;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  .createTicketButton,
  .tickets > * {
    margin-bottom: 11px;
  }

  .createTicketButton {
    margin-top: 30px;
    margin-bottom: 11px;
  }

  .tickets {
    flex: 1;
    display: flex;
    flex-direction: column;

    .noItemsLabel {
      flex: 1;
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 14px;
      color: $color-gray4;
    }
  }

  .loaderContainer {
    height: 100%;
  }
}
