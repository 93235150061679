@import '../../styles/common';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  :global {
    .balance-block {
      position: static !important;
    }

    .gallery {
      display: flex;
      width: 100%;
      height: 100%;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      padding-bottom: $header-height;
    }
    .gallery-item {
      p {
        margin: 0.5rem auto;
      }

      scroll-snap-align: start;
      min-width: 90%;
      height: 100%;

      padding: 1rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .image {
        flex: 2;
        object-fit: cover;
        border-radius: 0.5rem;
        background-size: cover;
        background-position: center;
      }

      button {
        height: 60px;
        width: 100%;
        border-radius: 8px;
        font-size: 17px;
        font-weight: 700;
        color: $color-white;
        border: none;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }

      .name {
        font-size: 24px;
        font-weight: bold;
      }

      .price {
        font-weight: bold;
        font-size: 36px;
      }

      .description {
        font-size: 16px;
      }

      .info {
        display: flex;
        flex-direction: column;
        flex: 3;
        justify-content: space-between;
      }
    }
  }
}
