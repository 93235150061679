@import '../../styles/common';

$text_white_color: $color-white;
$link_color: $color-aquamarin;

.two-buttons-prompt-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__header {
    margin: 0 auto;
    text-align: center;
    width: 65%;
    font-weight: 900;
  }
  &__description {
    margin-top: 24px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    &__image {
      margin: 0 auto;
      border-radius: 14px;
      margin-top: 16px;
      width: 145px;
      height: 120px;
    }
  }
  &__link {
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: $link_color;
  }

  &__btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 5px;

    &__btn {
      margin: 30px 0 20px;
      width: 100%;
      height: 60px;
      border-radius: 8px;
      font-size: 17px;
      color: $text_white_color;
    }
    &__second-btn {
      margin: 30px 0 20px;
      width: 100%;
      height: 60px;
      border-radius: 8px;
      font-size: 17px;
      background-color: $color-white;
      border: 1px solid;
    }
  }
}
