@import '../../../styles/common';

.header {
  transition: all 0.5s linear;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $header-height;

  .backButton {
    background-color: transparent;
  }
}

.withoutBack {
  justify-content: center;
}
