@import "../../../../styles/common";

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  .title {
    color: $color-white;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
  }

  .successfulWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 21px;

    .successful {
      position: relative;
      font-weight: 400;
      font-size: 10px;
      line-height: 100%;
      color: $color-white;
      margin-bottom: 13px;

      &:before {
        position: absolute;
        content: url("../../../../images/completed.png");
        width: 16px;
        height: 16px;
        top: -4px;
        left: -21px;
      }
    }
  }

}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed $color-white;
  position: relative;
  border-radius: 16px;
  width: 278px;
  height: 278px;
  margin-bottom: 25px;


  .plus {
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      position: absolute;
      top: 30%;
      left: 50%;
      content: '';
      width: 4px;
      height: 68px;
      background: $color-white;
    }

    &:before {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: rotate(90deg);
      content: '';
      width: 4px;
      height: 68px;
      background: $color-white;
    }
  }

  .textBlock {
    display: flex;
    position: absolute;
    bottom: 17%;
    margin: 0 auto;
    color: $color-white;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    flex-direction: column;

    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }

    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
}