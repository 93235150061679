@import '../../../styles/colors';

.inputModal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .description {
    margin-top: 24px;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    font-weight: 400;
  }

  .input {
    :global {
      .input-block__input {
        background: $color-gray1;
        color: $color-black;
        height: 42px;
        margin-top: 35px;
        padding-left: 13px;
        border-radius: 8px;
        border-bottom: 0;
        padding-bottom: 0;
      }

      .input-block__input-invalid {
        background: $color-gray1;
        color: $color-black;
        height: 40px;
        margin-top: 35px;
        padding-left: 12px;
        padding-bottom: 0;
        border-radius: 8px;
        border: 1px solid $color-red;
      }

      .input-block__warning {
        display: none;
      }

      .input-block__warning-message {
        color: $color-red;
        margin-top: 5px;
      }
    }
  }

  .button {
    margin: 40px 0 20px;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    font-size: 17px;
    color: $color-white;
  }
}
