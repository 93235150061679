@import '../../../styles/common';

.container {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: $footer-height;

  .header {
    margin-bottom: 10px;
    background-color: $color-white;

    .text {
      color: $color-black;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .sliderWrapper {
    margin: 0 16px;

    .bullet {
      width: 60px;
      height: 5px;
      display: inline-block;
      border-radius: 10px;
      opacity: 0.5;
      background: #dadada;
      margin: 0 5px;
    }

    .activeBulletWinston,
    .activeBulletCamel,
    .activeBulletLd,
    .activeBulletJti,
    .activeBulletBenson{
      opacity: 1;
    }

    .activeBulletWinston {
      background: $color-blue2;
    }

    .activeBulletCamel {
      background: $color-camel;
    }

    .activeBulletLd {
      background: $color-ld;
    }

    .activeBulletJti {
      background: $color-green1;
    }

    .activeBulletBenson {
      background: #C6C6C6;
    }

    .bullets {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      top: 0;
    }

    .swiperImage {
      border-radius: 10px;
      margin: 20px 0;
      width: 315px;
    }
  }

  .buttonWrapper {
    position: relative;

    .button {
      display: flex;
      align-items: flex-start;
      color: $color-white;
      border: none;
      width: calc(100% - 32px);
      margin: 30px auto;
      padding: 10px 0 10px 20px;
      border-radius: 8px;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
    }

    .arrow {
      position: absolute;
      top: 42px;
      right: 34px;
    }
  }
}
