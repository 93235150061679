@import "src/styles/common";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .backButton {
    background: transparent;
    margin: 20px 0;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 40px 0 60px 16px;

    .title {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 10px;
    }

    .description {
      font-size: 16px;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1;

    .inputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .wrapper {
        display: flex;
        width: 90%;

        .answerInput {
          display: none;

          &:checked + .label {
            height: 62px;
            width: calc(100% - 2px);
            padding-left: 49px;
          }
        }

        .label {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 64px;
          width: 100%;
          border-radius: 8px;
          background-color: $color-gray3;
          margin-bottom: 10px;
          padding-left: 50px;

          &:after {
            position: absolute;
            content: '';
            width: 24px;
            height: 24px;
            background: $color-white;
            border-radius: 44px;
            top: 20px;
            left: 17px;
          }

          &:before {
            top: 27px;
            left: 24px;
            width: 10px;
            height: 10px;
            position: absolute;
            content: '';
            z-index: 1;
            border-radius: 44px;
          }
        }
      }
    }
  }

  .nextButton {
    margin-bottom: 65px;
    margin-right: 10px;
    background: transparent;

    &:disabled {
      opacity: 0.2;
    }
  }
}