@import 'src/styles/common';

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  margin: 0 16px;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      width: 343px;
      height: 343px;
    }

    .textWrapper {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
        margin: 40px 0 10px;
      }

      .description {
        font-weight: normal;
        font-size: 18px;
        line-height: 120%;
        margin-bottom: 40px;
        text-align: center;
      }
    }
  }

  .button {
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    border-radius: 8px;
    color: $color-white;
    height: 60px;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 40px;
  }
}