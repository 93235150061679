@import '../../../../styles/colors';

.container {
  position: relative;
  border: 1px solid $color-gray3;
  border-radius: 16px;
  padding: 20px;
  margin-top: 10px;

  .task {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .taskTitle {
      font-weight: bold;
      margin-bottom: 10px;
      width: 100%;
    }

    .statusIcon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .taskButton {
    width: 70%;
  }

  .moderationTitle {
    color: $color-gray4;
    font-size: 10px;
    margin-top: 3px;
  }
}

.container.checking {
  border-color: $color-orange;
}

.container.completed {
  border-color: $color-green5;
}

.container.uncompleted {
  border-color: $color-gray3;
}
