@import '../../../styles/common';

.survey-page {
  min-height: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;

  .quiz-page {
    &__game-question-text {
      flex-direction: column;
      justify-content: flex-end;
      min-height: 53px;
    }
  }

  &__game {
    width: calc(100% - 32px);
    margin: 0 16px 0 16px;
  }

  &__leave-game {
    display: flex;
    margin: auto;
    padding-top: 30px;
    font-size: 12px;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: $color-white;
  }

  &__leave-game-icon {
    fill: $color-white;
    width: 9px;
    height: 9px;
    margin-right: 9px;
  }

  &__final-screen {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: calc(100% - 32px);
  }

  &__survey-headline {
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    border-bottom: 2px solid $color-white;
  }

  &__survey-text {
    color: $color-white;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &__reward-block {
    padding-top: 70px;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 43px;
    color: $color-white;
  }

  &__done-block {
    width: 100%;
    margin-top: 185px;
    color: $color-white;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  &__result-btn {
    width: 100%;
    background: $color-white;
    height: 60px;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: $footer-height;
  }

  &__questions-count {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    color: $color-white;
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
  }

  &__next-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    width: 65px;
    height: 30px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    background: $color-white;
    border-radius: 40px;
    color: $color-green1;

    &__disabled {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $color-white;
      opacity: 0.2;
    }
  }

  &__next-icon {
    fill: $color-green1;
    margin-left: 5px;
    transform: rotate(180deg);
    height: 12px;
    width: 7px;

    &__disabled {
      fill: $color-green1;
      transform: rotate(180deg);
      height: 12px;
      width: 7px;
    }
  }
}

@media screen and (min-height: 625px) {
  .survey-page {
    height: 100%;
  }
}
