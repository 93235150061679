@import "src/styles/common";

.container {
  background-image: url('../../../images/background-images/mystery-shopper-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: calc(100vh - 68px);
  padding-top: $header-height;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .backButton {
    background: transparent;
    position: absolute;
    top: 25px;
    left: 10px;
  }

  .generalScore {
    height: 108px;
    margin-top: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    font-weight: 400;
    font-size: 54px;
    line-height: 120%;
    color: $color-white;

    &:after {
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translate(-50%, -50%);
      content: url("../../../images/general_score.png");
      width: 108px;
      height: 122px;
    }
  }

  .title {
    margin: 50px 0 30px;
    width: 100%;
    font-weight: 400;
    font-size: 50px;
    line-height: 120%;
    color: $color-white;
    text-align: center;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: $color-white;
    text-align: center;
    margin: 0 16px 70px;
  }

  .criteriaBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0 32px;
  }

  .button {
    width: calc(100% - 32px);
    height: 60px;
    background: $color-white;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 50px;
  }
}