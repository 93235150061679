@import "src/styles/common";

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-image: url("../../../images/background-images/bg-chance-ireland.png");
  padding-left: 15px;
  position: relative;
  height: 67px;
  margin: 20px 16px 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 8px;


  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $color-white;
    margin-bottom: 3px;
  }

  .description {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $color-white;
  }

  .arrow {
    position: absolute;
    right: 21px;
    top: 40%;
  }
}