@import '../../../../styles/colors';

.teamChallenge {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatarsBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 0 5px;
      border: 1px solid $color-black;
    }
  }

  .titleBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .teamChallengeTitle {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      margin-bottom: 10px;
    }

    .teamChallengeDescription {
      font-size: 14px;
      text-align: center;
    }

    .challengePhoto {
      margin-top: 30px;
      width: 130px;
    }
  }
}
