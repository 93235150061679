@import 'src/styles/common';

.wrapper {
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: $color-white;
  font-size: 12px;
  line-height: 1em;
  cursor: pointer;
  border: 1px solid rgba($color-white, 0.4);
  display: inline-block;
}
