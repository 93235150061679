.wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    .container {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 3% 7%;
        position: absolute;
        width: 200%;
        animation: translating 6s linear infinite;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);

        .opponentImage {
            width: 100px;
            height: 100px;
            margin-top: 0;

            &:nth-child(even) {
                padding-top: 30px;
            }
        }
    }
}

@keyframes translating {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-700px, 0);
    }
}