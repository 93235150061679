@import '../../../styles/common';

.interactiveArticlePageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .backButton {
    position: relative;
    display: flex;
    background: transparent;
    margin: 20px 0;
    width: 50px;
    height: 21px;

    svg {
      fill: $color_black;
    }
  }

  .plug {
    border-radius: 50%;
    background-color: $color-gray3;
    height: 100%;
    width: 100%;
    padding: 0 5px;
  }

  .formContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    .delimiter {
      display: flex;
      height: 1px;
      background: $color-black;
      opacity: 0.05;
      width: 90%;
      margin: 50px auto 0;
    }

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 60px 16px 0 16px;

      .title {
        font-size: 28px;
        font-weight: bold;
      }

      .description {
        margin-top: 20px;
        margin-bottom: 50px;
        text-align: start;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
      }

      .inputContainer {
        height: 44px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        border: none;
        color: $color-black;
        font-weight: normal;
        font-size: 16px;

        ::placeholder {
          position: relative;
          opacity: 0.3;
        }

        input {
          height: 44px;
          border: none;
          padding: 0 0 0 15px;
          color: $color-black;
        }

        :global {
          .input-block__warning-message {
            color: $color-red1;
            margin-top: 5px;
            padding-left: 0;
          }
          .input-block__warning {
            display: none;
          }
          .input-block__input-invalid {
            border: $color-red1 1px solid;
            border-radius: 8px;
          }
        }
      }

      .selectContainer {
        height: 44px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        margin-bottom: 10px;
        margin-top: 15px;

        .selectInput {
          background-image: url('../../../images/select-arrow-down-black.png');
          background-position: 95% 50%;
          background-size: 21px 13px;
          background-repeat: no-repeat;
          height: 44px;
        }

        select {
          color: $color-black;
          border: none;
          width: 100%;
          height: 100%;
          padding-left: 15px;
          padding-bottom: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: transparent;
        }

        :global {
          .select-block__warning-message {
            color: $color-red1;
            margin-top: 5px;
            padding-left: 0;
          }
          .select-block__select-invalid {
            border: $color-red1 1px solid;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .btnContainer {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 80px;
    justify-content: center;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $color-black 252.33%);

    .nextButton {
      width: 90%;
      height: 60px;
      background-color: $color-white;
      border: none;
      border-radius: 8px;
      font-size: 17px;
      font-weight: bold;
    }
  }
}
