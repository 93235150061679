@import "../../../styles/common";

$button_background_color: $color-green3;
$text_white_color: $color-white;
$text_black_color: $color-black;
$button_white_background_color: $color-white;

.check-data-page {
  padding: 0 16px 0 16px;
  background: inherit;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  &__info-icon {
    position: absolute;
    top: 0;
    right: 10px;
  }
  &__account-code-container {
    position: relative;
  }

  &__top-section {
    margin-bottom: 5px;
  }

  &__bottom-section {
    display: flex;
    flex-flow: column;
    margin-bottom: 25px;
    justify-content: flex-end;
  }

  &__back-button {
    background: transparent;
    margin-top: 56px;
    border: 0;
    padding: 0;
  }
  :focus {
    outline: none;
  }

  &__top-arrow {
    height: 21px;
    fill: $color-white;
  }

  &__top-arrow:hover {
    cursor: pointer;
  }

  &__title {
    margin: 43px 0 50px;
    font-weight: 900;
    font-size: 32px;
    line-height: 37px;
    color: $color-white;
  }
  &__caption {
    color: $color-white;
    font-size: 10px;
    line-height: 12px;
    margin: 0 0 5px 0;
  }

  &__input {
    margin-bottom: 25px;

  }

  ::placeholder {
    color: $color-white;
    opacity: 0.8;
  }

  &__submit-btn {
    justify-self: flex-end;
    align-self: flex-end;
    width: 51px;
    height: 51px;
    padding: 0;
    background: $color-white;
    border-radius: 50%;
  }

  &__submit-btn:disabled {
    opacity: 0.3;
  }
}
.found-error-button {
  border: 1px solid $text_white_color;
  min-height: 60px;
  width: calc(100% - 32px);
  border-radius: 8px;
  background: transparent;
  margin: 0 0 10px 16px;
  font-size: 17px;
  font-weight: 700;
  color: $text_white_color;
}

.all-correct-button {
  min-height: 60px;
  width: calc(100% - 32px);
  margin: 0 0 65px 16px;
  border-radius: 8px;
  background: $button_white_background_color;
  font-size: 17px;
  font-weight: 700;
}
