@import 'src/styles/common';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .error {
    font-size: 10px;
    color: $color-red1;
  }

  .wrapper {
    .answerInput {
      display: none;

      &:checked + .label {
        padding: 4px 9px;
      }
    }

    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-gray3;
      padding: 5px 10px;
      width: 288px;
      height: 40px;
      border-radius: 8px;
      margin-bottom: 10px;
    }

    .correctAnswer {
      background-color: $color-green5;
    }

    .incorrectAnswer {
      &:checked + .label {
        padding: 5px 10px;
        background-color: $color-red1;
        border: none;
      }
    }
  }
}
