@import '../../../../styles/common';

.badge {
  height: 70px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: $color-white;
  padding: 0 20px;

  .text {
    font-weight: 700;
    font-size: 16px;

    .count {
      color: $color-aquamarin;
    }
  }

  .button {
    height: 40px;
    width: 40px;
    background-color: $color-gray2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow {
      transform: rotate(-90deg);
      height: 22px;
    }
  }
}