@import '../../styles/common';

$text_grey_color: $color_gray4;

.prizeContainer {
  padding-bottom: $footer-height;

  .pickupPointDetails {
    font-size: 12px;
    color: $text_grey_color;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    height: 100%;
    gap: 0.25rem;

    p {
      margin: 0;
    }

    div {
      padding: 0.25rem 1rem;
      background-color: $color_gray3;
      border-radius: 1rem;
      box-sizing: border-box;
    }

    a {
      font-weight: bold;
      color: $color-black;
    }
  }

  .empty {
    height: 100%;
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

    .description {
      font-size: 18px;
      line-height: 21px;
      color: $text_grey_color;
      max-width: calc(100% - 32px);
      text-align: center;
    }
  }

  .blockWrapper {
    min-height: 80px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    border-radius: 8px;

    &.border {
      border: 1px solid $color-gray12;
      height: 115px;
    }

    .downloadButton {
      position: absolute;
      bottom: 15px;
      right: 10px;
      border: none;
      background: none;
    }

    .prize {
      display: flex;
      align-items: center;

      .photo {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        border: 1px solid $color-black;
      }

      .photoBelarus {
        width: 85px;
        border-radius: 8px;
        margin: 15px 10px;
      }

      .prizeInfo {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        .title {
          font-size: 14px;
          line-height: 120%;
          font-weight: 500;
          margin: 0 10px 5px 0;
        }

        .titleBelarus {
          margin-bottom: 15px;
          font-weight: 600;
          font-size: 14px;
          line-height: 120%;
        }

        .price {
          font-size: 11px;
          line-height: 120%;
          color: $text_grey_color;
          margin: 0;
        }
      }

      .usedWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 15px;

        .text {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 120%;
          padding-left: 5px;
        }

        .markerNew {
          position: absolute;
          width: 10px;
          height: 10px;
          right: 20px;
          top: 18px;
          border-radius: 50%;
        }

        .checkbox {
          display: none;
        }

        .label {
          width: 20px;
          height: 20px;
          border-radius: 4px;
          position: relative;

          &:after {
            border: 2px solid $color-white;
            border-top: none;
            border-right: none;
            content: '';
            height: 4px;
            left: 5px;
            opacity: 1;
            position: absolute;
            top: 5px;
            transform: rotate(-45deg);
            width: 8px;
            border-radius: 1px;
          }
        }
      }
    }
  }
}
