@import "src/styles/common";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 16px;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 10px;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 60px;
    }

    .phoneInfo {
      color: $color-black;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      border-bottom: 1px solid #DFDFDF;
      height: 37px;
      margin-bottom: 90px;

    }

    .link {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      text-decoration-line: underline;
      color: $color-green1;
    }
  }

  .backButton {
    align-self: flex-start;
    margin: 20px 0 40px 0;

    & svg {
      fill: $color-black;
    }
  }

  .button {
    background: transparent;
    align-self: flex-end;
    padding: 0;
    margin-bottom: 30px;

    &:disabled {
      opacity: 0.3;
    }
  }
}