@import '../../../../styles/common';


.create-perx-card-page {
  position: relative;
  width: 100%;
  height: 100%;

  &__back-button {
    position: absolute;
    background: transparent;
    border-radius: 50%;
    top: 10px;
    margin: 10px 0;
    z-index: 1;
    .header-arrow {
      fill: $color_white;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &-image-container {
      height: 100%;
      width: 100%;
    }

    &-card {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 312px;
      background: $color-white;
      bottom: 0;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;

      &__faq {
        position: absolute;
        top: 20px;
        right: 16px;
        border: 1px solid $color-black;
        border-radius: 50%;
        box-sizing: border-box;
        color: $color-black;
        z-index: 10;

        & path {
          fill: $color-black;
        }
      }

      &__title-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        &-title {
          position: relative;
          font-weight: bold;
          font-size: 18px;
          text-align: center;
          color: $color-black;
          margin-bottom: 10px;
        }

        &-add-card-title {
          position: relative;
          font-size: 17px;
          text-align: center;
          color: $color-black;
          width: 95%;
        }
      }

      &__btn-container {
        width: 100%;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 10px;

        &-button {
          height: 60px;
          width: calc(100% - 125px);
          border-radius: 8px;
          background: $color-aquamarin;
          font-size: 17px;
          color: $color-white;
          font-weight: bold;
        }
      }

      &__create-card-link {
        position: relative;
        justify-content: center;
        font-size: 16px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: $color-aquamarin;
      }
    }
  }
}
