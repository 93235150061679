@import '../../styles/common';
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent !important;
  color: $color-white !important;
}

.promo-code-page {
  width: 100%;
  height: 100%;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &__form {
    display: flex;
    height: 100%;
    margin: 16px;
    flex-flow: column;
    background: transparent;
    justify-content: space-between;
  }

  &__header-arrow {
    height: 21px;
    fill: $color-white;
  }

  &__back-button {
    margin-top: 24px;
    background: transparent;
    width: 30px;
  }

  &__success {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $color-white;
    align-items: center;
    text-align: center;
    width: calc(100% - 32px);
    height: calc(100% - 68px);
    margin: auto;
    &-back-button {
      width: 100%;
      height: 60px;
      border-radius: 8px;
      color: $color-white;
      background: $color-green1;
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 65px;
    }

    &-header {
      font-weight: 900;
      font-size: 22px;
      line-height: 26px;
    }

    &-text {
      font-size: 18px;
      line-height: 21px;
      margin-top: 10px;
    }

    &-image {
      margin-top: 90px;
      height: 295px;
      width: 295px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__input {
    background: transparent;
    outline: transparent;
    border: none;
    width: calc(100% - 32px);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    color: $color-white;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 5px;
    padding-right: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    padding-left: 0;

    &::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  &__input-container {
    position: relative;
  }

  &__actions {
    margin-bottom: 1rem;
  }

  &__submit {
    height: 60px;
    width: 100%;
    border-radius: 8px;
    background: $color-white;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 1rem;

    &:disabled {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  &__header {
    color: $color-white;
    font-weight: 900;
    font-size: 32px;
    line-height: 37px;
    margin-top: 43px;
  }

  &__promo-code-info-button {
    position: absolute;
    right: 26px;
    top: 215px;
    background: transparent;
    border: 1px solid $color-white;
    border-radius: 25px;
    width: 20px;
    height: 20px;

    span {
      position: relative;
      color: $color-white;
      font-size: 14px;
      right: 1px;
      top: 0;
    }
  }
  &__info-icon {
    position: absolute;
    margin-left: -20px;
    margin-top: 3px;
  }
}

@media screen and (max-height: 750px) {
  .promo-code-page {
    &__success {
      &-image {
        margin-top: 40px;
        height: 200px;
        width: 200px;
      }
    }
  }
}
