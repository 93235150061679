@import '../../styles/common';

.article-page {
  &__content {
    width: 100%;
    overflow: hidden;
  }

  &__back-button_black {
    background: transparent;
    border-radius: 50%;
    margin: 20px 0px 20px 0px;
    .header-arrow {
      fill: $color_black;
    }
  }
}
