@import '../../../styles/common';

.checkboxBlock {
  display: flex;
  flex-direction: column;

  .container {
    border-radius: 8px;
    padding: 20px 15px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      display: none;

      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark {
      min-height: 25px;
      min-width: 25px;
      background-color: $color-white;
      border-radius: 4px;

      &:after {
        content: '';
        display: none;
        position: relative;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid $color-black;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .checkboxValue {
      margin-left: 10px;
    }
  }
  .invalidInput {
    border: 1px solid $color-red1;
    padding: 19px 14px;
  }
}
.errorMessage {
  position: absolute;
  color: $color-red1;
  font-size: 11px;
  line-height: 13px;
}
