.selfRegistrationFormContainer {
  height: 100%;
  flex-direction: column;
  display: flex;

  input {
    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.selfRegistrationTitle {
  margin-top: 0;
  margin-bottom: 45px;
  color: #fff;
  font-size: 32px;
  font-weight: 800;
}

.registrationInput {
  margin-bottom: 35px;
  width: 100%;
}

.phoneNumberContainer {
  display: flex;
  width: 100%;
}

.phoneNumber {
  font-size: 20px;
  height: 24px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  color: #fff;

  &:last-child {
    width: 100%;
  }
}

.submitBtnContainer {
  display: flex;
  margin-top: 50px;
  width: 100%;
  justify-content: flex-end;
}

.submitBtn {
  background: transparent;
  padding: 0;
}

.disabled {
  opacity: 0.3;
}
