@import "src/styles/common";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;

  .image {
    width: 100%;
  }

  .textBlock {
    display: flex;
    flex-direction: column;
    align-items: center;

    .correct {
      font-size: 18px;
      line-height: 120%;
      font-weight: bold;
      color: $color-green5;
    }

    .unCorrect {
      color: $color-red1
    }

    .resultMessage {
      margin-top: 10px;
      font-size: 18px;
      line-height: 120%;
    }
  }

  .button {
    margin: 40px 16px 0;
    border-radius: 8px;
    font-weight: bold;
    color: $color-white;
    font-size: 16px;
    height: 60px;
  }
}