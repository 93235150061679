@import "../../../styles/common";

.manager-page {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__top {
        width: 100%;
    }

    &__back-button {
        background-color: transparent;
        display: flex;
        margin-top: 24px;
    }

    &__border-photo {
        margin: auto;
        margin-top: 43px;
        margin-bottom: 20px;
        background-image: url("./border_photo.png");
        width: 205px;
        height: 205px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__photo {
        width: 175px;
        height: 175px;
        box-sizing: border-box;
        border-radius: 87px;
        object-fit: cover;
    }

    &__manager-name {
        width: 80%;
        margin: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: $color_white;
    }

    &__account-code-container {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $color_white;
        margin-top: 20px;
    }

    &__account-code {
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: $color_white;
    }

    &__phone-number-container {
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: $color_white;
        margin-top: 20px;
    }

    &__info {
        width: calc(100% - 32px);
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        color: $color_white;
    }

    &__button {
        border-radius: 8px;
        margin-bottom: 30px;
        margin-top: 30px;
        width: 100%;
        height: 60px;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
        background-color: $color_white;
    }
}
