@import '../../../../styles/common';

.oppa-create-wallet {
  &__addWallet {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &-container {
      display: flex;
      flex-direction: column;
      padding: 25px;
      background-color: $color-white;
      border-radius: 25px 25px 0 0;
      text-align: center;

      &-tutorial {
        align-self: flex-end;
      }

      &-header {
        font-size: 18px;
        font-weight: bold;
        margin: 10px 0;
      }

      &-description {
        font-size: 18px;
      }

      &-button {
        margin: 30px 40px 15px;
      }

      &-perx {
        color: $color-green1;
      }

      &__faq {
        position: relative;
        left: 95%;
        border: 1px solid $color-black;
        border-radius: 50%;
        box-sizing: border-box;
        color: $color-black;
        z-index: 10;

        & path {
          fill: $color-black;
        }
      }
    }
  }

  &__linkWallet {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-form {
      display: flex;
      flex-flow: column;
      align-items: stretch;
      background: inherit;
      flex-grow: 1;
      margin: 0 16px;

      & .input-block__warning-message {
        color: $color-red1;
      }

      &-input {
        & input {
          color: $color-black;
        }

        & .input-block__input {
          border-bottom: 1px solid $color-green1;
          &-invalid {
            border-bottom: 2px solid $color-red1;
          }
        }
      }
    }

    &-header {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin: 80px 16px 0;
    }
    &-description {
      margin: 10px 16px 50px;
    }

    &-buttonBox {
      margin: 25px 15px;
      background: none;
      display: flex;
      flex-direction: row-reverse;
    }
    
    &-button {
      background: none;
      -webkit-tap-highlight-color: transparent;

      & path {
        fill: $color-green1;
      }

      &-disabled {
        @extend .oppa-create-wallet__linkWallet-button;
        path {
          fill: $color-green1;
          opacity: 0.2;
        }
      }
    }
  }

  &__success {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-pictureBox {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      & img {
        margin-top: 50px;
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        width: calc(100% - 100px);
        border-radius: 32px;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 16px;

      &-header {
        font-weight: bold;
        font-size: 18px;
      }

      &-description {
        font-size: 18px;
        margin: 10px auto 40px;
        text-align: center;
      }
    }
  }
}
