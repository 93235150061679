@import '../../../styles/common';

.ios-add-to-homescreen {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);

  &__baner {
    background-color: $color_white;
    border-radius: 16px;
    width: 310px;
    height: 69px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__baner-caucasus {
    margin-top: 81px;
    background-color: $color_white;
    border-radius: 16px;
    width: 300px;
    height: 86px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-top: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
  }

  &__description {
    display: flex;
    margin-top: 5px;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
  }

  &__ios-icon {
    background-image: url('./ios_page_icon.png');
    height: 12px;
    width: 9px;
    background-size: cover;
    background-position: center;
    margin: 0 5px 0 5px;
  }
}
