@import '../../styles/common';

.root {
  height: 100%;

  :global {
    hr {
      border-width: 0;
      height: 1px;
      background: rgb(216, 216, 216);
    }

    .pickupPointDetails {
      font-size: 20px;
      line-height: 120%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 0.5rem;
      padding: 1rem;
      text-align: center;
      color: $color-white;
      gap: 0.5rem;

      p {
        font-size: 22px;
        font-weight: bold;
        margin: 0;
        text-transform: uppercase;
      }

      a {
        margin: 0;
        color: $color-white;
        text-decoration: underline;
      }
    }

    .content {
      height: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 2rem;
      padding-top: 2rem;
      background-size: cover;
      background-position: center;

      button {
        color: black;
        border-radius: 0.5rem;
        font-weight: bold;
        font-size: 1rem;
        width: 100%;
        padding: 1.5rem;
        border: none;
      }
    }
  }
}
