@import '../../../styles/common';

.onboarding {
  display: flex;
  height: 100%;
  padding-bottom: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto, sans-serif;

  &.tabaterra_az {
    background: $color_white;
  }

  &__top {
    flex-grow: 0.4;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    height: 195px;
    width: 195px;
    background-image: url('./club_logo.png');
    background-size: cover;

    &.tabaterra_az {
      background-image: url('./tabaterra_az/logo.png');
    }
  }

  &__top-belarus {
    flex-grow: 0.4;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  &__logo-belarus {
    height: 220px;
    width: 195px;
    background-image: url('./logo-be2gether.png');
    background-size: cover;
  }

  &__middle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    width: calc(100% - 32px);
    max-width: 270px;
    font-weight: 900;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    color: $color_black;
  }

  &__description {
    margin-top: 17px;
    width: calc(100% - 32px);
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: $color_black;
  }

  &__bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__exit {
    width: calc(100% - 32px);
    height: 60px;
    border: 1px solid $color_white;
    border-radius: 8px;
    background: transparent;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: $color_white;
  }

  &__next {
    width: calc(100% - 32px);
    height: 60px;
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    border-radius: 8px;
    background: $color_white;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 30px;
  }

  &__step {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    &__bottom {
      margin-top: 10px;
      margin-bottom: 30px;
      margin-right: 16px;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__skip {
    margin: 40px 16px 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $color_white;
  }
  &__step-logo-1,
  &__step-logo-2,
  &__step-logo-3 {
    display: flex;
    width: 285px;
    height: 285px;
    margin: auto;
    margin-top: 23px;
    background-size: cover;
  }

  &__step-logo-1 {
    background-image: url('./onboarding1_logo.png');

    &.tabaterra_az {
      background-image: url('./tabaterra_az/slide_1.jpg');
    }
  }

  &__step-logo-2 {
    background-image: url('./onboarding2_logo.png');
    &.tabaterra_az {
      background-image: url('./tabaterra_az/slide_2.jpg');
    }
  }

  &__step-logo-3 {
    background-image: url('./onboarding3_logo.png');
    &.tabaterra_az {
      background-image: url('./tabaterra_az/slide_3.jpg');
    }
    margin-top: 80px;
  }

  &__step-count {
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    align-items: center;
    text-align: center;
    color: $color_black;
  }

  &__step-info-1 {
    width: calc(100% - 32px);
    margin: auto;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: $color_black;
  }

  &__step-info-2 {
    width: calc(100% - 32px);
    margin: auto;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: $color_black;
  }

  &__next-step {
    align-self: end;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-color: transparent;
    //fill: red;
    padding: 0;
    color: $color_black;
  }
}
