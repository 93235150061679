.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
  width: 100%;

  .imgContainer {
    margin-top: 20px;

    .imgLike {
      width: 80px;
      height: 80px;
      margin-right: 54px;
    }

    .imgDislike {
      width: 80px;
      height: 80px;
    }
  }

  .title {
    margin-top: 30px;
  }

  .description {
    margin-top: 10px;
  }
}

