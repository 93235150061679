@import '../../../../styles/common';

.oppa-tutorial {
  $blackout_button_height: calc(61px + 15px + 15px);
  $blackout_button_margin: 15px;
  $margin_IphoneX: 32px;

  $button_next-position_bottom: 50px;

  $descriptionBox_position_right: 32px;
  $descriptionBox_position_left: $descriptionBox_position_right;
  $descriptionBox_position_bottom: 130px;
  $descriptionBox_position_top: 100px;

  $skip_position_top: 20px;
  $skip_position_right: 20px;
  $skip_width: 62px;
  $skip_height: 29px;

  $height_headers: calc(68px + 42px);
  $height_wallet: 117px;
  $height_transaction: 110px;

  $size_faq_icon: 20px;
  $margin_faq_icon: 16px;

  &__blackout {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0px;
    z-index: 600;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    &-step2 {
      @extend .oppa-tutorial__blackout;
      clip-path: polygon(
        0% 0%,
        0 $height_headers,
        100% $height_headers,
        100% calc(#{$height_headers} + #{$height_wallet}),
        0 calc(#{$height_headers} + #{$height_wallet}),
        0 100%,
        100% 100%,
        100% 0%
      );
    }
    &-step3 {
      @extend .oppa-tutorial__blackout;
      clip-path: polygon(
        0% 0%,
        0 calc(100% - (#{$blackout_button_height}) - #{$blackout_button_margin}),
        100% calc(100% - (#{$blackout_button_height}) - #{$blackout_button_margin}),
        100% calc(100% - #{$blackout_button_margin}),
        0 calc(100% - #{$blackout_button_margin}),
        0 100%,
        100% 100%,
        100% 0%
      );

      &-iphoneX {
        @extend .oppa-tutorial__blackout;
        clip-path: polygon(
          0% 0%,
          0 calc(100% - (#{$blackout_button_height}) - #{$blackout_button_margin} - #{$margin_IphoneX}),
          100% calc(100% - (#{$blackout_button_height}) - #{$blackout_button_margin} - #{$margin_IphoneX}),
          100% calc(100% - #{$blackout_button_margin} - #{$margin_IphoneX}),
          0 calc(100% - #{$blackout_button_margin} - #{$margin_IphoneX}),
          0 100%,
          100% 100%,
          100% 0%
        );
      }
    }

    &-step4 {
      @extend .oppa-tutorial__blackout;
      clip-path: polygon(
        0% 0%,
        0 calc(#{$height_headers} + #{$height_wallet}),
        100% calc(#{$height_headers} + #{$height_wallet}),
        100% calc(#{$height_headers} + #{$height_wallet} + #{$height_transaction}),
        0 calc(#{$height_headers} + #{$height_wallet} + #{$height_transaction}),
        0 100%,
        100% 100%,
        100% 0%
      );
    }

    &-step6 {
      @extend .oppa-tutorial__blackout;
      clip-path: polygon(
        0% 0%,
        0% 100%,
        89% 100%,
        89% 28.5%,
        98% 28.5%,
        98% 33%,
        89% 33%,
        89% 100%,
        100% 100%,
        100% 0%
      );
    }
  }

  &__skip {
    position: fixed;
    padding: 10px;
    right: $skip_position_right;
    top: $skip_position_top;
    background: $color-white;
    border-radius: 42px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 100%;

    &-icon {
      margin-left: 6px;
    }
  }

  &__descriptionBox {
    position: fixed;
    background: $color-white;
    border: 1px solid $color-aquamarin;
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-black;
    padding: 40px 16px 30px;

    &-bottom {
      @extend .oppa-tutorial__descriptionBox;
      left: $descriptionBox_position_left;
      right: $descriptionBox_position_right;
      bottom: $descriptionBox_position_bottom;
    }
    &-top {
      @extend .oppa-tutorial__descriptionBox;
      left: $descriptionBox_position_left;
      right: $descriptionBox_position_right;
      top: $descriptionBox_position_top;
    }

    &-header {
      font-weight: bold;
      font-size: 18px;
      line-height: 110%;
      text-align: center;
    }

    &-description {
      margin-top: 10px;
      font-size: 14px;
      line-height: 110%;
      text-align: center;
    }

    &-skip {
      height: 27px;
      margin-top: 10px;
      font-size: 12px;
      line-height: 110%;
      text-align: center;
    }

    &-step {
      margin-bottom: 10px;
      font-size: 10px;
      line-height: 110%;
      text-align: center;
    }
  }

  &__button {
    width: 279px;
    height: 50px;
    position: fixed;
    bottom: $button_next-position_bottom;
    background: $color-white;
    border-radius: 8px;
    font-size: 17px;
    font-weight: bold;

    &-box {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &-next {
      bottom: $button_next-position_bottom;
      right: 16px;
      position: fixed;
      background: none;
    }
  }

  &__arrow {
    position: fixed;
    left: calc(50% - 12px);
    top: calc(#{$height_headers} + #{$height_wallet} + 20px);

    &-faq {
      position: fixed;
      right: calc(#{$size_faq_icon}* 2.5 + #{$margin_faq_icon});
      top: calc(#{$height_headers} + #{$height_wallet} + #{$size_faq_icon}/ 2);
    }
  }
}
