@import '../../../styles/common';

.ReactModal__Content {
  border-radius: 8px;
  box-sizing: border-box;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 600;
}

.modal {
  &__close-wrapper {
    position: absolute;
    top: 20px;
    right: 16px;
    fill: $color-black;
    stroke: $color-black;
    cursor: pointer;
    z-index: 1;
  }

  &__title {
    margin-top: 40px;
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    text-align: center;

    color: $color-black;
    text-shadow: 0 5px 5px rgba(0, 0, 0, 0.0305423);
  }
}

@media only screen and (max-width: 600px) {
  .ReactModal__Content {
    width: calc(100% - 32px);
  }
}
