@import '../../../styles/common';

.module-container {
  width: calc(50% - 5px);
  .module-block {
    position: relative;
    width: 100%;
    height: 120px;
    border-radius: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    &__module-name {
      position: absolute;
      font-size: 18px;
      font-weight: bold;
      line-height: 21px;
      color: $color-white;
      margin-top: 0;
      margin-left: 10px;
      padding-top: 10px;
      width: 137px;
      word-wrap: break-word;
    }
    &__module-image {
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 21px;
    }
  }
}

@media screen and (max-width: 360px) {
  .module-container {
    .module-block {
      &__module-image {
        background-size: cover;
      }
    }
  }
}
