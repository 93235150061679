$color-black: #000;
$color-white: #fff;

$color-dark: #02002f;
$color-dark2: #252525;

$color-red: #de4d4d;
$color-red1: #ff6666;
$color-red2: #920000;
$color-ld: #cd071e;

$color-light-blue: #3c7eff;
$color-blue: #0085ff;
$color-blue2: #004494;
$color-blue3: #5135ff;
$color-blue4: #02002f;
$color-blue5: #c9ecff;
$color-blue-mybrand: #252B39;

$color-aquamarin: #01c1b6;
$color-violet: #8a4b9e;
$color-grapefruit: #ed8d7d;

$color-orange: #ffac30;
$color-orange2: #ff8a5c;
$color-orange3: #ff8a5c66;
$color-orange4: #ff6a00;
$color-orange5: #f26e17;

$color-green1: #01c1b6;
$color-green2: #0a9a88;
$color-green3: #80e1d8;
$color-green4: #01c5b1;
$color-green5: #4cbf8f;
$color-green6: #176443;
$color-green7: #45d67f;
$color-green8: #09b519;
$color-green9: #009c06;
$color-green-tabaterra: #004030;
$color-green-10: #03edc3;

$color-gray1: #f0f0f0;
$color-gray2: #f9f9f9;
$color-gray3: #f2f2f2;
$color_gray4: #b2b2b2;
$color-gray5: #b3b3b3;
$color-gray6: #8a8a8f;
$color-gray7: #c4c4c4;
$color-gray8: #c0c0c0;
$color-gray9: #f3f3f3;
$color-gray10: #e6e6e6;
$color-gray11: #f7f7f7;
$color-gray12: #f1f1f1;
$color-gray13: #4b4b4b;

$color-gold: #e5cf50;
$color-gold2: #ffea80;
$color-silver: #cccccc;
$color-bronze: #d9bd8c;
$color-yellow: #ffce2b;
$color-camel: #ffeb00;
$color-yellow-tabaterra: #e9a835;
$color-yellow-mybrand: #C5AE75;

$color-shadow1: rgba(0, 0, 0, 0.0305423);
$color-shadow2: rgba(255, 255, 255, 0.2);
$color-shadow3: rgba(0, 0, 0, 0.2);
$color-shadow4: rgba(0, 0, 0, 0.1);

$color-success: $color-green5;
$color-failed: $color-red1;
$color-moderation: $color-orange;
$color-waiting: $color-gray4;

:export {
  successColor: $color-success;
  failedColor: $color-failed;
  moderationColor: $color-moderation;
}
