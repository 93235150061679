@import 'src/styles/common';

.container {
  margin: 0 15px 15px;
}

.swiperItemContainer {
  width: 260px;
}

.swiperContainer {
  height: 130px;
  overflow: visible;
}

.swiperWrapper {
  position: relative;
  width: 100%;
  height: 120px;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiperItem {
  position: relative;
  height: 110px;
  width: 230px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.1);
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid $color-gray4;
}

.itemName {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 10px;
  height: 19px;
  padding: 0 10px;
  background: $color-white;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 700;
}

.itemDescription {
  position: absolute;
  top: 44px;
  left: 11px;
  height: 19px;
  font-weight: 500;
  color: $color-white;
}

.unread {
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: $color-red1;
}
