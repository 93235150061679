@import '../../../../styles/common';

$background_white_color: $color-white;
$text_grey_color: $color_gray4;
$divider_color: $color-gray7;

.perx-cashout-history {
  display: flex;
  flex-direction: column;
  padding: 30px 36px 160px 36px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  margin-top: -20px;
  width: calc(100% - 72px);
  background: $background_white_color;

  &-empty {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $text_grey_color;
    position: absolute;
    width: calc(100% - 72px);
    top: 50%;
  }

  &-caption {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 15px 0;
  }

  &-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 75px;
      margin-bottom: 5px;

      &-info {
        display: flex;
        flex-direction: column;

        &-amount {
          font-size: 14px;
          line-height: 17px;
          margin: 5px 0;
        }

        &-date {
          font-size: 10px;
          line-height: 12px;
          margin: 0 0 10px 0;
          opacity: 0.2;
        }

        &-status {
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 17px;

          &-icon {
            margin-right: 5px;
          }
        }
      }

      &-link {
        &-button {
          display: flex;
          align-items: center;
          justify-content: space-around;
          height: 26px;
          width: 61px;
          border-radius: 16px;
          font-size: 12px;
          font-weight: bold;
          background-color: $color-gray3;
        }
      }
    }

    &-divider {
      width: 100%;
      border-top: 1px solid $divider_color;
      opacity: 0.2;
      margin-bottom: 5px;
    }
  }
}
