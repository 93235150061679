@import "src/styles/common";

.container {
  background-image: url('../../../images/background-images/mystery-shopper-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: calc(100% - 136px);
  padding: $header-height 0;

  .backButton {
    background: transparent;
    position: absolute;
    top: 25px;
    left: 10px;
  }

  .title {
    margin-top: 20px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $color-white;
    text-align: center;
  }

  .mainWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .resultImageWrapper {
      max-width: 285px;
      max-height: 328px;

      @media screen and (max-height: 700px) {
        width: 200px;
      }

      .resultImage {
        object-fit: fill;
        width: 100%;
        height: 100%;
      }
    }

  }

  .iconWrapper {
    display: flex;
    justify-content: center;
    max-width: 439px;
    max-height: 406px;


    .icon {
      position: absolute;
      bottom: 0;

      @media screen and (max-height: 700px) {
        width: 270px;
        height: 270px;
      }

      @media screen and (max-width: 375px) {
        width: 320px;
        height: 320px;
      }
    }
  }

  .button {
    position: absolute;

    bottom: 50px;
    height: 60px;
    color: $color-black;
    background: $color-white;
    border-radius: 8px;
    width: calc(100% - 32px);
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    z-index: 1;
  }
}