@import '../../../styles/common';

$text_color: $color-white;

.input-block {
  position: relative;

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    filter: invert(1);
    margin-right: 10px;
  }

  input[type='date']::-webkit-date-and-time-value {
    text-align: right;
  }

  input[type='date']:after {
    left: 0;
    bottom: 8px;
    position: absolute;
    font-size: 20px;
    font-weight: normal;
    content: attr(placeholder) !important;
  }

  input[type='date'] {
    text-align: right;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  input::placeholder {
    color: $color-white;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    opacity: 1;
  }
  &__input {
    font-weight: 600;
    border: none;
    background: transparent;
    color: $text_color;
    width: 100%;
    height: 24px;
    padding-bottom: 5px;
    font-size: 20px;
    line-height: 23px;
    border-bottom: 2px solid rgba(256, 256, 256, 0.2);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    padding-left: 0;

    &-invalid {
      font-weight: 600;
      border: none;
      background: transparent;
      color: $text_color;
      width: 100%;
      height: 24px;
      padding-bottom: 5px;
      font-size: 20px;
      line-height: 23px;
      border-bottom: 2px solid $color-red1;
      -webkit-appearance: none;
      border-radius: 0;
      padding-left: 0;
    }
  }

  &__with-warning {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  &__warning {
    position: absolute;
    display: flex;
    min-width: 18px;
    margin-right: 10px;
    margin-left: -10px;
    height: 18px;
    line-height: 18px;
    justify-content: center;
    background: $color-red1;
    color: $color-white;
    font-size: 14px;
    border-radius: 50%;
  }

  &__warning-message {
    position: absolute;
    color: $color-white;
    font-size: 11px;
    line-height: 13px;
  }
}

.input-block_input :focus {
  outline: none;
}

::placeholder {
  opacity: 0.8;
}
