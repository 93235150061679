@import '../../../styles/common';

.wrongInviteCode-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    margin-top: 25px;
  }
  &__title {    
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    margin-top: 10px;
  }

  &__btn {
    width: calc(100% - 32px);
    height: 60px;
    margin-top: 30px;
    border-radius: 8px;
    color: $color_white;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    border: none;
    background: $color_green1;
  }
}
