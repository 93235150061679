@import '../../../../styles/common';

$background-color: $color-white;
$text_white_color: $color-white;

.signing-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 50px;
  background-color: $background-color;

  &__progressbar {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  &__header-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $text_white_color;
  }

  &__content {
    height: calc(100% - 228px);
    width: 100%;
  }

  &__back-button {
    position: absolute;
    background: transparent;
    top: 25px;
    left: 10px;
  }
}
