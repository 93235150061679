@import '../../../../styles/common';

.block {
  height: 171px;
  background-color: $color-white;
  box-shadow: 0 0 54px rgba(0, 0, 0, 0.05);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 0 25px;

  .titleBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .title {
      font-weight: 500;
      font-size: 16px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 12px;
    }
  }

  .buttonsBlock {
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      height: 40px;
      width: 45%;

      &:disabled {
        opacity: 0.5;
      }
    }

    .deleteButton {
      background-color: $color-white;
      font-weight: 700;
      font-size: 14px;
      color: $color-aquamarin;
      border: 1px solid $color-aquamarin;
      border-radius: 8px;
    }
  }
}