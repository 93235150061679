.inviteAcceptedContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  .logo {
    max-width: 166px;
    height: 96px;
    margin: 101px 0 32px;
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 50px;
  }

  .opponentsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
  }
}