@import "../../../../styles/common";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .answerInput {
    display: none;
  }

  .label {
    position: relative;
    background-color: transparent;
    border: 1px solid $color-white;
    margin-bottom: 10px;
    color: $color-white;
    border-radius: 100px;
    text-align: center;
    padding: 20px 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
  }

  input[type=checkbox]:checked + .label {
    background-color: $color-white;
    color: $color-black;
  }

  input[type=checkbox]:checked + .incorrectAnswer {
    background-color: $color-red1 !important;
    color: $color-white !important;
    border: none;
  }

  input[type=checkbox]:checked + .correctAnswer {
    background-color: $color-green5 !important;
    color: $color-white !important;
    border: none;

    &:before {
      display: block;
      background-color: $color-white !important;
    }

    &:after {
      border: 1px solid $color-white;
    }
  }

  .showCorrectAnswer {
    border: 1px solid $color-green5;
    background: rgba(76, 191, 143, 0.2);
  }
}