@import "src/styles/common";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0 16px;
  padding-bottom: $footer-height;

  .image {
    width: 100%;
    margin: 60px 0 30px ;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 10px;
  }

  .description {
    font-size: 14px;
    line-height: 120%;
  }
}