@import "src/styles/common";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 32px;

  .percentsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: calc(100% - 64px);
    height: 60px;
    background: $color-white;
    box-sizing: border-box;
    border-radius: 16px;

    .name {
      font-weight: 700;
      font-size: 12px;
      line-height: 120%;
      color: $color-black;
    }

    .percent {
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
    }
  }

  .recommendation {
    color: $color-white;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    margin: 10px 32px 50px;
  }
}