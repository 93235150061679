@import '../../../styles/common';

.userInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90px;

  .avatarImage {
    width: 60px;
    height: 60px;
    border: 2px solid;
    border-radius: 30px;
    margin-bottom: 10px;
  }

  .username {
    width: 100%;
    text-align: center;
    @include text-ellipsis
  }
}
