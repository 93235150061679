.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding: 0.5rem;
  box-sizing: border-box;
  padding-bottom: 7rem;

  .brand {
    width: 100%;
    margin-bottom: 0.5rem;
    display: flex;

    img {
      width: 100%;
      border-radius: 1rem;
    }
  }
}
