.loaderContainer {
  height: 100%;
  position: relative;

  > *:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
