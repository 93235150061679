@import "src/styles/common";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $color-red1;
  border-radius: 100px;
  height: 26px;
  padding: 0 10px;
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);

  .text {
    color: $color-white;
    white-space: nowrap;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
    padding-left: 5px;
  }
}