@import 'src/styles/common';

.sendMessageBlock {
  display: flex;
  align-items: center;
  background-color: $color-white;
  padding: 10px 5px;

  input {
    flex: 1;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 100px;
    background-color: $color-gray3;
    border: none;
  }

  .sendMessageIcon {
    height: 20px;
  }

  .addImageIcon {
    height: 40px;
  }
}
