@import '../../styles/common';

$header_and_button_background_color: $color-aquamarin;
$background_white_color: $color-white;
$background_grey_color: $color-gray1;
$text_white_color: $color-white;
$text_black_color: $color-black;
$bottom_line_color: $color-black;

.bonus-shop-category-page {
  background-color: $background_white_color;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-component {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: 10px;

      .header-arrow {
        height: 21px;
        fill: $color-white;
      }
    }
  }

  &__header-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: $text_white_color;
    margin: 0;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-carousel-place {
      height: 310px;
    }
  }
}
