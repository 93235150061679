@import '../../../../styles/colors';

.challenge {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .headerTitle {
    color: $color-white;
    font-size: 18px;
    font-weight: bold;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    align-items: center;
    height: 100%;
    padding: 0 16px 10px;

    .titleBlock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .challengeTitle {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
      }

      .challengeDescription {
        font-size: 14px;
        text-align: center;
      }

      .challengePhoto {
        margin-top: 30px;
        width: 130px;
      }
    }
  }

  .loaderContainer {
    height: 100%;
    flex: 1;
  }
}
