@import 'src/styles/common';

.wrapper {
  background-color: rgba($color-blue4, 1);
  background-image: linear-gradient(to bottom, rgba($color-black, 0.3), rgba($color-blue4, 1), rgba($color-black, 0.7));
  height: 100%;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 0em;
  color: $color-white;
  text-align: center;

  .streamWrapper {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    width: 100%;

    video {
      height: 100%;
      position: relative;
      z-index: 1;
    }
  }

  .contentWrapper {
    min-height: 100%;
    width: calc(100% - 32px);
    padding: 0px 16px;
    display: grid;
    grid-template-rows: 45px auto 15px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
  }

  .topWrapper {
    margin-top: 10px;
    position: relative;
    z-index: 10;
  }

  .middleWrapper {
    margin: 30px 0 0;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: stretch;

    @media (min-width: 375px) and (min-height: 615px) {
      margin: 30px 0 35px;
    }
  }

  .bottomWrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;

    .lineFooter {
      @media (min-width: 375px) and (min-height: 615px) {
        width: 134px;
        height: 5px;
        border-radius: 100px;
        border-radius: 100px;
        background: $color-white;
        margin: 0 auto 9px;
      }
    }
  }
}
