@import '../../../styles/common';

$border_color: $color-aquamarin;
$button_background_color: $color-aquamarin;
$text_white_color: $color-white;
$background_white_color: $color-white;

.transfer-tutorial-third-step {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 600;

  &__content {
    width: 100%;
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  &__description {
    width: calc(100% - 64px);
    padding: 0 16px;
    background: $background_white_color;
    border: 1px solid $border_color;
    box-sizing: border-box;
    border-radius: 16px;

    &-step-number {
      margin: 29px 0 0 0;
      font-size: 10px;
      line-height: 11px;
      text-align: center;
    }
    &-title {
      margin: 10px 0 0 0;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }
    &-text {
      margin: 10px 0 50px 0;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
    }
  }
  
  .arrow {
    position: absolute;
    bottom: 90px;
    transform: rotate(180deg);
  }
  &__btn-container {
    display: flex;
    justify-content: center;
    width: calc(100% - 32px);
    border-radius: 16px;
    position: absolute;
    background: $background_white_color;
    left: 9px;
    padding: 8px;

    &-button {
      height: 60px;
      width: 100%;
      border-radius: 8px;
      background: $button_background_color;
      font-size: 17px;
      color: $text_white_color;
      font-weight: bold;
    }
  }
}
