@import '../../../styles/common';

.enter-phone-page-irl {
  display: flex;
  height: 100%;
  min-height: 560px;
  justify-content: space-between;
  flex-flow: column;
  background-image: url('../../../images/background-images/bg-lep-img.jpg');
  background-size: 100% 100%;
}

.enter-phone-page {
  display: flex;
  height: 100%;
  min-height: 560px;
  justify-content: space-between;
  flex-flow: column;

  &__description {
    width: 65%;
    margin-top: 11px;
    background: transparent;
    color: $color-white;
    font-size: 14px;
    line-height: 17px;
  }
  &__irl-description {
    width: 65%;
    margin-top: 11px;
    background: transparent;
    color: $color-green6;
    font-size: 14px;
    line-height: 17px;
  }

  &__top-section {
    padding-top: 80px;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
  }

  &__bot-section {
    display: flex;
    position: relative;
    flex-flow: column;
    height: 140px;
    justify-content: flex-end;

    .verify__btn {
      justify-self: flex-end;
      align-self: flex-end;
      width: 51px;
      height: 51px;
      padding: 0;
      background: $color-white;
      border-radius: 50%;
      margin: 150px 16px 30px 0;
    }

    .btn-component {
      margin-top: 0;
      position: absolute;
      top: 0;
      z-index: 1000;
    }
  }

  &__title {
    margin: 0 0 30px;
    font-size: 32px;
    background: transparent;
    color: $color-white;
  }
  &__irl-title {
    margin: 0 0 30px;
    font-size: 32px;
    background: transparent;
    color: $color-green6;
  }

  &__phone {
    color: $color-white;

    .phone-number-input__container {
      margin-top: 50px;
    }
  }

  &__irl-phone {
    color: $color-green6;

    &.phone-number-input__code {
      width: 80px;
    }
  }

  &__text {
    margin: 0 16px 26px 16px;
    background: transparent;
    color: $color-white;
    font-size: 9px;
  }
  &__irl-text {
    margin: 0 16px 26px 16px;
    background: transparent;
    color: $color-green6;
    font-size: 9px;
  }

  &__phone-info {
    margin-top: 10px;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }

  &__btn {
    justify-self: flex-end;
    align-self: flex-end;
    width: 51px;
    height: 51px;
    padding: 0;
    background: $color-white;
    border-radius: 50%;
    margin-right: 16px;
    margin-bottom: 40px;
  }

  &__icon {
    width: 100%;
    height: 20px;
    fill: $color-green4;
  }

  &__btn:disabled {
    opacity: 0.3;
  }

  @media only screen and (min-width: 600px) {
    margin: auto;
    max-width: 500px;
  }

  @media only screen and (max-height: 620px) {
    .verify__btn {
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 340px) {
    .phone-number-input__code {
      font-size: 28px;
      width: 65px;
    }
    .phone-number-input__code-zero {
      font-size: 28px;
      width: 35px;
    }
    .phone-number-input__number {
      font-size: 28px;
      width: 140px;
    }
  }
}

.auth__btn {
  justify-self: flex-end;
  align-self: flex-end;
  width: 51px;
  height: 51px;
  padding: 0;
  background: $color-white;
  border-radius: 50%;
  margin-right: 16px;
  margin-bottom: 40px;
}
