@import '../../styles/common';

$background-color: $color-white;
$text_white_color: $color-white;
$text_black_color: $color-black;
$button_background_color: $color-aquamarin;

.passport-success-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: $background-color;

  &__header-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $text_white_color;
  }

  &__content {
    height: calc(100% - 228px);
    width: 100%;
  }
  &__content-area {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    &-schedule-container {
      min-height: 285px;
      width: 100%;
    }
    @media screen and (max-height: 600px) {
      &-schedule-container {
        max-height: 285px;
        min-height: 185px;
      }
    }

    &-description {
      width: calc(100% - 32px);
      text-align: center;
  
      &-title {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        margin: 0 0 10px 0;
      }
      &-desc {
        margin: 0;
        font-size: 17px;
        line-height: 20px;
      }
    }

    &-button-container {
      width: calc(100% - 32px);
    }
  }
  .got-it-button {
    height: 60px;
    width: 100%;
    border-radius: 8px;
    background: $button_background_color;
    color: $text_white_color;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
  }
}
