@import '../../../styles/common';

.personalDataContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background: $color-white;
  padding: 0 16px;

  .progressBar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .backButton {
    position: absolute;
    background: transparent;
    top: 25px;
  }

  .title {
    font-weight: bold;
    padding-top: 70px;
    font-size: 24px;
    color: $color-black;
  }

  .description {
    margin-top: 20px;
    font-size: 14px;
    white-space: pre-line
  }

  .checkBoxContainer {
    display: flex;
    align-items: center;
    background: rgba($color-black, 0.05);
    border-radius: 8px;
    margin-top: 50px;
    padding: 12px;

    .text {
      font-size: 16px;
      margin-left: 10px;
    }

    .input {
      display: none;
    }

    .checkbox {
      background-color: $color-white;
      width: 24px;
      height: 24px;
      display: inline-block;
      border: 1px solid $color-black;
      border-radius: 4px;
      position: relative;

      &:before {
        content: url('../../../images/checked.png');
        position: absolute;
        height: 24px;
        width: 24px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: .2s;
        background: $color-black;
        border-radius: 4px;
      }

      .input:checked + .checkbox:before {
        border-radius: 6px;
        background-color: $color-orange2;
        opacity: 1;
      }

    }
  }

  .active {
    border: 1px solid $color-red1;
  }

  .checked {
    .checkbox {
      background-color: $color-white;
      width: 24px;
      height: 24px;
      display: inline-block;
      border: 1px solid $color-black;
      border-radius: 4px;
      position: relative;

      &:before {
        content: url('../../../images/checked.png');
        position: absolute;
        height: 24px;
        width: 24px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: .2s;
      }

      .input:checked + .checkbox:before {
        opacity: 1;
      }
    }
  }

  .errorText {
    margin-top: 5px;
    font-size: 10px;
    color: $color-red1
  }

  .nextArrowButton {
    display: flex;
    justify-self: flex-end;
    align-self: flex-end;
    width: 51px;
    height: 51px;
    padding: 0;
    background: $color-white;
    border-radius: 50%;
    margin: 50px 0 20px 0;
  }

  .nextArrowButton:disabled {
    opacity: 0.3;
  }
}