@import '../../../../styles/common';

.navigationWrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    background-color: $color-black;

    .messageBlock {
      background-color: $color-gray3;
      border-radius: 8px 8px 0 0;
      height: calc(100vh - #{$header-height} - #{$navigation-menu-height} - #{$disclaimer-height});
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.navigationWrapper.white .messageBlock {
  background-color: $color-white;
}
