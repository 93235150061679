@import '../../../styles/common';

$achievements_page_blue_color: #4d55c4;

.selected-achievement-page {
  background-color: $color-white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &__top-section {
    margin: 20px 0 50px 10px;

    &-back-button {
      background-color: transparent;
    }
  }

  &__bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;

    &-header {
      margin-bottom: 30px;
      &-caption {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
      }

      &-description {
        font-size: 14px;
        line-height: 120%;
        text-align: center;
      }
    }

    &-tasks {
      width: 100%;
      margin-bottom: 40px;

      &-item {
        position: relative;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        background-color: $color-white;
        border: 1px solid $color-gray3;
        box-sizing: border-box;
        border-radius: 16px;

        &-status {
          margin: 5px 20px;

          &-success {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }

        &-info {
          display: flex;
          &-icon {
            margin: 0 10px 20px 20px;
            background-color: $color-gray3;
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
          }

          &-right {
            display: flex;
            flex-direction: column;
            width: calc(100% - 80px);

            &-name {
              margin: 0 30px 10px 0;
              font-weight: bold;
              font-size: 16px;
              line-height: 120%;
            }

            &-reward {
              position: absolute;
              display: flex;
              justify-content: center;
              bottom: 10px;
              left: 0;
              width: 100%;

              &-button {
                display: flex;

                height: 30px;
                width: 30%;
                align-items: center;
                align-self: center;
                justify-content: center;

                box-sizing: border-box;
                border-radius: 8px;

                &-text {
                  margin: 5px 10px;
                  font-size: 10px;
                  line-height: 100%;
                  font-weight: bold;
                  text-align: center;
                  color: $color-white;
                }
              }
            }

            &-progress {
              &-bar {
                margin-right: 20px;
                &-text {
                  margin: 0;
                  margin-bottom: 5px;
                  font-size: 10px;
                  line-height: 12px;
                }
              }
            }
          }
        }
      }
    }

    &-completed {
      margin-bottom: 30px;
      width: 100%;
      &-caption {
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
      }

      &-tasks {
        display: inline-grid;
        width: 100%;
        grid-template-columns: 49% 49%;
        gap: 10px;

        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 16px;
          background-color: $color-white;
          &-icon {
            width: 50px;
            height: 50px;
            margin-top: 20px;
            margin-bottom: 10px;
            background-color: $color_gray4;

            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
          }

          &-name {
            text-align: center;
            margin: 0 10px 20px 10px;
          }
        }
      }
    }
  }
}
