@import '../../../styles/common';

.relative-container {
  height: 100%;
  position: relative;
}
.perx-money-for-the-card-page {
  background-color: $color-white;
  width: 100%;

  &__header {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $color-white;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;

      &-wallet-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 84px;
        width: calc(100% - 32px);
        color: $color-white;

        &-caption {
          margin: 5px 0 5px 0;
          font-weight: bold;
          font-size: 18px;
          line-height: 19px;
        }

        &-label {
          margin: 0;
          font-size: 14px;
          line-height: 26px;
          text-align: center;
        }
      }
    }

    &-faq {
      position: relative;
      right: 23px;
      top: 25px;
      border: 1px solid $color-black;
      border-radius: 50%;
      margin-top: -30px;
      margin-left: calc(100% - 10px);

      & path {
        fill: $color-black;
      }
    }

    &-btn-container {
      width: 100%;
      background-image: linear-gradient(to bottom, rgba($color-white, 0.1), rgba($color-white, 1));
      padding: 15px 0;
      display: flex;
      justify-content: center;
      position: fixed;

      &-button {
        height: 60px;
        width: calc(100% - 32px);
        border-radius: 8px;
        background: $color-green3;
        font-size: 17px;
        color: $color-white;
        font-weight: bold;

        &:disabled {
          opacity: 0.3;
        }
      }
    }
  }
}