@import '../../../../styles/common';

.perx-error-page {
  position: relative;
  height: 100%;
  display: flex;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &-image-container {
      position: relative;
      margin-bottom: 68px;
      display: flex;

      &-image {
        width: 100%;
        height: 100%;
      }
    }

    &-title-container {
      position: absolute;
      bottom: 115px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;

      &-label {
        font-size: 18px;
        text-align: center;
        margin: 5px;
      }

      &-title {
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        margin: 5px;
      }
    }

    &-button-container {
      width: 100%;
      padding: 15px 0;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 15px;

      &-btn {
        height: 60px;
        width: calc(100% - 32px);
        border-radius: 8px;
        background: $color-aquamarin;
        font-size: 17px;
        color: $color-white;
        font-weight: bold;
      }
    }
  }
}
