@import '../../styles/common';

$text_and_background_color: $color-aquamarin;
$background_white_color: $color-white;
$text_white_color: $color-white;

.successful-product-order {
  background-color: $background_white_color;
  width: 100%;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15% 16px 0 16px;

    &-image {
      position: absolute;
      top: 15%;
      width: 70%;
      max-height: 45%;
    }

    &-info-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 62%;

      &-text {
        font-size: 22px;
        line-height: 26px;
        font-weight: bold;
        display: flex;
        align-items: center;
        text-align: center;
        margin: 0;
      }
    }

    &-buttons {
      width: 90%;
      position: absolute;
      top: 73%;

      &-prizes {
        width: 100%;
        margin-bottom: 5px;

        a {
          text-decoration: none;

          .btn-component {
            background: $background_white_color;
            width: 100%;
            height: 60px;
            border: 1px solid $text_and_background_color;
            border-radius: 8px;
            font-size: 17px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $text_and_background_color;
          }
        }
      }
      &-bonus-shop {
        width: 100%;
        a {
          text-decoration: none;

          .btn-component {
            width: 100%;
            height: 60px;
            background: $text_and_background_color;
            border-radius: 8px;
            font-size: 17px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $text_white_color;
          }
        }
      }
    }
  }
}
