@import "../../../styles/common";

.signup-page {
  padding: 0 16px 0 16px;
  height: 100%;
  min-height: 745px;
  background: inherit;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  &__progress-bar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__info-icon {
    position: absolute;
    top: 0;
    right: 10px;
  }

  &__account-code-container {
    position: relative;
  }

  &__hungary {
    min-height: 900px;
  }

  &__caucasus {
    min-height: 900px;
  }

  &__top-section {
  }

  &__bottom-section {
    display: flex;
    flex-flow: column;
    margin-bottom: 25px;
    justify-content: flex-end;
  }

  &__back-button {
    background: transparent;
    margin-top: 56px;
    border: 0;
    padding: 0;
  }
  :focus {
    outline: none;
  }

  &__top-arrow {
    height: 21px;
    fill: $color-white;
  }

  &__top-arrow:hover {
    cursor: pointer;
  }

  &__title {
    margin: 43px 0 50px;
    font-weight: 900;
    font-size: 32px;
    line-height: 37px;
    color: $color-white;
  }

  &__input {
    margin-bottom: 35px;

    &-default {
      margin-bottom: 35px;
      opacity: 0.9;
    }
  }

  input[type="date"]::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-month-field {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
  }

  ::placeholder {
    color: $color-white;
    opacity: 0.8;
  }

  &__submit-btn {
    justify-self: flex-end;
    align-self: flex-end;
    width: 51px;
    height: 51px;
    padding: 0;
    background: $color-white;
    border-radius: 50%;
  }

  &__submit-btn:disabled {
    opacity: 0.3;
  }
}
