@import "src/styles/common";

.container {
  .header {
    margin-bottom: 30px;

    .headerText {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: $color-white;
    }
  }

  .backButton {
    background: transparent;
    position: absolute;
    top: 25px;
    left: 10px;
    z-index: 5;
  }

  .cardWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 0 10px 15px;
    position: relative;
    height: 70px;
    margin: 0 16px 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 16px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $color-white;
    }

    .participation {
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      color: $color-white;
    }

    .arrow {
      position: absolute;
      right: 21px;
      top: 40%;
    }
  }
}