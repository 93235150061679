@import '../../../../styles/common';

.userComponent {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 72px;
  margin-bottom: 15px;
  margin-top: 5px;

  .imageContainer {
    .userPhoto {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;

    .greetings {
      color: $color-dark2;
      font-size: 20px;
      font-weight: 500;
      margin: 0 0 5px;
    }

    .earned {
      color: $color-dark2;
      font-size: 10px;
      font-weight: 400;
      margin: 0;
    }
  }

  .coins {
    padding: 8px 12px 8px 13px;
    border-radius: 16px;
    background: $color-dark2;
    text-align: center;
    color: $color-white;
    font-size: 10px;
    font-weight: 400;
    line-height: 120%;
  }

  .coins_theme8 {
    background: $color-yellow-mybrand;
    color: $color-blue-mybrand;
    .boldCoins {
      font-weight: 700;
    }
  }
}
