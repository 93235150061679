@import '../../styles/common';
$background_white_color: $color-white;

.privacy-policy-update-page {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background-color: $background_white_color;
}

.privacy-policy-update {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__notification {
    margin-top: 150px;
    margin-left: 16px;
    margin-right: 16px;
    &-caption {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 120%;
      text-align: center;
    }

    &-text {
      margin-top: 20px;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
    }
  }

  &__bottom-section {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 30px;

    &-links {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-link {
        margin-bottom: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        text-decoration-line: underline;
      }
    }

    &-button {
      margin-top: 30px;
      width: 100%;
      height: 60px;
      border-radius: 8px;
      color: $color-white;
      font-size: 17px;
      line-height: 20px;
      font-weight: bold;
      text-align: center;
    }
  }
}
