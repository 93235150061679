@import '../../../styles/common';

.container {
  display: flex;
  flex-direction: column;
  background: $color-gray9;
  border-radius: 10px;

    .imageWrapper {
      display: flex;
      justify-content: center;
      position: relative;

      .image {
        height: 162px;
        margin-top: 5px;
        background: $color-white;
        border-radius: 10px;
        width: calc(100% - 10px);
      }

      .statusInfo {
        position: absolute;
        bottom: -6px;
        left: 5px;
        color: $color-white;
        background: transparent;
        text-align: center;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        padding: 3px 11px;
        z-index: 1;
        border-radius: 2px;
      }
    }

    .text {
      text-align: center;
      margin: 20px 5px;
      font-size: 14px;
      line-height: 17px;
    }
  }
