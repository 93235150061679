@import '../../../styles/common';

.achievements-tutorial {
  $blackout_top_statistik: 182px;
  $statistik_Height: 93px;
  $blackout_top_statistik_wide: 165px;

  $blackout_top_firstItem: 289px;
  $firstItem_Height: 160px;
  $blackout_top_firstItem_wide: 272px;

  $blackout_top_firstTaskItem: 162px;
  $firstTaskItem_Height: 103px;
  $blackout_top_firstTaskItem_wide: 161px;

  $button_next-position_bottom: 50px;

  $descriptionBox_position_right: 32px;
  $descriptionBox_position_left: $descriptionBox_position_right;
  $descriptionBox_position_bottom: 130px;
  $descriptionBox_position_top: 100px;

  $skip_position_top: 20px;
  $skip_position_right: 20px;
  $skip_width: 62px;
  $skip_height: 29px;

  &__full-blackout {
    background: $color-black;
    position: absolute;
    top: 0px;
    z-index: 2;
    opacity: 0.7;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &__lockClick-firstTaskItem {
    position: absolute;
    z-index: 2;
    top: calc(#{$blackout_top_statistik} - 20px);
    left: 7px;
    right: 7px;
    height: calc(#{$statistik_Height} + 10px);
  }

  &__blackout {
    background: $color-black;
    position: absolute;
    z-index: 2;
    opacity: 0.7;

    &-statistik {
      &-top {
        @extend .achievements-tutorial__blackout;
        top: 0px;
        right: 0;
        left: 0;
        height: $blackout_top_statistik;
      }

      &-left {
        @extend .achievements-tutorial__blackout;
        top: $blackout_top_statistik;
        left: 0px;
        height: $statistik_Height;
        width: 6px;
      }

      &-right {
        @extend .achievements-tutorial__blackout;
        top: $blackout_top_statistik;
        right: 0px;
        height: $statistik_Height;
        width: 6px;
      }

      &-bottom {
        @extend .achievements-tutorial__blackout;
        top: calc(#{$blackout_top_statistik} + #{$statistik_Height});
        right: 0px;
        left: 0;
        bottom: 0;
      }

      @media screen and (min-width: 519px) {
        &-top {
          height: $blackout_top_statistik_wide;
        }
        &-left {
          top: $blackout_top_statistik_wide;
        }
        &-right {
          top: $blackout_top_statistik_wide;
        }
        &-bottom {
          top: calc(#{$blackout_top_statistik_wide} + #{$statistik_Height});
        }
      }
    }

    &-firstItem {
      &-top {
        @extend .achievements-tutorial__blackout;
        top: 0px;
        right: 0;
        left: 0;
        height: $blackout_top_firstItem;
      }

      &-left {
        @extend .achievements-tutorial__blackout;
        top: $blackout_top_firstItem;
        left: 0px;
        height: $firstItem_Height;
        width: 6px;
      }

      &-right {
        @extend .achievements-tutorial__blackout;
        top: $blackout_top_firstItem;
        right: 0px;
        height: $firstItem_Height;
      }

      &-bottom {
        @extend .achievements-tutorial__blackout;
        top: calc(#{$blackout_top_firstItem} + #{$firstItem_Height});
        right: 0px;
        left: 0;
        bottom: 0;
      }

      @media screen and (min-width: 519px) {
        &-top {
          height: $blackout_top_firstItem_wide;
        }
        &-left {
          top: $blackout_top_firstItem_wide;
        }
        &-right {
          top: $blackout_top_firstItem_wide;
        }
        &-bottom {
          top: calc(#{$blackout_top_firstItem_wide} + #{$firstItem_Height});
        }
      }
    }

    &-firstTaskItem {
      &-top {
        @extend .achievements-tutorial__blackout;
        top: 0px;
        right: 0;
        left: 0;
        height: $blackout_top_firstTaskItem;
      }

      &-left {
        @extend .achievements-tutorial__blackout;
        top: $blackout_top_firstTaskItem;
        left: 0px;
        height: $firstTaskItem_Height;
        width: 6px;
      }

      &-right {
        @extend .achievements-tutorial__blackout;
        top: $blackout_top_firstTaskItem;
        right: 0px;
        height: $firstTaskItem_Height;
        width: 6px;
      }

      &-bottom {
        @extend .achievements-tutorial__blackout;
        top: calc(#{$blackout_top_firstTaskItem} + #{$firstTaskItem_Height});
        right: 0px;
        left: 0;
        bottom: 0;
      }

      @media screen and (min-width: 519px) {
        &-top {
          height: $blackout_top_firstTaskItem_wide;
        }
        &-left {
          top: $blackout_top_firstTaskItem_wide;
        }
        &-right {
          top: $blackout_top_firstTaskItem_wide;
        }
        &-bottom {
          top: calc(#{$blackout_top_firstTaskItem_wide} + #{$firstTaskItem_Height});
        }
      }
    }
  }

  &__button {
    width: 279px;
    height: 50px;
    position: fixed;
    bottom: $button_next-position_bottom;
    z-index: 5;
    background: $color-white;
    border-radius: 8px;

    &-box {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &-next {
      bottom: $button_next-position_bottom;
      right: 16px;
      z-index: 5;
      position: fixed;
      background: none;
    }
  }

  &__descriptionBox {
    position: fixed;
    background: $color-white;
    border: 1px solid $color-aquamarin;
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    color: $color-black;
    padding: 40px 16px;
    z-index: 5;

    &-bottom {
      @extend .achievements-tutorial__descriptionBox;
      left: $descriptionBox_position_left;
      right: $descriptionBox_position_right;
      bottom: $descriptionBox_position_bottom;
    }
    &-top {
      @extend .achievements-tutorial__descriptionBox;
      left: $descriptionBox_position_left;
      right: $descriptionBox_position_right;
      top: $descriptionBox_position_top;
    }

    &-header {
      font-weight: bold;
      font-size: 18px;
      line-height: 110%;
      text-align: center;
    }

    &-description {
      margin-top: 10px;
      font-size: 14px;
      line-height: 110%;
      text-align: center;
    }

    &-skip {
      height: 27px;
      margin-top: 10px;
      font-size: 12px;
      line-height: 110%;
      text-align: center;
    }

    &-step {
      margin-bottom: 10px;
      font-size: 10px;
      line-height: 110%;
      text-align: center;
    }
  }

  &__skip {
    position: fixed;
    width: $skip_width;
    height: $skip_height;
    right: $skip_position_right;
    top: $skip_position_top;
    padding: 0 2px;
    background: $color-white;
    border-radius: 42px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 100%;

    &-icon {
      margin-left: 6px;
    }
  }
}
