@import '../../../../styles/common';

.notificationMessage {
  padding: 40px 15px;
  background-color: $color-white;
  border-radius: 8px;
  position: relative;

  .newMessageBadge {
    position: absolute;
    right: -3px;
    top: -3px;
  }

  .time,
  .image,
  .caption {
    margin-bottom: 10px;
  }

  .time {
    color: $color-gray4;
    font-size: 10px;
  }

  .caption {
    font-size: 16px;
    font-weight: bold;
  }

  .text {
    font-size: 14px;
  }

  .image {
    width: 100%;
  }

  .button {
    margin-top: 30px;
    width: 100%;
  }
}
