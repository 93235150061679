@import '../../../../styles/common';

.verification-step {
  &__reward-image {
    width: 100%;
    max-height: 362px;
    height: auto;
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-top: 10px;
    text-align: center;
  }

  &__reward {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: $color-black;
    text-shadow: 0px 5px 5px $color-shadow1;
  }
  &__description {
    margin-top: 10px;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  &__btn {
    width: 100%;
    border-radius: 8px;
    background-color: $color_green1;
    color: $color_white;
    height: 60px;
    margin: 30px 0 40px 0;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
  }
}