@import '../../../styles/common';

.solo-mode-start {
  &__invite-accepted-form {
    display: flex;
    min-width: 280px;
    width: calc(100% - 32px);
    flex-direction: column;
    justify-content: space-between;
  }

  &__invite-accepted-main {
    width: 100%;
    margin-top: 60px;
  }

  &__time-text {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 35px;
  }
}

.solo-mode-game {
  &__points {
    font-weight: 500;
    font-size: 21px;
    line-height: 19px;
    margin-top: 20px;
    text-align: center;
  }
}

.solo-mode-result {
  &__info {
    padding: 130px 0 30px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__points-count {
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    font-weight: bold;
    margin-top: 40px;
  }

  &__corect_answers {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
}
