@import "../../../styles/common";

.verify__component {
  display: flex;
  height: 100%;
  flex-flow: column;
  justify-content: space-between;
  background: inherit;
}
.verify__irl-component {
  display: flex;
  height: 100%;
  flex-flow: column;
  justify-content: space-between;
  background-image: url('../../../images/background-images/bg-lep-img.jpg');
  background-size: 100% 100%;
}

.verify__top-button {
  background: transparent;
  border: 0;
  padding: 0;
  width: 21px;
}

.verify__top-button:focus {
  outline: none;
}

.verify__top-section {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 24px;
}

.verify__middle-section {
  height: 40%;
  display: flex;
  flex-flow: column;
}

.verify__bottom-section {
  display: flex;
  flex-flow: column;

  &__arrow-next-button {
    background-color: $color-green2;
  }
}

.verify__top-arrow {
  height: 21px;
  margin-left: -5px;
}

.verify__top-arrow:hover {
  cursor: pointer;
}

.verify__title {
  margin: 0;
  margin-top: 27px;
  background: transparent;
  color: $color-white;
}
.verify__irl-title {
  margin: 27px 0 0;
  background: transparent;
  color: $color-green6;
}

.verify__text {
  max-width: 300px;
  color: $color-white;
  margin: 0 7px 0 0;
}
.verify__irl-text {
  max-width: 300px;
  color: $color-green6;
  margin: 0 7px 0 0;
}

.verify__link {
  color: $color-white;
  text-decoration: underline;
}
.verify__irl-link {
  color: $color-green6;
  text-decoration: underline;
}

.verify__radio-container {
  display: inline-flex;
  margin-top: 40px;
  justify-content: space-between;
}

.verify__radio--irl-container {
  display: inline-flex;
  margin-top: 40px;
  justify-content: space-between;

  .radio-button + label:before {
    background: $color-gray10;
  }

  .radio-button + label:after {
    background: $color-white;
  }

  .radio-button:checked + label:before {
    background: $color-green6;
  }
  .radio-button:checked + label:after {
    background: $color-yellow;
    left: 16px;
  }
}

.verify__code {
  color: $color-white;
  border-color: $color-white;
}
.verify__irl-code {
  color: $color-green6;
  border-color: $color-green6;
}

.verify__icon {
  width: 100%;
  height: 19px;
  fill: $color-green4;
  padding-left: 3px;
  padding-top: 4px;
}

.verify__btn {
  justify-self: flex-end;
  align-self: flex-end;
  width: 51px;
  height: 51px;
  padding: 0;
  background: $color-white;
  border-radius: 50%;
  margin: 10px 16px 30px 0;
}

.verify__btn:disabled {
  opacity: 0.3;
}

.code-input__error {
  color: $color-white;
}

.code-input__container {
  margin-bottom: 10px;
  margin-top: 50px;
}
