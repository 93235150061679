@import '../../../styles/common';

.loading {
  width: 100%;
  height: 100%;

  .animationContainer {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, $color-black 100%);
  }

  .description {
    position: absolute;
    bottom: 0;
    left: 50%;
    padding-bottom: 50px;
    transform: translateX(-50%);
    max-width: 340px;
    margin: 0 auto 0 auto;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: $color-white;
  }
}
