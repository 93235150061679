.root {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  :global {
    .stub {
      height: 100%;
      width: 100%;
      &.tabaterra_az {
        background-image: url('./assets/tabaterra_az.jpg');
        background-size: cover;
        background-position: center;
      }
    }
  }
}
