@import '../../../styles/common';

.faq-check-in {
  &__component {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: inherit;
  }
  &__bot-section {
  display: flex;
  flex-flow: column;
  margin-top: 30px;
  margin-bottom: 30px;
  }
  &__submit {
  height: 60px;
  width: 100%;
  border-radius: 8px;
  background: $color-white;
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  }
}