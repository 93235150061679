@import '../../styles/common';

$header_and_button_background_color: $color-aquamarin;
$background_white_color: $color-white;
$text_white_color: $color-white;

$header-height: 102px;
$footer-padding: 120px;

.root {
  :global {
    swiper-container {
      width: 100%;
      height: 100svh;
      box-sizing: border-box;
    }

    .slide {
      padding-top: $header-height;
      padding-bottom: $footer-padding;
      overflow: auto;
      box-sizing: border-box;
    }

    .header {
      position: fixed;
      top: 0;
      $header-height: 102px;
      transition: all 0.5s linear;
      display: flex;
      z-index: 2;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .tabs {
        width: 100%;
        display: flex;
        min-height: 60px;
        align-items: flex-end;

        .tab,
        .tab-active {
          width: 33.3%;
          font-size: 16px;
          text-align: center;
          padding: 10px 0;
          color: $text_white_color;
          opacity: 0.5;
          font-weight: bold;
        }

        .tab-active {
          opacity: 1;
          padding: 10px 0 9px 0;
          border-bottom: 1px solid;
          box-sizing: border-box;
        }
      }
    }
  }
}
