@import 'src/styles/common';

.chatTab {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 10px 20px;
  height: 40px;
  min-width: 140px;
  font-size: 14px;
  font-weight: bolder;
  color: $color-white;
  box-sizing: border-box;
  background-color: $color-black;
  border: 1px solid $color-gray6;

  &.active {
    border-color: transparent;
  }
}
