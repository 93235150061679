@import '../../../styles/common';

.choice-check-in{
  &__component {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: inherit;
  }
  &__mode{
   display: flex;
   justify-content: space-between;
   border: 1px solid $color-shadow2;
   box-sizing: border-box;
   border-radius: 8px;
   margin-bottom: 5px;  
  }
  &__selected-mode{
    display: flex;
    justify-content: space-between;
    border: 1px solid $color-white;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 5px;  
  }
  &__bold-text{
    font-size: 14px;
    line-height: 17px;
    margin-left: 16px;
    margin-top: 14px;
    color: $color-white; 
    margin-bottom: 0px;   
  }
  &__text{
    font-size: 11px;
    line-height: 13px;
    margin-top: 0px;
    margin-left: 16px;
    margin-bottom: 15px;
    color: $color-white;  
  }
  &__select-section{
     padding-top: 52px; 
  }
  &__check-icon{
    width: 19.2px;
    height: 15.16px;
  }
  &__check-icon-container {
    margin-right: 16px;
    margin-top: 12px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;
    border-radius: 50%;
  }
}