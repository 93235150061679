@import "src/styles/common";

.container {
  position: relative;
  height: 100%;
  display: flex;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0 16px;
    width: 100%;

    .imageContainer {
      width: 343px;
      height: 343px;

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .description {
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      text-align: center;
    }

    .button {
      height: 60px;
      width: 100%;
      border-radius: 8px;
      font-size: 17px;
      color: $color-white;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}