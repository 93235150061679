@import '../../styles/common';

$background_color: $color-white;
$text_color: $color-black;
$button_background_color: $color-aquamarin;
$border-color: $color-gray1;
$text-warning-color: $color-red1;

.product-info-block__button-container {
  display: flex;
  flex-direction: column;
  background: $background_color;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  width: calc(100% - 32px);

  &_unavailable {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: $text-warning-color;
  }
}

.purchase-limit-warning {
  margin-bottom: 1rem;
  text-align: center;
}

.carousel-block {
  padding-bottom: 15px;
  width: 100%;
  overflow: hidden;

  &--swiper-container {
    margin: 0;
    width: 260px;
  }

  .swiper-wrapper {
    height: 210px;
    align-items: center;

    .item-image {
      border-radius: 8px;
    }

    .swiper-slide {
      transition: all 0.1s;
      position: relative;

      & svg {
        position: absolute;
        top: 13px;
        right: 13px;
        z-index: 3;
      }

      .item-image {
        display: none;
        width: 200px;
        height: 150px;
        position: absolute;
        z-index: 1;
      }
    }

    .swiper-slide-active {
      min-width: 220px;
      height: 150px;
      position: relative;
      margin-left: 16px;

      .item-image {
        display: block;
        width: 200px;
        height: 150px;
        border: 0.5px solid rgb(230, 230, 230);
        box-sizing: border-box;
        box-shadow: rgba(0, 0, 0, 0.1) 0 5px 20px;
      }
    }

    .swiper-slide-next {
      min-width: 198px;
      height: 135px;

      .item-image {
        display: block;
        width: 198px;
        height: 135px;
        opacity: 0.2;
        border: 0.5px solid rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      }
    }
  }
  .products {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-x: scroll;

    &__product-physical-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-left: 5px;
    }

    &__product-color {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
    }

    .not-selected {
      opacity: 0.1;
    }

    .selected-physical {
      border: 3px solid $color-green1;
      box-sizing: border-box;
    }

    &__product-color-name {
      margin-top: 5px;
      text-align: center;
    }

    &-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-bottom: 2px solid $color-gray1;

      &-product {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 25px;

        p {
          margin: 0;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
        }
      }
      .selected {
        border: 1px solid black;
        border-radius: 100px;
      }

      &-no-products-info {
        height: 25px;
      }
    }
  }
}

.modal-item-image {
  border: 0.5px solid $color-gray10;
  box-sizing: border-box;
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
  border-radius: 16px;
  width: 146px;
  height: 100px;
  object-fit: cover;
  margin: auto;
  margin-top: 60px;
}

.carousel-last-item {
  margin: 0 0 0 -25px;
}
