@import './styles/common';

// TODO Rewrite using Sass mixins

$themeColor1: $color-red;
$themeColor2: $color-aquamarin;
$themeColor3: $color-orange;
$themeColor4: $color-violet;
$themeColor5: $color-grapefruit;
// Taiwan
$themeColor6: $color-orange5;
// Tabaterra AZ
$themeColor7: $color-green-tabaterra;
// Mybrand AZ
$themeColor8: $color-blue-mybrand;

$themeHeight: 100%;

#root {
  height: $themeHeight;
  overflow: auto;
}

:root {
  --theme-color: $color-white;
}

.iphoneXTheme {
  max-height: calc(100% - 49px);
}

.theme1 {
  height: $themeHeight;

  .themed-background,
  .themedButton,
  .themedMessage,
  .themedProblem:active,
  .header-component,
  .chat-message__content-text,
  .profile-page__swipeable-header,
  .prizes-page__checkbox:checked + .prizes-page__label,
  .swipeable-pages__tabs,
  .activeAnswer:checked + .activeAnswer:before {
    background-color: $themeColor1;
  }

  .btn-component.themed,
  .prizes-page__markerNew,
  .notification-badge-component {
    color: $color-white;
    background-color: $themeColor1;
  }

  .themed-background-image,
  .profile-page__content-info-block,
  .daily-rewards,
  .promo-code-page,
  .edit-profile-form,
  .survey-page,
  .chance-page__is-playing,
  .chance-page__is-playing-animation,
  .progress-page,
  .address-registration-page__content,
  .manager-page,
  .formContainer,
  .passport-settings,
  .ba-statistic-page {
    background-image: url('./images/background-images/bg-image-1.png');
    background-size: cover;
    background-position: center;
  }

  .team-challenge-time-remain,
  .themedModalButton,
  .themedWhiteButton,
  .achievements-summary-info-amount,
  .quiz-card-block-main-name.themed,
  .prizesUnused,
  .challenge-block__container-time-caption {
    color: $themeColor1;
  }

  .themedModalButton,
  .achievements-summary,
  .challenge-block,
  .chance-block,
  .prizes-page__checkbox,
  .input-border-theme {
    border-color: $themeColor1;
  }

  .activeAnswer:checked + .activeAnswer,
  .themedWhiteButton,
  .prizes-page__label {
    border: 1px solid $themeColor1;
  }

  .right-arrow {
    stroke: $themeColor1;
  }

  .link {
    color: $themeColor1;
  }

  .lottie-spinner path {
    stroke: $themeColor1;
    fill: $themeColor1;
  }

  .enlargeImage path {
    fill: $themeColor1;
  }
}

.theme2 {
  height: $themeHeight;

  .themed-background,
  .themedButton,
  .themedMessage,
  .themedProblem:active,
  .header-component,
  .swipeable-pages__tabs,
  .chat-message__content-text,
  .profile-page__swipeable-header,
  .btn-component.themed,
  .prizes-page__markerNew,
  .prizes-page__checkbox:checked + .prizes-page__label,
  .notification-badge-component,
  .activeAnswer:checked + .activeAnswer:before {
    background-color: $themeColor2;
  }

  .themed-background-image,
  .profile-page__content-info-block,
  .daily-rewards,
  .promo-code-page,
  .edit-profile-form,
  .survey-page,
  .chance-page__is-playing,
  .chance-page__is-playing-animation,
  .progress-page,
  .address-registration-page__content,
  .manager-page,
  .formContainer,
  .idCard,
  .passport-settings,
  .ba-statistic-page {
    background-image: url('./images/background-images/bg-image-2.png');
    background-size: cover;
    background-position: center;
  }

  .team-challenge-time-remain,
  .themedModalButton,
  .themedWhiteButton,
  .achievements-summary-info-amount,
  .quiz-card-block-main-name.themed,
  .prizesUnused,
  .challenge-block__container-time-caption {
    color: $themeColor2;
  }

  .themedModalButton,
  .achievements-summary,
  .challenge-block,
  .chance-block,
  .input-border-theme {
    border-color: $themeColor2;
  }

  .activeAnswer:checked + .activeAnswer,
  .themedWhiteButton,
  .prizes-page__label {
    border: 1px solid $themeColor2;
  }

  .right-arrow {
    stroke: $themeColor2;
  }

  .link {
    color: $themeColor2;
  }

  .lottie-spinner path {
    stroke: $themeColor2;
    fill: $themeColor2;
  }

  .enlargeImage path {
    fill: $themeColor2;
  }
}

.theme3 {
  height: $themeHeight;

  .themed-background,
  .themedButton,
  .themedMessage,
  .themedProblem:active,
  .header-component,
  .swipeable-pages__tabs,
  .chat-message__content-text,
  .notification-badge-component,
  .btn-component.themed,
  .prizes-page__markerNew,
  .prizes-page__checkbox:checked + .prizes-page__label,
  .profile-page__swipeable-header,
  .activeAnswer:checked + .activeAnswer:before {
    background-color: $themeColor3;
  }

  .themed-background-image,
  .profile-page__content-info-block,
  .daily-rewards,
  .promo-code-page,
  .edit-profile-form,
  .survey-page,
  .chance-page__is-playing,
  .chance-page__is-playing-animation,
  .progress-page,
  .address-registration-page__content,
  .manager-page,
  .formContainer,
  .passport-settings,
  .ba-statistic-page {
    background-image: url('./images/background-images/bg-image-3.png');
    background-size: cover;
    background-position: center;
  }

  .team-challenge-time-remain,
  .themedModalButton,
  .themedWhiteButton,
  .achievements-summary-info-amount,
  .quiz-card-block-main-name.themed,
  .prizesUnused,
  .challenge-block__container-time-caption {
    color: $themeColor3;
  }

  .themedModalButton,
  .achievements-summary,
  .challenge-block,
  .chance-block,
  .input-border-theme {
    border-color: $themeColor3;
  }

  .activeAnswer:checked + .activeAnswer,
  .themedWhiteButton,
  .prizes-page__label {
    border: 1px solid $themeColor3;
  }

  .right-arrow {
    stroke: $themeColor3;
  }

  .link {
    color: $themeColor3;
  }

  .lottie-spinner path {
    stroke: $themeColor3;
    fill: $themeColor3;
  }

  .enlargeImage path {
    fill: $themeColor3;
  }
}

.theme4 {
  height: $themeHeight;

  .themed-background,
  .themedButton,
  .themedMessage,
  .themedProblem:active,
  .header-component,
  .swipeable-pages__tabs,
  .chat-message__content-text,
  .notification-badge-component,
  .btn-component.themed,
  .prizes-page__markerNew,
  .prizes-page__checkbox:checked + .prizes-page__label,
  .profile-page__swipeable-header,
  .activeAnswer:checked + .activeAnswer:before {
    background-color: $themeColor4;
  }

  .themed-background-image,
  .profile-page__content-info-block,
  .daily-rewards,
  .promo-code-page,
  .edit-profile-form,
  .survey-page,
  .chance-page__is-playing,
  .chance-page__is-playing-animation,
  .progress-page,
  .address-registration-page__content,
  .manager-page,
  .formContainer,
  .passport-settings,
  .ba-statistic-page {
    background-image: url('./images/background-images/bg-image-4.png');
    background-size: cover;
    background-position: center;
  }

  .team-challenge-time-remain,
  .themedModalButton,
  .themedWhiteButton,
  .achievements-summary-info-amount,
  .quiz-card-block-main-name.themed,
  .prizesUnused,
  .prizes-page__markerNew,
  .challenge-block__container-time-caption {
    color: $themeColor4;
  }

  .themedModalButton,
  .achievements-summary,
  .challenge-block,
  .chance-block,
  .input-border-theme {
    border-color: $themeColor4;
  }

  .activeAnswer:checked + .activeAnswer,
  .themedWhiteButton,
  .prizes-page__label {
    border: 1px solid $themeColor4;
  }

  .right-arrow {
    stroke: $themeColor4;
  }

  .link {
    color: $themeColor4;
  }

  .lottie-spinner path {
    stroke: $themeColor4;
    fill: $themeColor4;
  }

  .enlargeImage path {
    fill: $themeColor4;
  }
}

.theme5 {
  height: $themeHeight;

  .themed-background,
  .themedButton,
  .themedMessage,
  .themedProblem:active,
  .header-component,
  .swipeable-pages__tabs,
  .chat-message__content-text,
  .notification-badge-component,
  .btn-component.themed,
  .prizes-page__markerNew,
  .prizes-page__checkbox:checked + .prizes-page__label,
  .profile-page__swipeable-header,
  .activeAnswer:checked + .activeAnswer:before {
    background-color: $themeColor5;
  }

  .themed-background-image,
  .profile-page__content-info-block,
  .daily-rewards,
  .promo-code-page,
  .edit-profile-form,
  .survey-page,
  .chance-page__is-playing,
  .chance-page__is-playing-animation,
  .progress-page,
  .address-registration-page__content,
  .manager-page,
  .formContainer,
  .passport-settings,
  .ba-statistic-page {
    background-image: url('./images/background-images/bg-image-5.png');
    background-size: cover;
    background-position: center;
  }

  .team-challenge-time-remain,
  .themedModalButton,
  .themedWhiteButton,
  .achievements-summary-info-amount,
  .quiz-card-block-main-name.themed,
  .prizesUnused,
  .prizes-page__markerNew,
  .challenge-block__container-time-caption {
    color: $themeColor5;
  }

  .themedModalButton,
  .achievements-summary,
  .challenge-block,
  .chance-block,
  .input-border-theme {
    border-color: $themeColor5;
  }

  .activeAnswer:checked + .activeAnswer,
  .themedWhiteButton,
  .prizes-page__label {
    border: 1px solid $themeColor5;
  }

  .right-arrow {
    stroke: $themeColor5;
  }

  .link {
    color: $themeColor5;
  }

  .lottie-spinner path {
    stroke: $themeColor5;
    fill: $themeColor5;
  }

  .enlargeImage path {
    fill: $themeColor5;
  }
}

.theme6 {
  height: $themeHeight;

  .box-container {
    border: 1px solid $color_gray4;

    &__expiration-time {
      background-color: $themeColor6;
      color: $color-white;

      svg > path {
        stroke: $color-white;
      }
    }
  }

  .themed-svg-stroke {
    stroke: $themeColor6 !important;
    path {
      stroke: $themeColor6 !important;
    }
  }

  .themed-svg-fill {
    path {
      fill: $themeColor6 !important;
    }
    fill: $themeColor6 !important;
  }

  themed-border {
    border-color: $themeColor6;
  }

  .themed-background,
  .themedButton,
  .themedMessage,
  .themedProblem:active,
  .header-component,
  .swipeable-pages__tabs,
  .chat-message__content-text,
  .notification-badge-component,
  .btn-component.themed,
  .prizes-page__markerNew,
  .prizes-page__checkbox:checked + .prizes-page__label,
  .profile-page__swipeable-header,
  .activeAnswer:checked + .activeAnswer:before {
    background-color: $themeColor6;
  }

  .themed-background-image,
  .profile-page__content-info-block,
  .daily-rewards,
  .promo-code-page,
  .edit-profile-form,
  .survey-page,
  .chance-page__is-playing,
  .chance-page__is-playing-animation,
  .progress-page,
  .address-registration-page__content,
  .manager-page,
  .formContainer,
  .passport-settings,
  .ba-statistic-page {
    background-image: url('./images/background-images/bg-image-6.png');
    background-size: cover;
    background-position: center;
  }

  .team-challenge-time-remain,
  .themedModalButton,
  .themedWhiteButton,
  .achievements-summary-info-amount,
  .quiz-card-block-main-name.themed,
  .prizesUnused,
  .prizes-page__markerNew,
  .challenge-block__container-time-caption {
    color: $themeColor6;
  }

  .themedModalButton,
  .achievements-summary,
  .challenge-block,
  .chance-block,
  .input-border-theme {
    border-color: $themeColor6;
  }

  .activeAnswer:checked + .activeAnswer,
  .themedWhiteButton,
  .prizes-page__label {
    border: 1px solid $themeColor6;
  }

  .right-arrow {
    stroke: $themeColor6;
  }

  .link {
    color: $themeColor6;
  }

  .lottie-spinner path {
    stroke: $themeColor6;
    fill: $themeColor6;
  }

  .enlargeImage path {
    fill: $themeColor6;
  }
}

.theme7 {
  height: $themeHeight;

  .box-container__expiration-time {
    background-color: $color-yellow-tabaterra;

    color: $themeColor7;
    svg > path {
      stroke: $themeColor7;
    }
  }

  .themed-svg-stroke {
    stroke: $themeColor7 !important;
    path {
      stroke: $themeColor7 !important;
    }
  }

  .themed-svg-fill {
    path {
      fill: $themeColor7 !important;
    }
    fill: $themeColor7 !important;
  }

  themed-border {
    border-color: $themeColor7;
  }

  .themed-background,
  .themedButton,
  .themedMessage,
  .themedProblem:active,
  .header-component,
  .swipeable-pages__tabs,
  .chat-message__content-text,
  .notification-badge-component,
  .btn-component.themed,
  .prizes-page__markerNew,
  .prizes-page__checkbox:checked + .prizes-page__label,
  .profile-page__swipeable-header,
  .activeAnswer:checked + .activeAnswer:before {
    background-color: $themeColor7;
  }

  .themed-background-image,
  .profile-page__content-info-block,
  .daily-rewards,
  .promo-code-page,
  .edit-profile-form,
  .survey-page,
  .chance-page__is-playing,
  .chance-page__is-playing-animation,
  .progress-page,
  .address-registration-page__content,
  .manager-page,
  .formContainer,
  .passport-settings,
  .ba-statistic-page {
    background-image: url('./images/background-images/bg-image-7.jpg');
    background-size: cover;
    background-position: center;
  }

  .team-challenge-time-remain,
  .themedModalButton,
  .themedWhiteButton,
  .achievements-summary-info-amount,
  .quiz-card-block-main-name.themed,
  .prizesUnused,
  .prizes-page__markerNew,
  .challenge-block__container-time-caption {
    color: $themeColor7;
  }

  .themedModalButton,
  .achievements-summary,
  .challenge-block,
  .chance-block,
  .input-border-theme {
    border-color: $themeColor7;
  }

  .activeAnswer:checked + .activeAnswer,
  .themedWhiteButton,
  .prizes-page__label {
    border: 1px solid $themeColor7;
  }

  .right-arrow {
    stroke: $themeColor7;
  }

  .link {
    color: $themeColor7;
  }

  .lottie-spinner path {
    stroke: $themeColor7;
    fill: $themeColor7;
  }

  .enlargeImage path {
    fill: $themeColor7;
  }
}

.theme8 {
  height: $themeHeight;

  .box-container__expiration-time {
    background-color: $color-yellow-mybrand;

    color: $themeColor8;
    svg > path {
      stroke: $themeColor8;
    }
  }

  .themed-svg-stroke {
    stroke: $themeColor8 !important;
    path {
      stroke: $themeColor8 !important;
    }
  }
  .multibrand-quiz-page__no-bonus-game__content-area,
  .promo-code-page__success-image,
  .spec-quiz-no-bonus-game__content-area,
  .no-bonus-games-info-page__content-area {
    svg {
      path {
        stroke: $themeColor8 !important;
        fill: $themeColor8 !important;
      }
    }
  }

  .themed-svg-fill {
    path {
      fill: $themeColor8 !important;
    }
    fill: $themeColor8 !important;
  }

  themed-border {
    border-color: $themeColor8;
  }

  .themed-background,
  .themedButton,
  .themedMessage,
  .themedProblem:active,
  .header-component,
  .swipeable-pages__tabs,
  .chat-message__content-text,
  .notification-badge-component,
  .btn-component.themed,
  .prizes-page__markerNew,
  .prizes-page__checkbox:checked + .prizes-page__label,
  .profile-page__swipeable-header,
  .tab-active,
  .header-component > p,
  .tabs > .tab-active,
  .activeAnswer:checked + .activeAnswer:before {
    background-color: $themeColor8;
    color: $color-yellow-mybrand !important;
    fill: $color-yellow-mybrand !important;
  }

  .themed-background-inverted {
    background-color: $color-yellow-mybrand;
    color: $themeColor8 !important;
    fill: $themeColor8 !important;
  }

  .themed-background-image,
  .profile-page__content-info-block,
  .daily-rewards,
  .promo-code-page,
  .edit-profile-form,
  .survey-page,
  .chance-page__is-playing,
  .chance-page__is-playing-animation,
  .progress-page,
  .address-registration-page__content,
  .manager-page,
  .formContainer,
  .passport-settings,
  .ba-statistic-page {
    background-image: url('./images/background-images/bg-image-8.jpg');
    background-size: cover;
    background-position: center;
  }

  .themedModalButton,
  .themedWhiteButton,
  .achievements-summary-info-amount,
  .quiz-card-block-main-name.themed,
  .prizesUnused,
  .prizes-page__markerNew,
  .challenge-block__container-time-caption {
    color: $themeColor8;
  }

  .themedModalButton,
  .achievements-summary,
  .challenge-block,
  .chance-block,
  .input-border-theme {
    border-color: $themeColor8;
  }

  .activeAnswer:checked + .activeAnswer,
  .themedWhiteButton,
  .prizes-page__label {
    border: 1px solid $themeColor8;
  }

  .right-arrow {
    stroke: $themeColor8;
  }

  .link {
    color: $themeColor8;
  }

  .lottie-spinner path {
    stroke: $themeColor8;
    fill: $themeColor8;
  }

  .enlargeImage path {
    fill: $themeColor8;
  }
  .game-results-button-new-game,
  .team-challenge-time-remain {
    color: $color-yellow-mybrand;
  }
  .balanceboard__block-coins-positive {
    color: $color-black;
    font-size: 20px;
  }
  .balanceboard__block-coins-negative {
    font-size: 20px;
  }
  .swiper-slide > div {
    border: 1px solid $color-yellow-mybrand;
    color: $color-yellow-mybrand;
    div:first-of-type {
      color: $themeColor8;
      background: $color-yellow-mybrand;
    }
  }
  .quizGame-modal > button {
    color: $color-yellow-mybrand;
    background: $themeColor8;
  }
  .successful-product-order__content-buttons-prizes {
    .btn-component {
      border: 1px solid $themeColor8;
      color: $themeColor8;
    }
  }
  .header-arrow {
    fill: $color-yellow-mybrand !important;
  }
}
