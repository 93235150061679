@import '../../styles/common';

.loyalty-program {
    height: auto;
    width: 100%;
    min-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: fixed;

    .header-component {
        background-color: transparent;
    }

    &__top {
        display: flex;
        position: relative;
        justify-content: center;
    }

    &__faq {
        position: absolute;
        right: 16px;
    }

    &__progress {
        height: 32px;
        width: 80px;
        display: flex;
        justify-content: center;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 10px;
        font-weight: bold;
        font-size: 12px;
        line-height: 32px;
    }

    &__title{
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        margin: 30px 16px 0 16px;
    }

    &__sub-title{
        font-size: 16px;
        text-align: center;
        margin: 10px 16px 10px 16px;
    }

    &__product-container,&__product-container__with-border {
        position: relative;
        width: calc(100% - 32px);
        margin: auto;
        margin-top: 20px;
        border-radius: 8px;
    }
    &__product-container__with-border{
        border: 1px solid;
    }

    &__product-image{
      display: flex;
      width: 100%;
      height: 100%;  
      border-radius: 8px;    
    }

    &__covers-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: auto;
        position: absolute;
        overflow: hidden;
        border-radius: 8px;   
        top: 0;
    }

    &__cover-image {
        height: 50%;
    }

    &__time {
        margin-top: 50px;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
    }

    &__receipts-uploading {
        margin: auto;
        margin-top: 45px;
        margin-bottom: 69px;
        width: calc(100% - 32px);
        display: flex;
        position: relative;
        background: $color_white;
        border-radius: 16px;
        padding-top: 43px;
        padding-bottom: 10px;
        justify-content: center;
    }

    &__receipts-uploading-hidden {
        margin: auto;
        margin-top: 45px;
        margin-bottom: 69px;
        width: calc(100% - 32px);
        background: transparent;
        position: relative;
        display: flex;
        justify-content: center;
    }

    &__upload-button {
        display: flex;
        position: absolute;
        top: -46px;
        margin: auto;
        margin-top: 20px;
        width: 200px;
        height: 46px;
        border-radius: 16px;
        justify-content: center;
        align-items: center;
    }

    &__plus-icon{
        margin-right: 10px;
    }

    &__attachments-container {
        margin: 0 20px 0 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__attachment {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $color-gray9;

        &__left {
            display: flex;
            flex-direction: column;
        }

        &__right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    &__attachment-name {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
    }

    &__attachment-time {
        font-size: 10px;
        line-height: 12px;
        color: $color-gray5;
        text-transform: lowercase;
        margin-top: 5px;
    }

    &__attachment-status-accepted,
    &__attachment-status-failed,
    &__attachment-status-on-moderation {
        margin-right: 5px;
        font-size: 10px;
    }

    &__attachment-status-accepted {
        color: $color-green5;
    }

    &__attachment-status-failed {
        color: $color-red1;
    }

    &__attachment-status-on-moderation {
        color: $color-orange;
    }

    &__attachment-icon {
        width: 16px;
        height: 16px;
    }

    &__final-button {
        bottom: 30px;
        width: calc(100% - 32px);
        position: absolute;
        margin: auto;
        height: 60px;
        font-size: 17px;
        font-weight: bold;
        line-height: 20px;
        justify-content: center;
        background: $color_white;
        border-radius: 8px;
        margin-left: 16px;
        margin-right: 16px;
    }
}

