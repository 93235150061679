@import "../../styles/common";

$faded_background: #5c5c5c;

.get-passport-photo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: $faded_background;

  &__video-player {
    height: 100%;
  }

  &__spinner {
    width: 100%;
    height: 200px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    .lds-ring {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__photo-button {
    margin: auto;
    margin-bottom: 30px;
    margin-top: 0px;
    width: calc(100% - 32px);
    height: 60px;
    background: $color-green1;
    color: $color-white;
    border: none;
    border-radius: 8px;
  }

  &__upload-button {
    margin: auto;
    margin-bottom: 30px;
    margin-top:40px;
    width: calc(100% - 32px);
    height: 60px;
    background: transparent;
    color: $color-white;
    background: $color-green1;
    border: none;
    border-radius: 8px;
    font-size: 17px;
    font-weight: 700;
  }

  &__block {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 200;

  }

  &__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;

    &__top {

      width: 100%;

    }

    &__close-icon-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 40px;
      opacity: 1;
    }

    &__close-icon {
      fill: $color-white;

      width: 15px;
      height: 15px;
      margin-right: 32px;
    }

    &__info-block {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      opacity: 1;
    }

    &__info-block-title {
      margin-top: 20px;
      margin-bottom: 5px;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: $color-white;
    }

    &__info-block-page,
    &__info-block-page-passport,
    &__info-block-page-registration {
      display: flex;
      margin: auto;
      margin-top: 5px;
      margin-bottom: 10px;
      background: #0080ff;
      border-radius: 3px;
      padding-left: 15px;
      padding-right: 15px;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;
      text-align: center;
      color: $color-white;
    }

    &__info-block-page-passport {
      background: #ffac30;
    }

    &__info-block-page-registration {
      background: #8a4b9e;
    }

    &__info-block-text {
      display: flex;
      margin: auto;
      font-size: 16px;
      line-height: 19px;
      color: $color-white;
      margin-bottom: 10px;
    }

    &__mid {
      width: 100%;
      display: flex;

    }

    &__left {
      height: 100%;
      width: 16px;
      background-color: $faded_background;
    }

    &__frame {
      width: calc(100% - 32px);
      margin: auto;
      height: 100%;
      min-height: 243px;
      border: 1px solid $color-green1;
      box-sizing: border-box;
      border-radius: 3px;
    }
    &__right {
      height: 100%;
      width: 16px;
      background-color: $faded_background;
    }

    &__bottom {
      padding-top: 10px;
      display: flex;
      height: auto;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      background-color: $faded_background;
    }
  }
}

@media only screen and (max-height: 600px) {
  .get-passport-photo {
    &__mask {
      &__frame {
        min-height: 204px;
      }
    }
  }
}
