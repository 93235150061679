@import '../../styles/common';

$background_grey_color: $color-gray1;
$text_black_color: $color-black;

.boosters-page {
  &__success {
    position: absolute;
    z-index: 100;
    background: $color-white;
    width: 100%;

    &-hide {
      display: none;
    }
  }

  &__header {
    &-text {
      font-size: 18px;
      line-height: 21px;
      font-weight: bold;
      text-align: center;
      color: $color-white;
      margin: 0;
    }
  }

  &__balance {
    width: calc(100% - 32px);
    display: flex;
    z-index: 2;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    color: $text_black_color;
    height: 42px;
    background: $background_grey_color;
    position: sticky;
    top: $header-height;

    p {
      margin: 0;
    }
  }

  &__content-wrapper {
    margin: 30px 16px 0 16px;

    &-header {
      margin-bottom: 30px;
      &-headline {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
      }

      &-description {
        font-size: 14px;
        line-height: 120%;
        text-align: center;
      }
    }

    &-exchange {
      display: flex;
      justify-content: space-between;
      border: 1px solid #01c1b6;
      border-radius: 16px;
      align-items: center;
      margin-bottom: 30px;

      &-text {
        font-weight: bold;
        font-size: 14px;
        line-height: 120%;
        margin: 17px 0 16px 17px;
      }

      &-icon {
        margin-right: 27px;
      }
    }

    &-boosters {
      &-card {
        position: relative;
        height: 150px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 16px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;

        &-name {
          margin: 20px 0 5px 17px;
          font-weight: bold;
          font-size: 18px;
          line-height: 120%;
        }

        &-description {
          margin-left: 17px;
          width: 60%;
        }

        &-price {
          width: 50%;
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 17px;
          margin-bottom: 9px;
          border: 1px solid $color-black;
          box-sizing: border-box;
          border-radius: 8px;
          background-color: $color-white;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          margin-top: auto;
        }

        &-amount {
          position: absolute;
          right: -10px;
          top: -15px;
          border: 1px solid;
          border-radius: 16px;
          width: 30px;
          background: white;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          font-weight: bold;
          font-size: 12px;
          line-height: 120%;
        }
      }
    }
  }
}
