@import '../../styles/common';

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: url('../../images/background-images/bg-image-6.png');
  background-size: cover;
  padding: 0 20px;

  .title {
    color: #FFF;
    font-size: 32px;
    font-weight: 600;
  }

  .description {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
  }

  .checkboxBlock {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: 600;
    gap: 50px;

    .point {
      display: flex;
      align-items: center;
      gap: 10px;

      .radioInput + label:before {
        background: rgba(0, 0, 0, 0.1);
      }
      .radioInput:checked + label:after {
        background: $color-white;
      }

      .pointLabel {
        max-width: 250px;
        color: $color-white;
        margin: 0 7px 0 0;
        line-height: 30px;
      }
    }
  }
}

.productDisclaimer {
  background: #545F71;
}
