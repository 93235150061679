@import '../../../styles/common';

.achievements-summary {
  display: flex;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 16px;
  justify-content: space-around;
  background-color: $color-white;

  &-info {
    margin: 15px 0 15px 0;

    &-caption {
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      opacity: 0.5;
      margin-top: 0;
      margin-bottom: 5px;
    }

    &-amount {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
