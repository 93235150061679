.scroll-block {
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  // padding-right: 100px; //Hide scroll bar ios
}
// Hide scroll bar google chrome
// ::-webkit-scrollbar {
//   display: none;
// }