@import '../../../../styles/common';

.chatHeader {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  padding: 10px 10px 18px;
  height: $header-height;
  background-color: $color-black;
  box-sizing: border-box;

  .titleBlock {
    display: flex;
    align-items: center;
    justify-content: center;

    .backButton {
      position: absolute;
      left: 15px;
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: $color-white;
    }
  }

  .buttonContainer {
    position: relative;

    .newMessageBadge {
      position: absolute;
      right: 0;
      top: 0;
    }

    &:first-child {
      margin-right: 10px;
    }
  }
}
