@import 'src/styles/common';

.createTicket {
  display: flex;
  align-items: center;
  height: 80px;
  box-sizing: border-box;
  padding: 10px 15px;
  background: url('../../../../../images/create-ticket.png') center / cover;
  color: $color-white;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
