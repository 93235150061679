@import "../../../styles/common";

.passport-settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: all 0.5s linear;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: $color-white;

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-component {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: 10px;

      .header-arrow {
        height: 21px;
        fill: $color-white;
      }
    }
  }

  &__form {
    height: calc(100% - 68px);
    width: calc(100% - 32px);
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: space-between;
  }
  &__title {
    margin: 60px 0 5px 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
  }

  &__input-container {
    margin-top: 25px;
    height: 45px;
  }

  &__input-title {
    font-size: 10px;
    line-height: 12px;
  }

  &__input-form {
    margin-top: 5px;
  }

  &__input {
    margin-top: 5px;
    border: none;
    background: transparent;
    color: $color-white;
    width: 100%;
    height: 24px;
    padding-bottom: 5px;
    font-size: 20px;
    line-height: 23px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    padding-left: 0;
  }

  ::placeholder {
    color: $color-white;
    opacity: 0.8;
  }

  &__info-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 15px;
  }

  &__info-description {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 30px;
  }

  &__bottom {
    margin-top: 30px;
  }

  &__button {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
    height: 60px;
    background: $color-white;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 17px;
    color: $color-black;
    font-weight: 700;
  }

  &__button:disabled {
    opacity: 0.6;
  }
  &__camera-icon {
    margin-right: 5px;
    margin-bottom: 2px;
  }
}
