@import '../../../styles/common';

$border_color: $color-aquamarin;
$background_white_color: $color-white;
$text_white_color: $color-white;

.transfer-tutorial-sixth-step {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 600;

  &__faq-block {

    &-icon {
      position: absolute;
      top: 133px;
      right: 11px;
    }
    
    .arrow {
      position: absolute;
      top: 125px;
      transform: rotate(90deg);
    }
  }

  &__content {
    width: 100%;
    position: absolute;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__description {
    width: calc(100% - 64px);
    padding: 0 16px;
    background: $background_white_color;
    margin-bottom: 30px;
    border: 1px solid $border_color;
    box-sizing: border-box;
    border-radius: 16px;

    &-step-number {
      margin: 29px 0 0 0;
      font-size: 10px;
      line-height: 11px;
      text-align: center;
    }
    &-title {
      margin: 10px 0 0 0;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }
    &-text {
      margin: 10px 0 50px 0;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
    }
  }
  &__btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.finish-button {
  height: 50px;
  width: calc(100% - 96px);
  font-weight: bold;
  border-radius: 8px;
  background: $background_white_color;
  font-size: 14px;
  line-height: 17px;
}
