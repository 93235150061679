@import 'src/styles/common';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;

  .backButton {
    background: transparent;
    margin: 20px 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 16px;

    .textBlock {
      display: flex;
      flex-direction: column;
      flex: 1;

      .title {
        margin: 40px 0 10px;
        font-weight: bold;
        font-size: 24px;
      }

      .description {
        font-weight: 400;
      }
    }


    .buttonWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 65px;

      .secured {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 15px;
        border-radius: 16px;
        border: 1px solid $color-green5;
        margin-bottom: 30px;

        .text {
          padding: 0;
          margin: 0;
          font-size: 12px;
        }

        .securedIcon {
          width: 14px;
          height: 16px;
          margin-right: 5px;
        }
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 100%;
        height: 60px;
        color: $color-white;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 5px;

        .spinner {
          position: relative;
        }
      }

      .clip {
        top: 17px;
        left: 99px;
        margin-right: 10px;
      }
    }
  }


  .errorMessage {
    color: $color-red1;
    font-size: 10px;
    line-height: 100%;
  }
}