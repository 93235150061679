@import '../../styles/common';

.achievements-page {
  &__header-text {
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    text-align: center;
    color: $color-white;
    margin: 0;
  }

  &__top-block {
    margin: 30px 16px 30px 16px;
    &-headline {
      font-weight: bold;
      font-size: 18px;
      line-height: 120%;
      text-align: center;
      margin-bottom: 10px;
    }

    &-box {
      margin: 24px 10px;
      padding: 6px;
    }
    &-description {
      font-size: 14px;
      line-height: 120%;
      text-align: center;
      margin-bottom: 20px;
    }

    &-summary {
      margin: 30px 16px;
    }
  }

  &__bottom-block {
    margin: 0 11px 31px 11px;
    &-list {
      width: 100%;
      &-row {
        display: flex;
        flex-direction: row;
      }
      &-column {
        display: flex;
        flex-direction: column;
        width: 50%;
      }

      &-item {
        margin: 5px;
        font-size: 20px;
        background-color: $color-gray5;
        border-radius: 16px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;

        &-header {
          margin: 10px;

          &-timer {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 10px;
            line-height: 100%;
            text-align: right;
          }

          &-name {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-weight: bold;
            font-size: 16px;
            line-height: 130%;
            text-align: left;
            overflow: hidden;
          }
        }

        &-status {
          display: flex;
          width: 60%;
          height: 30px;
          margin-bottom: 20px;
          align-items: center;
          align-self: center;
          justify-content: center;

          box-sizing: border-box;
          border-radius: 8px;

          &-text {
            margin: 5px 10px;
            font-size: 10px;
            line-height: 100%;
            font-weight: bold;
            text-align: center;
            color: $color-white;
          }
        }

        &-level {
          margin: 10px;
          font-size: 48px;
          line-height: 100%;
          text-align: left;
        }
      }
    }
  }
}
