@import './../../styles/colors';

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .taskCounter {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .progressBar {
    width: 100%;
    height: 3px;

    .background,
    .progress {
      border-radius: 5px;
      position: absolute;
      height: 5px;
      bottom: 0;
      left: 0;
    }
    .background {
      background: $color-gray3;
      width: 100%;
    }

    .progress {
      background: $color-green5;
    }
  }
}
