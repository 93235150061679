@import '../../../styles/common';

.btn-component {
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 0;
  -moz-appearance: none;
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}
