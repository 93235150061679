@import '../../styles/common';

$background_color: $color-black;

.transfer-tutorial {
  position: absolute;
  height: 100%;
  width: 100%;
  background: $background_color;
  z-index: 600;
  opacity: 0.5;
}
