@import '../../../styles/common';

.single-mode {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../../../images/background-images/bg-image-2.png');
  color: $color-white;

  &__rules {
    height: 100%;
    background: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &-header-text {
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      color: $color-white;
    }

    &-gamepad {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 300px;
      width: 300px;
    }

    @media screen and (max-height: 670px) {
      &-gamepad {
        height: 169px;
        width: 169px;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: $color-black;
      margin-bottom: 10px;
    }

    &-info {
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: $color-black;
      margin: 0 16px 0 16px;
    }

    &-button {
      width: calc(100% - 32px);
      height: 60px;
      border-radius: 8px;
      color: $color-white;
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 30px;
    }
  }

  &__game {
    margin: 0 16px 0 16px;

    &-leave-game {
      display: flex;
      margin: auto;
      padding-top: 30px;
      font-size: 12px;
      align-items: center;
      text-align: center;
      justify-content: center;
      cursor: pointer;
    }

    &-leave-game-icon {
      width: 9px;
      height: 9px;
      margin-right: 9px;
    }
  }

  &__stage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-status {
      margin-top: 60px;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
    }

    &-status-icons {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }

    &-status-icon-success {
      width: 10px;
      height: 8px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url('./checked-icon.png');
    }

    &-status-icon-failure {
      fill: $color-red1;
      stroke: $color-red1;
      width: 10px;
      height: 10px;
    }

    &-icon-devider {
      width: 110px;
      height: 1px;
      background: $color-white;
    }

    &-success-icon {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-white;
      border-radius: 50%;
    }

    &-failure-icon {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-white;
      border: 1px solid $color-red1;
      border-radius: 50%;
      box-sizing: border-box;
    }

    &-unstarted-icon {
      width: 20px;
      height: 20px;
      justify-content: center;
      align-content: center;
      border: 2px $color-white solid;
      border-radius: 50%;
    }

    &-credits {
      margin-top: 30px;
      font-weight: bold;
      font-size: 36px;
      line-height: 43px;
      text-align: center;
    }

    &-extra-credits {
      margin-top: 10px;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
    }

    &-info {
      margin: 0 16px 40px 16px;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
    }

    &-status-buttons {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }

    &-button-colored {
      width: calc(100% - 32px);
      height: 60px;
      margin: auto;
      background: $color-white;
      border-radius: 8px;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
    }

    &-button-transparent {
      width: calc(100% - 32px);
      height: 60px;
      margin: auto;
      margin-bottom: 10px;
      background: transparent;
      border-radius: 8px;
      border: 1px solid $color-white;
      color: $color-white;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
    }
  }

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-component {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: 10px;

      .header-arrow {
        height: 21px;
        width: 13px;
        fill: $color-white;
      }
    }
  }
}
