@import '../../styles/common';

.disclaimer-footer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15px;
  color: $color-black;
  background: $color-white;
  line-height: 15px;
  text-align: center;
  font-size: 7px;
  letter-spacing: 0.16em;
  z-index: 500;

  &.taiwan {
    font-size: 9px;
  }
}
.disclaimer-footer-iphoneX {
  width: 100%;
  position: fixed;
  height: 34px;
  bottom: 0;
  background: $color-white;
  z-index: 500;
}

.disclaimer-footer-empty {
  position: fixed;
  height: 15px;
  width: 100%;
  background: $color-white;
  z-index: 500;
}

.disclaimer-footer-georgia {
  @extend .disclaimer-footer;
  letter-spacing: 0em;
}

.disclaimer-footer-armenia {
  @extend .disclaimer-footer;
  font-size: 6px;
  letter-spacing: 0em;
}

@media screen and (max-width: 360px) {
  .disclaimer-footer {
    height: 30px;
  }
}
