@import "../../styles/common";

.scenario-page {
  height: 100%;
  width: 100%;
  background-image: url("./scenario_page_bg.png");
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  align-items: center;

  &__top {
    position: absolute;
    width: 100%;
  }

  &__step {
    position: absolute;
    background: transparent;
    width: 100%;
    display: flex;
    height: 220px;
    margin-top: 40px;
    overflow: revert;
    flex-direction: column-reverse;
    align-items: center;
  }

  &__step-container {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__scenario-chat-container {
    position: relative;
    height: 212px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__step-line-container {
    height: 1px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  &__step-line {
    height: 1px;
    width: 100%;
    background: $color-gray1;
  }

  &__step-text {
    position: absolute;
    top: -11px;
    padding-left: 15px;
    padding-right: 15px;
    height: 22px;
    line-height: 22px;
    margin: auto;
    background: $color-gray1;
    border-radius: 8px;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: $color-gray8;
  }

  &__customer-messages {
    position: relative;
    height: 200px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    z-index: 20;
  }

  &__customer-message-container {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    width: calc(100% - 32px);
  }

  &__seller-message-container {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: calc(100% - 32px);
  }

  &__customer-messages-title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
  }

  &__seller-messages-title {
    margin-top: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    text-align: right;
  }

  &__customer-message {
    min-height: 16px;
    background: $color-blue;
    border-radius: 3px 16px 16px 16px;
    font-size: 14px;
    color: $color-white;
    display: flex;
    padding: 15px;
    margin: 10px 0 0 0;
  }

  &__seller-message {
    min-height: 16px;
    background: $color-gray1;
    border-radius: 16px 3px 16px 16px;
    font-size: 14px;
    display: flex;
    padding: 15px;
    margin: 10px 0 0 0;
  }

  &__reaction-container {
    width: calc(100% - 32px);
    margin-top: 298px;
    height: calc(100% - 418px);
    position: absolute;
    display: flex;
    justify-content: center;
  }

  &__reaction {
    height: 100%;
    max-width: 100%;
    display: flex;
    position: absolute;
    margin: auto;
  }

  &__answer-button-container {
    width: 100%;
    position: fixed;
  }

  &__answer-button {
    width: calc(100% - 32px);
    margin: auto;
    height: 60px;
    border: none;
    background: $color-blue;
    border-radius: 8px;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
  }

  &__answer-screen {
    width: 100%;
    min-height: 100%;
    height: auto;
    background-image: url("./scenario_answer_bg.png");
    background-size: cover;
    background-color: $color-blue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    overscroll-behavior-x: none;

    z-index: 30;

    &__customer-messages {
      width: 100%;
      margin: 76px 0 20px 16px;
    }

    &__customer-message-container {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 32px);
    }

    &__customer-messages-title {
      font-style: normal;
      color: $color-white;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      width: 100%;
    }

    &__customer-message {
      min-height: 16px;
      border: 1px solid $color-white;
      box-sizing: border-box;
      border-radius: 3px 16px 16px 16px;
      font-size: 14px;
      color: $color-white;
      display: flex;
      min-width: 70px;
      padding: 15px;
      margin: 10px 0 0 0;
    }

    &__answers-title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: $color-white;
      margin-bottom: 20px;
    }

    &__answer {
      width: calc(100% - 62px);
      height: 98px;
      background-color: $color-white;
      font-size: 14px;
      line-height: 120%;
      display: flex;
      align-items: center;
      border-radius: 16px;
      margin: auto;
      margin-bottom: 10px;
      padding: 15px;

      &__correct {
        width: calc(100% - 62px);
        height: 98px;
        background-color: $color-green5;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        align-items: center;
        border-radius: 16px;
        margin: auto;
        margin-bottom: 10px;
        padding: 15px;
        color: $color-white;
      }

      &__wrong {
        width: calc(100% - 62px);
        height: 98px;
        background-color: $color-red1;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        align-items: center;
        border-radius: 16px;
        margin: auto;
        margin-bottom: 10px;
        padding: 15px;
        color: $color-white;
      }
    }

    &__final {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $color-white;
      align-items: center;
      align-content: center;
    }

    &__reaction {
      width: 235px;
      height: 235px;
      margin: auto;
      margin-top: 30px;
      background-image: url("./final_screen_success.png");
      background-size: cover;
    }

    &__coins {
      margin-top: 10px;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
    }
    &__title {
      margin: auto;
      width: calc(100% - 32px);
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }

    &__text {
      margin: auto;
      width: calc(100% - 32px);
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      margin-bottom: 30px;
    }
    &__button {
      display: flex;
      margin: auto;
      margin-bottom: 30px;
      width: calc(100% - 32px);
      height: 60px;
      background: $color-white;
      border: none;
      border-radius: 8px;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      justify-content: center;
      align-items: center;
      color: $color-black;
    }
  }

  &__answer-screen-body {
    height: 100%;
    width: 100%;
    z-index: 500;
  }
}

.scenario-reaction {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    width: 32px;
    height: 32px;
    border: 1px solid $color-white;
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__plus {
      fill: $color-white;
      height: 13px;
      width: 13px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    margin-top: 20px;
    color: $color-white;
  }

  &__description {
    font-size: 14px;
    line-height: 110%;
    text-align: center;
    color: $color-white;
    margin-top: 10px;
  }
}
