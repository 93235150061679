@import "../../../styles/common";

$background_white_color: $color-white;

.check-in-status {

	&__component {
		margin-top: 23px;
		display: flex;
		flex-flow: column;

		&-text {
			position: relative;
			margin: 0 16px;
			text-align: center;
			line-height: 125%;
			font-weight: bold;
		}
		
		&-question-container {
			width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $color-white;
			border-radius: 50%;
			border: 1px solid $color-green1;
			position: absolute;
			right: 0;
			top: 0;
			
			&-icon {
				width: 6px;
				height: 10px;
			}
		}

		&-time-left {
			display: flex;
			justify-content: center;
			margin-top: 10px;
			
			&-time {
				font-size: 12px;
				line-height: 14px;
				text-align: center;
				width: 45px;
				
				&-value {
					font-size: 18px;
					line-height: 21px;
				}
				
				&-colon {
					width: 15px;
					font-size: 18px;
					line-height: 21px;
					text-align: center;
				}
			}
		}
	
		&-description {
			line-height: 125%;
			text-align: center;
			margin: 20px 16px 0 16px;
		}

		&-steps-slider {
			margin-top: 46px;
			padding-top: 10px;
			display: flex;
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;

			&-step-container {
				margin-right: 10px;

				&-step {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					min-width: 107px;
					height: 110px;
					box-shadow: 0px 2px 3px $color-shadow3, 0px 5px 20px $color-shadow4;
					border-radius: 8px;

					&-image {
						width: 40px;
						height: 40px;
						opacity: 0.2;
					}

					&-text {
						line-height: 100%;
						text-align: center;
						opacity: 0.2;
						margin-top: 12px;
						font-weight: bold;
					}

					&-completed {
						position: relative;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						min-width: 107px;
						height: 110px;
						border: 1px solid $color-green1;
						box-shadow: 0px 2px 3px $color-shadow3, 0px 5px 20px $color-shadow4;
						border-radius: 8px;

						&-check-icon {
							position: absolute;
							right: -5px;
							top: -5px;
						}
						
						&-image {
							width: 40px;
							height: 40px;
						}
						
						&-text {
							line-height: 100%;
							text-align: center;
							margin-top: 12px;
							font-weight: bold;
						}
					}
				}

				&-day {
					margin-top: 5px;
					line-height: 125%;
					display: flex;
					justify-content: center;
				}
			}
			&-step-container:first-child {
					padding-left: 16px;
			}
			&-step-container:last-child {
					padding-right: 16px;
			}
		}
		&-steps-slider::-webkit-scrollbar {
			display: none;
		}
		
		&-btn-container {
			position: absolute;
			width: calc(100% - 32px);
			bottom: 80px;
			left: calc(100% + 16px);

			&-button {
				height: 60px;
				width: 100%;
				border-radius: 8px;
				background: $color-green1;
				color: $color-white;
				font-size: 17px;
				font-weight: bold;
				line-height: 20px;

				&-disabled {
					height: 60px;
					width: 100%;
					border-radius: 8px;
					background: $color-green1;
					color: $color-white;
					font-size: 17px;
					font-weight: bold;
					line-height: 20px;
					opacity: 0.4;
				}
			}
		}
	}
}
