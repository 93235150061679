@import 'src/styles/common';

.primary {
  display: block;
  padding: 10px 15px;
  background-color: $color-aquamarin;
  color: $color-white;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
