.lottieWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & path {
    stroke: white;
    fill: white;
  }
}