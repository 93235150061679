@import '../../../../styles/common';

.chooseCardNumberPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .backButton {
    align-self: flex-start;
    margin: 20px 0 40px 16px;
    & svg {
      fill: $color-black;
    }
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px;

    .textContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      .title {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
      }

      .description {
        font-size: 16px;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;

      .radioButton {
        border: 1px solid $color-aquamarin;

        & > span {
          border-color: transparent;
          &:after {
            background: $color-aquamarin;
          }
        }
      }

      .button {
        align-self: center;
        height: 60px;
        width: 90%;
        border-radius: 8px;
        background: $color-green1;
        font-size: 17px;
        color: $color-white;
        font-weight: bold;
        margin-bottom: 15px;
      }
    }
  }
}
