@import '../../../styles/common';

$background_white_color: $color-white;

.loading-page {
  display: flex;
  flex-direction: column;
  background-color: $background_white_color;
  width: 100%;
  height: 100%;

  &__content-area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
}
