@import '../../../../styles/common';

.interactiveArticleResultPage {
  height: 100%;
  display: flex;
  flex-direction: column;

  .interactiveArticleResultPageContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 100px 16px 0 16px;

      .title {
        font-size: 28px;
        font-weight: bold;
      }

      .description {
        margin-top: 20px;
        margin-bottom: 50px;
        text-align: start;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
      }
    }
    .btnContainer {
      height: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 80px;
      justify-content: center;

      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $color-black 252.33%);

      .nextButton {
        width: 90%;
        height: 60px;
        background-color: $color-white;
        border: none;
        border-radius: 8px;
        font-size: 17px;
        font-weight: bold;
      }
    }
  }
}
