@import '../../../styles/common';

$text_white_color: $color-white;
$button_done_background_color: $color-aquamarin;
$button_clear_background_color: $color-white;
$clear_button_border_color: $color-red1;
$clear_button_text_color: $color-red1;

.clear-button {
  height: 60px;
  width: calc(100% - 32px);
  border: 1px solid $clear_button_border_color;
  border-radius: 8px;
  margin-bottom: 10px;
  background: $button_clear_background_color;
  font-size: 17px;
  color: $clear_button_text_color;
}

.done-button {
  height: 60px;
  width: calc(100% - 32px);
  border-radius: 8px;
  margin-bottom: 30px;
  background: $button_done_background_color;
  font-size: 17px;
  color: $text_white_color;
}

.done-button-disabled {
  opacity: 0.5;
  height: 60px;
  width: calc(100% - 32px);
  border-radius: 8px;
  margin-bottom: 30px;
  background: $button_done_background_color;
  font-size: 17px;
  color: $text_white_color;
}

.buttons-wrapper {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  bottom: 0;
  margin-bottom: 40px;

  .clear-button-belarus {
    border: 2px solid $color-red1;
    border-radius: 8px ;
    background: none;
    font-weight: bold;
    font-size: 14px;
    position: relative;
    padding: 10px 15px 10px 32px;

    &:before {
      content: url("../../../images/trash.png");
      width: 18px;
      height: 18px;
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }

  .next-arrow-button {
      background: transparent;

    &:disabled {
      opacity: 0.2;
    }
  }
}
