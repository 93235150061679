@import '../../../styles/common';
.notification-badge-component{
  position: absolute;
  text-align: center;
  font-size: 9px;
  line-height: 18px;
  height: 18px;
  color: $color-white;
  border-radius: 9px;
  top: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
}