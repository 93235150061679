@import "src/styles/common";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .textWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 97%;

    .title {
      margin-top: 106px;
      font-weight: bold;
      font-size: 20px;
      line-height: 120%;
    }

    .description {
      margin-top: 20px;
      font-size: 16px;
      line-height: 140%;
    }
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 95%;

    .remindLaterButton {
      background: $color-white;
      border: 1px solid $color-aquamarin;
      border-radius: 8px;
      height: 60px;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      width: 100%;
      color: $color-aquamarin;
      margin: 0 10px;
    }

    .continueButton {
      margin: 10px 0 30px 0;
      height: 60px;
      font-weight: bold;
      font-size: 17px;
      width: 100%;
      line-height: 20px;
      border-radius: 8px;
      color: $color-white;
    }
  }
}