@import '../../styles/common';

$text_white_color: $color-white;
$link_color: $color-aquamarin;

.prompt-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__description {
    margin-top: 24px;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }
  &__link {
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: $link_color;
  }
  &__btn {
    margin: 30px 0 20px;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    font-size: 17px;
    color: $text_white_color;

    &:disabled {
      opacity: 0.3;
    }
  }
}

@media only screen and (min-width: 600px) {
  .ReactModal__Content {
    width: 343px;
  }
}
