@import '../../styles/common';

.main-page {
  overflow: hidden;

  &__blocks {
    padding-bottom: $footer-height;
  }

  &__items-block {
    width: calc(100% - 32px);
    margin: auto auto 30px;
    position: relative;
    display: flex;
    justify-content: flex-start;
  }

  &__items-headline {
    width: calc(100% - 32px);
    margin: auto auto 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
  }
  &__modules-block {
    width: calc(100% - 32px);
    margin: auto auto 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__focus-block {
    margin: auto auto 35px;
  }
}
