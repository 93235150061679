@import '../../styles/common';

.select-game-mode {
  &__devider {
    height: 1px;
    width: 100%;
    background: $color-gray1;
    margin-bottom: 15px;
  }

  &__mode-buttons-container {
    width: calc(100% - 32px);
    height: 40px;
    display: flex;
    margin-bottom: 15px;
    border: 1px solid $color-green1;
    box-sizing: border-box;
    border-radius: 8px;
    margin: auto;
  }

  &__mode-button {
    width: 50%;
    height: 100%;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    border: none;
    background: $color_white;
    color: $color_green1;
  }

  &__mode-button-selected {
    width: 50%;
    height: 100%;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    border: none;
    background: $color_green1;
    color: $color-white;
  }

  &__mode-button:first-child,
  &__mode-button-selected:first-child {
    border-radius: 8px 0 0 8px;
  }
  &__mode-button:last-child,
  &__mode-button-selected:last-child {
    border-radius: 0 8px 8px 0;
  }
}
