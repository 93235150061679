@import '../../styles/common';

.sales-simulator {
  &__body {
    padding-bottom: $footer-height;
  }

  &__sales-simulator-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__sales-simulator-card-chain {
    height: 30px;
    width: 1px;
    background: $color-green1;
  }

  &__sales-simulator-card-completed,
  &__sales-simulator-card-incompleted {
    width: 240px;
    height: 125px;
    border-radius: 16px;
  }

  &__sales-simulator-card-completed {
    border: 1px solid $color-gray10;
  }

  &__tip-steps {
    display: flex;
    flex-direction: column;
  }

  &__tip-step-row {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-unavailable {
      height: 100px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      opacity: 0.2;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: $color-gray1;
  }

  &__tip-step-row-text {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 16px;
  }

  &__tip-step-row-header {
    color: $color-green1;
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
  }

  &__tip-step-row-info {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  &__done-step-icon-container {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__done-step-icon {
    border: 1px solid $color-green5;
    border-radius: 16px;
    padding: 8px;
    height: 16px;
    width: 16px;
    fill: $color-green5;
  }
}
