@import 'src/styles/common';

.code-input__container {
  &__error-message {
    text-align: center;
    margin-top: 10px;
    display: block;
    font-size: 10px;
    color: $color-red1
  }
}

.code-input_input {
  width: 20px;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  border: none;
  border-radius: 0;
  font-size: 34px;
  line-height: 40px;
  background: transparent;
  border-bottom: 2px solid;

  &:nth-child(-n+3) {
    margin-right: 10px;
  }
}

.code-input_input::-webkit-inner-spin-button,
.code-input_input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.code-input_input :focus {
  outline: none;
}
