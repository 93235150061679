@import '../../styles/common';

$menu_color: $color-white;
$line_color: $color-black;

.navigation-menu {
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: $menu_color;
  height: 52px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &__link {
    width: 100%;
    margin-right: 10px;
  }

  &__link:nth-last-of-type(1) {
    margin-right: 0;
  }

  &__buttons {
    display: flex;
    height: 70px;
    margin-bottom: 5px;
    align-items: center;
    width: calc(100% - 32px);
    justify-content: space-between;

    &-unread-messages {
      position: absolute;
      text-align: center;
      font-size: 9px;
      line-height: 18px;
      height: 18px;
      width: 18px;
      border-radius: 9px;
      top: 15px;
      margin-left: 10px;
    }

    &-icon-container {
      display: flex;
      padding: 0;
      border: 0;
      outline: 0;
      background: transparent;
      min-width: 50px;
      max-width: 69px;
      height: 52px;
      align-items: center;
      justify-content: center;
    }
  }
}
