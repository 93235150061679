.focus-block {
  height: auto;
  width: calc(100% - 32px);
  margin: auto auto 10px auto;
  &__focus-photo {
    width: 100%;
    max-height: 210px;
    border-radius: 16px;
  }
}
