@import '../../../styles/common';

.boosters {
    position: fixed;
    bottom: calc(10px + env(safe-area-inset-bottom));
    display: flex;
    margin: auto;
    height: 40px;
    width: calc(100% - 32px);
    border-radius: 47px;
    background: rgba(255, 255, 255, 0.2);
    justify-content: space-evenly;
    align-items: center;
    z-index: 500;
    
    &_disabled{
        @extend .boosters;
        opacity: 0.2;
    }

    &__booster{
        height: 30px;
        width: 30px;
        border-radius: 47px;
        position: relative;
    }

    &__amount {
        position: absolute;
        height: 11px;
        min-width: 11px;
        right: 0;
        border-radius: 50px;
        background: $color_white;
        font-weight: 500;
        font-size: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color_black
    }
}