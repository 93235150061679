@import "src/styles/common";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 53px;
  height: 32px;
  padding: 0 5px;
  margin: 10px auto;

  .icon {
    width: 9px;
    height: 9px;
    fill: $color-white;
    margin-bottom: 2px;
  }

  .text {
    margin-left: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: $color-white;
  }
}