@import '../../../styles/common';

.esign-irl-page {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-flow: column;
  background-image: url('../../../images/background-images/bg-lep-img.jpg');
  background-size: 100% 100%;
}

.esign-page {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-flow: column;
  background: transparent;

  &__top-section {
    margin: 24px 16px 0 16px;
  }

  &__email-info {
    margin-top: 10px;
    font-size: 14px;
    line-height: 16px;
    color: $color-white;
  }
  &__irl-email-info {
    margin-top: 10px;
    font-size: 14px;
    line-height: 16px;
    color: $color-green6;
  }

  &__bot-section {
    display: flex;
    flex-flow: column;

    .verify__btn {
      justify-self: flex-end;
      align-self: flex-end;
      width: 51px;
      height: 51px;
      padding: 0;
      background: $color-white;
      border-radius: 50%;
      margin: 150px 16px 30px 0;
    }
  }

  &__input {
    margin-top: 100px;

    .input-block {
      &-default {
        margin-bottom: 35px;
        opacity: 0.9;
      }

      &__warning-message {
        color: $color-red;
        margin-bottom: 5px;
      }
    }
    ::placeholder {
      color: $color-white;
      opacity: 0.8;
    }
  }

  &__irl-input {
    margin-top: 100px;

    .input-block {
      &__input {
        color: $color-green6;
        border-bottom: 1px solid;
        border-bottom-color: rgba($color-green6, 0.2);
      }
      &__input-invalid {
        color: $color-green6;
      }
      &__warning-message {
        color: $color-red;
      }
    }
    ::placeholder {
      color: $color-green6;
      opacity: 0.3;
    }
  }

  &__title {
    margin-top: 43px;
    font-size: 32px;
    background: transparent;
    color: $color-white;
  }
  &__irl-title {
    margin-top: 43px;
    font-size: 32px;
    background: transparent;
    color: $color-green6;
  }

  &__btn {
    justify-self: flex-end;
    align-self: flex-end;
    width: 51px;
    height: 51px;
    padding: 0;
    background: $color-white;
    border-radius: 50%;
    margin-right: 16px;
    margin-bottom: 40px;
  }

  &__icon {
    width: 100%;
    height: 20px;
    fill: $color-green4;
  }

  &__btn:disabled {
    opacity: 0.3;
  }

  @media only screen and (min-width: 600px) {
    margin: auto;
    max-width: 500px;
  }
}
