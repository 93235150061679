@import '../../../../styles/common';

.relative-container {
  position: relative;
  height: 100%;
}

.oppa-wallet-settings {
  width: 100%;

  &__header {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $color-white;
  }

  &__content {

    &-wallet-container {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      &__field {
        position: relative;
        height: 65px;

        &-title {
          position: relative;
          color: $color-gray6;
          font-size: 10px;
          left: 16px;
        }

        &-card-number {
          position: relative;
          font-size: 20px;
          left: 16px;
          height: 28px;
          width: 90%;
          border-bottom: 1px solid $color-aquamarin;
        }
      }
    }

    &-image-container {
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 130px;
      justify-content: center;
      align-items: center;
      bottom: 30px;

      &-image {
        height: 100%;
        width: calc(100% - 36px);
        border-radius: 30px;
      }

      &-button-container {
        width: 100%;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        flex-direction: column;

        &-title {
          display: flex;
          color: $color-white;
          font-weight: bold;
          font-size: 18px;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        &-btn {
          background-color: $color-white;
          position: relative;
          width: 200px;
          height: 40px;
          border-radius: 8px;
          font-weight: bold;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: $color-black;
        }
      }
    }
  }
}
