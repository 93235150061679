@import '../../../styles/common';

.lep {
  overflow: hidden;
  height: 100%;

  .scroll-block {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.code-input__container {
  padding: 0;
}

.lep-page {
  &__choose-competition {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-white;

    &-header {
      font-weight: bold;
      font-size: 32px;
      color: $color-black;
      margin: 80px 18px 60px 18px;
      white-space: pre-line;
      line-height: 120%;
    }
    &-cards {
      padding: 0px 16px;
    }
    &-card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 8px;
      border-radius: 16px;

      &-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 47px 0px 47px 16px;

        &-text {
          font-weight: bold;
          font-size: 18px;
          line-height: 100%;
          margin: 5px 0px;
        }
      }

      &-button {
        background-color: $color-white;
        padding: 10px 30px;
        border-radius: 8px;
        font-weight: bold;
        font-size: 17px;
        color: $color-black;
        margin: 55px 16px;
      }
    }
  }

  &__confirm-terms {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      margin-top: 50px;
    }

    &-description {
      margin: 20px 16px;
      font-size: 16px;
      text-align: center;
    }

    &-link {
      font-size: 16px;
      text-align: center;
      text-decoration-line: underline;
      color: $color-green1;
    }

    &-withoutButton {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      form {
        width: 100%;
      }
    }

    &-container {
      border-radius: 16px;
      padding: 20px 15px;
      margin: 5px 15px;
      display: flex;
      align-items: center;
      background-color: $color-gray3;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        display: none;
        &:checked ~ .lep-page__confirm-terms-container-checkmark {
          border-color: $color-black;
          &:after {
            display: block;
          }
        }
      }

      &-checkmark {
        height: 25px;
        width: 25px;
        border: 1px solid $color_gray4;
        background-color: $color-white;
        border-radius: 4px;

        &:after {
          content: '';
          display: none;
          position: relative;
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: 1px solid $color-black;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      &-checked {
        @extend .lep-page__confirm-terms-container;
        box-shadow: 0 0 0px 1px $color-green5;
      }

      &-description {
        margin-left: 10px;
        font-size: 16px;
      }
    }

    &-button {
      background: none;
      -webkit-tap-highlight-color: transparent;

      &-box {
        margin: 25px 15px;
        background: none;
        display: flex;
        flex-direction: row-reverse;
      }

      path {
        fill: $color-green1;
      }

      &-disabled {
        @extend .lep-page__confirm-terms-button;
        path {
          fill: $color-green1;
          opacity: 0.2;
        }
      }
    }
  }

  &__back-button {
    position: absolute;
    top: 20px;
    left: 16px;
    background: none;
  }

  &__start-competition {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-gold2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &-links {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 150px;
    }
    &-timeLeft {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-weight: bold;
      font-size: 12px;
      padding: 5px 10px;
      background-color: $color-white;
      border-radius: 16px;
    }
    &-header {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      margin: 10px 10px;
    }
    &-description {
      font-size: 14px;
      text-align: center;
      margin: 0px 10px 30px 0px;
    }
    &-button {
      background-color: $color-white;
      padding: 20px 50px 19px 50px;
      border-radius: 8px;
      font-weight: bold;
      font-size: 17px;
      animation: button-animation 2s ease infinite;
    }
    &-competitionEnd {
      font-size: 12px;
      text-align: center;
      margin: 10px 10px;
    }

    &-links {
      &-rules {
        font-size: 10px;
        text-decoration-line: underline;
        color: $color-black;
      }
      &-help {
        font-weight: bold;
        font-size: 12px;
        margin: 15px 10px;
        color: $color-black;
        border: 1px solid $color-black;
        padding: 10px 20px;
        border-radius: 8px;
      }
    }
  }

  &__code-input {
    border-color: $color-green6;
    width: 30px;
    margin: 0 2.5px;
    text-align: center;
    padding: 0;
  }

  &__enter-code {
    height: 100%;
    background-color: $color-gold;
    display: flex;
    flex-direction: column-reverse;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &-content {
      display: flex;
      flex-direction: column;
      background-color: $color-white;
      border-radius: 16px 16px 0 0;
      height: 435px;
      position: relative;

      &-header {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        margin: 40px 16px 10px 16px;
      }

      &-description {
        padding: 5px 16px;
        font-size: 18px;
        text-align: center;
      }

      &-form {
        display: flex;
        flex-flow: column;
        background: inherit;
        align-items: center;
        flex-grow: 1;
        position: relative;

        &-placeholder {
          position: absolute;
          opacity: 0.3;
          bottom: 63%;
        }

        &-error {
          position: relative;
          color: $color-red1;
          font-size: 12px;
        }
      }
      &-finally {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      &-lottie {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &-button {
      position: relative;
      background-color: $color-green6;
      margin: 15px 16px;
      height: 60px;
      border-radius: 8px;
      color: $color-white;
      font-weight: bold;
      font-size: 16px;
      transition: All 0.5s ease;
      -webkit-tap-highlight-color: transparent;

      &:disabled {
        opacity: 0.2;
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }

  &__signup {
    background-color: $color-gold;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    .input-block__warning-message {
      color: $color-red1;
    }
    &-phone {
      display: flex;
      flex-direction: row;

      &-red {
        & .input-block__input {
          border-bottom: 2px solid $color-red1;
        }
      }
      & .signup-page__input {
        flex-grow: 1;
      }

      &-prefix {
        width: 30px;
      }
    }
  }

  &__result {
    position: absolute;
    background-color: $color-gold;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;

    &-content {
      display: flex;
      flex-direction: column;
      border-radius: 16px 16px 0 0;
      background-color: $color-gray3;
      align-items: stretch;

      &-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        border-radius: 16px;
      }

      &-header {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        padding: 10px 16px;
      }

      &-description {
        padding: 0 16px;
        text-align: center;
        font-size: 14px;
      }

      &-box-icon {
        margin: 20px 20px 10px 20px;
        align-self: flex-end;
      }

      &-info-icon {
        path {
          fill: $color-black;
        }

        circle {
          stroke: $color-black;
        }
      }

      &-timeLeft {
        font-weight: bold;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 16px;
        border: 1px solid $color-gold;
      }

      &-button {
        background-color: $color-green6;
        color: $color-white;
        width: 250px;
        border-radius: 8px;
        font-weight: bold;
        font-size: 17px;
        padding: 20px 50px;
        margin-top: 30px;
        animation: button-animation 2s ease infinite;
      }

      &-competitionEnd {
        font-size: 12px;
        text-align: center;
        margin: 10px 10px 30px;
      }

      &-registration {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px 15px;
        margin: 30px 16px 0 16px;
        border-radius: 16px;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;

        path {
          fill: $color-white;
        }

        &-text {
          font-weight: bold;
          font-size: 20px;
          display: flex;
          align-items: center;
          color: $color-white;
        }
        svg {
          margin: 40px 10px;
        }
      }

      &-lucky-codes {
        padding: 10px 15px;
        margin: 10px 16px 0 16px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 16px;
        font-weight: bold;
        font-size: 16px;
        color: $color-white;
      }

      &-codes {
        &-header {
          margin: 30px 16px 0 16px;
          font-weight: bold;
          font-size: 18px;
        }

        &-card {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: $color-white;
          border-radius: 16px;
          margin: 10px 16px 0 16px;
          padding: 15px;
        }

        & .lep-page__code-win {
          background-color: $color-green7;

          & :nth-child(1) {
            color: $color-white;
          }

          & > :nth-child(2) {
            background-color: $color-white;
          }
        }

        &-status {
          font-weight: bold;
          font-size: 12px;
          padding: 5px 10px;
          border-radius: 16px;
          background-color: $color-gold2;
        }
        &-code {
          font-weight: bold;
          font-size: 22px;
          letter-spacing: 0.1em;

          & :nth-child(2) {
            font-weight: normal;
            font-size: 10px;
          }
        }
      }
    }
    &-game-rules {
      margin: 30px 16px;
      font-size: 10px;
      text-align: center;
      text-decoration-line: underline;
      color: $color-black;
    }
  }
  &__complete-registration {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    &-img {
      flex-grow: 1;
    }

    &-header {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
    }
    &-description {
      text-align: center;
      font-size: 18px;
      margin: 10px 16px 40px 16px;
    }

    &-button {
      background-color: $color-green6;
      margin: 15px 16px;
      height: 60px;
      border-radius: 8px;
      color: $color-white;
      font-weight: bold;
      font-size: 16px;
      transition: All 0.5s ease;
      align-self: stretch;
      -webkit-tap-highlight-color: transparent;
      border: none;

      &:active {
        transform: scale(0.9);
      }
    }
  }
}

@keyframes button-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
