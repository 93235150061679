@import '../../../styles/colors';

.container {
  background-color: $color-black;
  height: 100%;

  .backButton {
    background-color: transparent;
    position: absolute;
    top: 25px;
  }

  .productImage {
    position: absolute;
    top: 30%;
    width: 100%;
  }
}
