@import '../../../styles/common';

$button_background_color: $color-aquamarin;
$background_white_color: $color-white;
$text_white_color: $color-white;
$back_color: $color-dark;

.relative-container {
  height: 100%;
  position: relative;
}
.money-for-the-wallet-page {
  background-color: $background_white_color;
  width: 100%;

  &__header {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $text_white_color;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;

      &-wallet-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 84px;
        width: calc(100% - 32px);
        background: $back_color;
        color: $text_white_color;

        &-caption {
          margin: 5px 0 5px 0;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
        }

        &-wallet {
          margin: 0;
          font-size: 22px;
          line-height: 26px;
          text-align: center;
        }
      }

      &-faq {
        margin-top: -30px;
        margin-left: calc(100% - 10px);
      }
    }

    &-btn-container {
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 1));
      padding: 15px 0;
      display: flex;
      justify-content: center;
      position: fixed;

      &-button {
        height: 60px;
        width: calc(100% - 32px);
        border-radius: 8px;
        background: $button_background_color;
        font-size: 17px;
        color: $text_white_color;
        font-weight: bold;
      }
    }
  }
}
