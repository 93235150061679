@import "../../styles/common";

$background-color: $color-white;
$text_white_color: $color-white;
$text_black_color: $color-black;
$button_background_color: $color-white;

.address-registration-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
 

  &__header-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $text_white_color;
  }

  &__content {
    background-size: cover;
    padding: 0 16px 16px 16px;
    height: 100%;
  }

  &__bottom-section {
    display: flex;
    flex-flow: column;
    margin-bottom: 35px;
    justify-content: flex-end;

    &-button {
      height: 60px;
      width: 100%;
      border-radius: 8px;
      background: $button_background_color;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      color: $text_black_color;

      &-disabled {
        opacity: 0.5;
        height: 60px;
        width: 100%;
        border-radius: 8px;
        background: $button_background_color;
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
        color: $text_black_color;
      }
    }
  }

  &__title {
    margin: 60px 0 50px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $color-white;
  }

  &__input {
    margin-bottom: 35px;

    &-default {
      margin-bottom: 35px;
      opacity: 0.9;
    }
  }

  ::placeholder {
    color: $color-white;
    opacity: 0.8;
  }
}
