@import '../../../../../styles/common';

.enter-user-info-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
}

.enter-user-info-page {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow-x: hidden;

  &__back-button {
    position: relative;
    display: flex;
    background: transparent;
    margin: 20px 0;
    .header-arrow {
      fill: $color_black;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    &-input {
      position: relative;
      margin-bottom: 40px;
      width: 100%;

      &-label {
        position: relative;
        color: $color-gray6;
        font-size: 10px;
        margin: 0;
      }

      .input-block {
        &__input {
          position: relative;
          color: $color-black;
          text-align: start;
          border-bottom: 1px solid $color-aquamarin;

          &-invalid {
            border-bottom: 1px solid $color-red;
            text-align: start;
            color: $color-black;
          }
        }
        &__warning {
          right: 10px;
          top: 5px;
          margin-right: 0;
        }
        &__warning-message {
          color: $color-red;
        }
      }
    }

    &-select-input {
      position: relative;
      width: 100%;
      margin-bottom: 30px;

      .select-block {
        &__select {
          color: $color-black;
          text-align: start;
          border-bottom: 1px solid $color-aquamarin;

          &-invalid {
            border-bottom: 1px solid $color-red;
          }
        }
      }
    }

    &-title-container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 60px;
      left: 16px;

      &-title-bold {
        font-weight: bold;
        font-size: 24px;
        text-align: start;
        color: $color-black;
        margin: 10px 0 0;
        width: 90%;
      }

      &-title {
        font-size: 16px;
        text-align: start;
        color: $color-black;
      }
    }

    &-bottom-button {
      position: relative;
      left: 87%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 25px;
      height: 80px;

      &-next-step {
        width: 51px;
        height: 51px;
        border-radius: 50%;
        background-color: $color-white;
        padding: 0;

        &:disabled {
          opacity: 0.3;
        }
      }
    }
  }

}
