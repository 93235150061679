@import "src/styles/common";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  background-image: url('../../../images/background-images/mystery-shopper-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .backButton {
    background: transparent;
    position: absolute;
    top: 25px;
    left: 10px;
  }

  .title {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $color-white;
  }

  .iconWrapper {
    min-width: 315px;
    min-height: 149px;

    .icon {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: $color-white;
  }
}