@import '../../../styles/common';

a {
  text-decoration: none;
}

.box-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto auto 30px;
  width: calc(100% - 32px);
  height: 88px;
  border-radius: 16px;

  &__picture-block {
    padding-right: 10px;
  }

  &__info {
    padding-left: 15px;
    overflow: hidden;
  }

  &__name {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 60px;
    white-space: nowrap;
  }
  &__description {
    font-size: 10px;
    line-height: 12px;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__expiration-time {
    display: flex;
    align-items: center;
    color: $color-white;
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
    min-width: 75px;
    justify-content: space-between;
    border-radius: 16px;
    padding: 8px 13px;
    margin-right: 15px;
    background-color: $color-black;
    color: $color-white;

    svg {
      width: 10px;
      height: 10px;
      > path {
        stroke: $color-white;
      }
    }
  }
  &__container-arrow-right {
    padding: 20px 15px 20px 10px;
    stroke: $color-white;
  }
}
