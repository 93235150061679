@import '../../styles/common';

.bs-limit {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  &__header {
    height: 68px;
    background: $color-green1;
    width: 100%;
  }

  &__passport {
    margin: auto;
    margin-top: 30px;
    background-image: url('./passports.png');
    background-size: cover;
    width: 254px;
    height: 258px;
  }

  @media screen and (max-height: 700px) {
    &__passport {
      width: 224px;
      height: 228px;
    }
   }

   @media screen and (max-height: 650px) {
    &__passport {
      width: 174px;
      height: 178px;
    }
   }

   @media screen and (max-height: 620px) {
    &__passport {
      width: 124px;
      height: 128px;
    }
   }


  &__title {
    width: calc(100% - 32px);
    margin: auto;
    margin-top: 36px;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    align-items: center;
    text-align: center;
  }

  &__text {
    width: calc(100% - 32px);
    margin: auto;
    margin-top: 10px;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
  }

  &__bot {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__later {
    margin: auto;
    margin-top: 20px;
    width: calc(100% - 32px);
    height: 60px;
    border: 1px solid $color-green1;
    border-radius: 8px;
    background: $color-white;
    color: $color-green1;
    font-size: 17px;
    line-height: 20px;
  }

  &__fill-now {
    margin: auto;
    margin-top: 5px;
    margin-bottom: 30px;
    width: calc(100% - 32px);
    height: 60px;
    border-radius: 8px;
    border: none;
    background: $color-green1;
    color: $color-white;
    font-size: 17px;
    line-height: 20px;
  }
}
