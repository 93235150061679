@import '../../styles/common';

$background_color: $color-white;

.banner-block {
  img {
    width: 100%;
    display: block;
  }

  background: $background_color;
}
