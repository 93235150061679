@import '../../styles/common';

.list_item {
  cursor: pointer;
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.5rem;

  img {
    border-radius: 0.5rem;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
  .title {
    margin-top: 0.25rem;
    min-height: 2rem;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }
  .date {
    margin-top: 0.25rem;
    font-weight: bold;
    font-size: 9px;
    text-align: center;
  }
}
