@import '../../../styles/common';

.page {
  height: 100%;

  .header {
    color: $color-white;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 100px;
  }

  .goToInvitationButton {
    padding: 32px 56px 0px;
    margin: 50px 0;
  }

  .invitationButtonComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
  }

  .logoutDeleteSpacing {
    padding: 0 20px;
  }
}
