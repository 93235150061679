@import "../../../../styles/common";

.container {
  display: flex;
  justify-content: center;
  margin: 0 16px;

  .textArea {
    padding: 15px;
    background: transparent;
    border: 1px solid $color-white;
    border-radius: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: $color-white;
    width: 100%;
    resize: none;
    min-height: 200px;
    margin-top: 20px;

    &::placeholder {
      opacity: 0.2;
      color: $color-white;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
    }
  }
}