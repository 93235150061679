$margin_top: 25px;

.invitationTitle {
  color: #000;
  margin: 0;
  text-align: center;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.invitationSubTitle {
  color: #000;
  margin-top: $margin_top;
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.03);
  font-family: SF Pro Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.qrCodeBlock {
  margin-top: $margin_top;
}

.invitationAvailableCodes {
  margin-top: 15px;
}

.headerCustomClass {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
