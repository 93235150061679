@import '../../../../styles/common';

.choose-amount-page {
  position: relative;
  width: 100%;
  height: 100%;

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;

    &-back-button {
      display: flex;
      background: transparent;
      margin: 20px 0;
      .header-arrow {
        fill: $color_black;
      }
    }

    &-title-container {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 60px;
      width: 350px;
      left: 16px;

      &-title-bold {
        font-weight: bold;
        font-size: 24px;
        text-align: start;
        color: $color-black;
        margin: 10px 0 0;
        width: 90%;
      }

      &-title {
        font-size: 16px;
        text-align: start;
        color: $color-black;
      }
    }

    &-counter {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 90%;
      height: 325px;
      margin: 0 auto 30px;
      background-color: $color-gray11;
      border-radius: 32px;

      &-rate-money {
        font-size: 32px;
        margin: 15px 0 0;
      }

      &-rate {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;

        &-coins {
          font-size: 32px;
          margin: 0;
        }
      }

      &-increase-buttons {
        display: flex;
        flex-direction: row;
        position: relative;
        bottom: 20px;
      }

      &-increase-by {
        display: flex;
        flex-direction: row;

        &-button {
          background-color: transparent;
          border: 1px solid $color-aquamarin;
          width: 82px;
          height: 45px;
          border-radius: 8px;
          font-size: 16px;
          margin: 5px;
        }
      }

      &-image {
        width: 100%;
        height: 370px;
        border-radius: 20px;
      }
    }

    &-button-container {
      width: 100%;
      padding: 15px 0;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 15px;

      &-btn {
        height: 60px;
        width: calc(100% - 32px);
        border-radius: 8px;
        background: $color-aquamarin;
        font-size: 17px;
        color: $color-white;
        font-weight: bold;

        &:disabled {
          opacity: 0.3;
        }
      }
    }
  }
}
