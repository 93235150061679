@import '../../../styles/common';

$border_color: $color-aquamarin;
$background_white_color: $color-white;

.transfer-tutorial-fourth-step {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 600;

  &__transfer-block {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100px;
    background: $background_white_color;
    top: 262px;
    left: 16px;
    border-radius: 16px;
    
    &-caption {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      margin: 0 0 15px 0;
      width: calc(100% - 36px);
    }
    
    &-list-item {
      width: calc(100% - 36px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 44px;
      margin-bottom: 5px;

      &-info {
        display: flex;
        flex-direction: column;
        
        &-amount {
          font-size: 14px;
          line-height: 17px;
          margin: 5px 0;
        }

        &-date {
          font-size: 10px;
          line-height: 12px;
          margin: 0 0 5px 0;
          opacity: 0.2;
        }
      }
      
      &-status {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 17px;

        &-icon {
          margin-left: 5px;
          margin-top: 3px;
        }
      }
    }

    .arrow {
      position: absolute;
      top: 120px;
    }
  }

  &__content {
    width: 100%;
    position: absolute;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  &__description {
    width: calc(100% - 64px);
    padding: 0 16px;
    background: $background_white_color;
    margin-bottom: 30px;
    border: 1px solid $border_color;
    box-sizing: border-box;
    border-radius: 16px;

    &-step-number {
      margin: 29px 0 0 0;
      font-size: 10px;
      line-height: 11px;
      text-align: center;
    }
    &-title {
      margin: 10px 0 0 0;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }
    &-text {
      margin: 10px 0 50px 0;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
    }
  }

  &__arrow-button {
    justify-self: flex-end;
    align-self: flex-end;
    width: 51px;
    height: 51px;
    padding: 0;
    background: transparent;
    border-radius: 50%;
    margin: 0 16px 0 0;
  }
}
