@import '../../../../styles/common';

.oppa__mainPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &-headerMenu {
    color: $color-white;
    font-size: 18px;
    font-weight: bold;
  }

  &-container-button {
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 1));
    padding: 15px 0;
    display: flex;
    justify-content: center;
    position: fixed;
    align-items: stretch;
  }

  &-button {
    margin: 0 16px;
  }

  &-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 120px;
    align-items: stretch;
    position: relative;

    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &-settings {
      position: absolute;
      right: 13px;
      top: 120px;
    }
  }

  &-wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px 90px;

    &-info {
      color: $color-white;
      text-align: center;
      font-size: 22px;
      line-height: 26px;

      @media screen and (max-width: 320px) {
        font-size: 19px;
      }
    }
    &-number {
      font-size: 22px;
      margin-top: 5px;
    }
  }
  &-history {
    border-radius: 16px 16px 0 0;
    background-color: $color-white;
    padding: 30px 36px 110px;
    position: relative;
    height: 100%;
    display: flex;

    & .cashout-history {
      flex-grow: 1;

      & .cashout-history-empty {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
      }
    }

    &-faq {
      position: absolute;
      right: 16px;
      top: 16px;

      & path {
        fill: $color-black;
      }
      & circle {
        stroke: $color-black;
      }
    }

    & .cashout-history {
      padding: 0;
      border-radius: 0;
      margin-top: 0;
      width: auto;
      background: none;
      flex-grow: 1;
    }

    & .cashout-history-empty {
      width: auto;
      position: static;
      padding-bottom: 30px;
    }
  }
}
