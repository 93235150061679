.qrcodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;
  border-radius: 16px;
  background: #f2f2f2;
}

.qrCodeBlock {
  padding: 20px;
  border-radius: 16px;
  background-color: #fff;
}

.copyButton {
  width: 100%;
  margin-top: 20px;
}

.copyButtonComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
}

.copied {
  animation: jumpAnimation 0.5s ease-out;
}

@keyframes jumpAnimation {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
