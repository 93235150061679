@import 'src/styles/common';

.chatMenu {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  padding: 10px 10px 30px ;
  margin-top: 30px;

  .problem {
    display: inline-block;
    text-align: center;

    padding: 20px 10px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;
    min-height: 60px;
    border: 1px solid $color-gray3;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}
