@import "src/styles/common";

.container {
  position: relative;
  padding-top: 80px;
  height: calc(100% - 80px);

  .backButton {
    background: transparent;
    position: absolute;
    top: 25px;
    left: 10px;
  }
}