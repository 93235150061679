@import '../../styles/common';

$bottom-button-color: $color-white;

.quiz-page {
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  &__with-background-image {
    width: 100%;
    height: auto;
    min-height: 100%;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../images/background-images/bg-image-2.png');

    > div {
      width: inherit;
    }
  }

  &__daily-question {
    padding-top: 40px;
    box-sizing: border-box;
    align-items: center;
    height: 100%;
    display: block;

    &-logo {
      margin: 50px auto 0 auto;
      height: 105px;
      max-width: 200px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &-final-points {
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }

    &-game-results {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__event-mode-final-body {
    height: 250px;
    display: flex;
    flex-direction: column;
    margin: auto 16px auto 16px;
    justify-content: space-between;
  }

  &__event-mode-point {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  &__event-mode-rewards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__event-mode-quiz-type {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0 19px 0 19px;
    padding-bottom: 10px;
  }

  &__event-mode-rewards-title {
    font-size: 24px;
    line-height: 29px;
    margin: 140px auto 0 auto;
  }

  &__event-mode-rewards-keys {
    margin-top: 10px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }

  &__event-mode-rewards-container {
    width: 288px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  }

  &__event-mode-reward {
    width: 96px;
    height: 78px;
    margin-bottom: 40px;
  }

  &__event-mode-reward-card {
    width: 78px;
    height: 78px;
    background: $color_white;
    margin: auto;
    border: 1px solid $color-silver;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
  }

  &__event-mode-reward-icon {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__event-mode-rewards-final {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__event-mode-rewards-final-title {
    font-size: 24px;
    line-height: 29px;
    margin-top: 100px;
    text-align: center;
  }

  &__event-mode-rewards-final-subtitle {
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px;
    text-align: center;
  }

  &__event-mode-final-bottom {
    padding-bottom: 20px;
    width: 100%;
  }

  &__spec-quiz {
    &-game-results {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-brand-logo {
      margin: 50px auto 0 auto;
      height: 105px;
      width: 100%;
      max-width: 200px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &-final-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -100px;
    }

    &-quiz-type {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin: 53px 35px 0 35px;
      padding-bottom: 10px;
    }

    &-answer-row {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }

    &-final-points {
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      margin-top: 10px;
      text-align: center;
    }

    &-final-extra-points {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #4cbf8f;
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    &-final-bottom {
      padding-bottom: 20px;
    }

    &-result-text {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      margin: 0 16px;
    }

    &-result-btn {
      width: calc(100% - 32px);
      height: 60px;
      margin: 20px 16px 16px 16px;
      background: $color-white;
      border-radius: 8px;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
    }
  }

  &__loading {
    width: 100%;
  }

  &__loading-main {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__invite-form {
    height: 100%;
    min-height: 550px;
    width: 100%;
  }

  &__main-container {
    display: flex;
    min-height: 89vh;
    flex-direction: column;
    justify-content: space-between;
  }

  &__back-button {
    position: absolute;
    z-index: 2;
    background: transparent;
    border: 0;
    padding: 32px 0 0 16px;
  }

  &__back-icon {
    height: 25px;
    margin-left: -8px;
  }

  &__back-icon:hover {
    cursor: pointer;
  }

  &__brand-logo {
    margin: 50px auto 0 auto;
    height: 105px;
    max-width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__clocks-animation-container {
    margin-top: 10px;
  }

  &__quiz-description {
    max-width: 340px;
    margin: 0 auto 0 auto;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: $color-white;
  }

  &__loading-opponent-animation {
    margin: auto;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid;
    box-sizing: border-box;
    display: flex;
  }

  &__avatar-container {
    width: 100px;
    height: 100px;
    margin: 50px auto auto;
  }

  &__avatar-icon {
    width: 100px;
    height: 100px;
  }

  &__avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &__avatar-animation-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__username {
    margin: auto;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  &__stat {
    display: flex;
    margin: auto;
    justify-content: center;
    margin-top: 50px;
  }

  &__stat-count {
    width: 80px;
    height: 80px;
    margin: 12px;
    border: 1px solid;
    border-radius: 50%;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__stat-name {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }

  &__invite-form-bottom {
    display: flex;
    width: calc(100% - 32px);
    align-self: center;
    padding-bottom: 30px;
    flex-direction: column;
    align-content: center;
  }

  &__bottom-button {
    margin: auto;
    background-color: $bottom-button-color;
    max-width: 350px;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    text-align: center;

    &-new-request {
      background-color: $bottom-button-color;
      height: 60px;
      border-radius: 8px;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      text-align: center;
    }
  }

  &__invite-status-text {
    margin: auto;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 0;
    margin-top: -70px;
  }

  &__declined-status-text {
    margin: auto;
    color: $color-red1;
    margin-top: 25px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  &__declined-area {
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
  }

  &__declined-icon {
    fill: $color_red1;
    height: 21px;
    width: 21px;
  }

  &__line {
    width: 1px;
    height: 35px;
    margin-top: 10px;

    &-long {
      width: 1px;
      height: 175px;
      margin-top: 10px;
    }
  }

  &__brand-logo-mini {
    margin: 0 auto;
    height: 90px;
    max-width: 166px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__pregame-text {
    margin-top: 25px;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  &__result-avatar-default {
    display: flex;
    width: 54px;
    height: 54px;
    margin: auto;
  }

  &__game {
    max-width: 375px;
    margin: 0 auto;
  }

  &__leave-game {
    display: flex;
    margin: auto;
    padding-top: 30px;
    font-size: 16px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: transparent;
    border: none;
  }

  &__leave-game-icon {
    width: 9px;
    height: 9px;
    margin-right: 9px;
  }

  &__game-progress {
    display: flex;
    max-width: 340px;
    width: 100%;
    margin: auto;
    margin-top: 30px;
  }

  &__user-progress {
    display: flex;
    flex-direction: column;
    flex-basis: 60px;
    flex-grow: 1;
  }

  &__opponent-progress {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-basis: 60px;
    flex-grow: 1;
  }

  &__score {
    display: flex;
    justify-content: center;
    flex-basis: 60px;
  }

  &__divider {
    width: 1px;
    height: 25px;
    background: white;
    margin: 0 10px 0 10px;
  }

  &__user-progress-points {
    margin-top: 5px;
    display: flex;
  }

  &__user-progress-points-solo {
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }

  &__user-progress-username {
    word-wrap: break-word;
  }

  &__game-question-text {
    display: flex;
    font-weight: 500;
    font-size: 21px;
    line-height: 110%;
    max-width: 340px;
    margin: 15px auto auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 51px;
    height: auto;
    word-break: break-word;
    word-wrap: break-word;
  }

  &__image-large-container {
    margin-top: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__double-points-title-container {
      display: flex;
      justify-content: center;
      padding: 2px 10px;
      position: absolute;
      width: auto;
      height: 15px;
      background: $color-aquamarin;
      border-radius: 8px;
      z-index: 51;
      top: -10px;
    }

    &__double-points-title {
      display: flex;
      position: static;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      align-items: center;
      color: $color-white;
    }
  }

  &__disclaimers {
    position: absolute;
    width: 100%;
    bottom: 0;

    &-price-disclaimer {
      width: 100%;
      height: 10px;
      background: $color-white;
      font-weight: 500;
      font-size: 9px;
      line-height: 10px;
      color: $color-black;
      border-radius: 0 0 3px 3px;
      text-align: center;

      &-with-disclaimer {
        @extend .quiz-page__disclaimers-price-disclaimer;
        border-radius: 0;
      }
    }

    &-line-between-disclaimers {
      color: $color-black;
      background-color: $color-black;
      border: none;
      height: 2px;
      margin: 0;
    }

    &__double-points {
      border: 3px solid $color-aquamarin;
      border-top: 0;
      box-sizing: border-box;
      border-radius: 3px;
    }
  }

  &__disclaimer,
  &__disclaimer-caucasus {
    width: 100%;
    margin: auto;
    font-size: 14px;
    height: 40px;
    line-height: 31px;
    color: $color-black;
    background: transparent;
    text-align: center;
    font-weight: bold;
    align-items: center;

    &-with-border {
      @extend .quiz-page__disclaimer;
      background: $color-white;
      border: 2px solid $color-black;
      border-radius: 0 0 3px 3px;
      box-sizing: border-box;
    }

    &_ru-region {
      display: flex;
      justify-content: center;
      border: none;
      height: unset;
      padding-top: 5px;

      > svg {
        padding: 0 5px;
      }
    }

    &-image {
      width: 100%;
      height: 100%;
    }
  }

  &__disclaimer-caucasus {
    line-height: 14px;
  }

  &__answer-disclaimer {
    font-size: 11px;
    height: 35px;
    line-height: 14px;

    .quiz-page__disclaimer-logic {
      width: 97%;
    }
  }

  &__disclaimer-logic-container {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -3px;
    display: flex;
    height: 35px;
    background: $color-white;
    font-weight: bold;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
  }

  &__disclaimer-logic {
    position: absolute;
    margin: auto;
    height: 80%;
    width: 70%;
    fill: $color-black;
  }

  &__image-large {
    border-radius: 3px;
    width: 100%;
    min-width: 280px;
    height: 206px;
    display: flex;
    margin: auto;
    object-fit: cover;

    &__with-boosters {
      height: 190px;
    }

    &-with-disclaimer {
      border-radius: 3px 3px 0 0;
      width: 100%;
      min-width: 280px;
      height: auto;
      display: flex;
      margin: auto;
      object-fit: cover;
    }

    &__double-points {
      width: 100%;
      display: flex;
      position: relative;
      border: 3px solid $color-aquamarin;
      box-sizing: border-box;
      border-radius: 3px;
    }
  }

  &__image-large-max {
    border-radius: 3px;
    width: 100%;
    min-width: 280px;
    height: 306px;
    display: flex;
    margin: auto;

    &__with-boosters {
      height: 280px;
    }

    &-with-disclaimer {
      border-radius: 3px;
      width: 100%;
      min-width: 280px;
      height: auto;
      display: flex;
      margin: auto;
    }

    &__double-points {
      width: 100%;
      display: flex;
      position: relative;
      border: 3px solid $color-aquamarin;
      box-sizing: border-box;
      border-radius: 3px;
    }
  }

  &__answers {
    max-height: 260px;
    display: flex;
    margin: auto;
    margin-top: 10px;
    flex-wrap: wrap;
    padding-bottom: 20px;
    justify-content: center;
  }

  &__answers-pictures {
    position: relative;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    width: 100%;

    &__double-points {
      width: 100%;
      display: flex;
      position: absolute;
      border: 3px solid $color-aquamarin;
      box-sizing: border-box;
      border-radius: 3px;
    }
  }

  &__answer {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 15px;
    cursor: pointer;

    &:nth-child(2n + 1) {
      margin-right: 5px;
    }

    &-text {
      color: black;
      z-index: 1;
      max-width: 130px;
      position: absolute;
      word-break: break-word;
      line-height: 22px;

      &.wrong {
        background: $color-red;
        color: $color-white;
      }

      &.correct {
        background: $color-green8;
        color: $color-white;
      }
    }
  }

  &__image-answer-container {
    position: relative;
    width: calc(50% - 2px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 4px;
  }

  &__image-answer-container_my-answer {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 7px solid transparent;
    z-index: 100;
  }

  &__image-answer-container_opponent-answer {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 5px solid transparent;
    z-index: 100;
  }

  &__answered-image- {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.8;
    z-index: 10;

    &correct {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 50;
      opacity: 0.8;
      background: $color-green8;
    }

    &wrong {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 50;
      opacity: 0.8;
      background: $color-red;
    }
  }

  .with-disclaimer {
    border-radius: 3px 3px 0 0;
  }

  &__image-answer.with-disclaimer {
    border-radius: 3px 3px 0 0;
  }

  &__image-answer {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 190px;
    border-radius: 3px;

    &-text {
      color: black;
      z-index: 1;
      max-width: 130px;
      position: absolute;

      &.wrong {
        background: $color-red;
        color: $color-white;
      }

      &.correct {
        background: $color-green8;
        color: $color-white;
      }
    }
  }

  &__answer-svg {
    fill: $color_white;
    stroke: $color_white;

    &.wrong {
      fill: $color-red;
      stroke: $color-red;
    }

    &.correct {
      fill: $color-green8;
      stroke: $color-green8;
    }
  }
}

@media screen and (max-width: 340px) {
  .quiz-page {
    &__disclaimer {
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 320px) {
  .quiz-page {
    &__image-answer-container {
      height: 150px;
    }

    &__answers {
      max-height: none;
      flex-direction: column;
      align-items: center;
    }

    &__answer {
      &:nth-child(2n + 1) {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-height: 650px) {
  .quiz-page {
    &__image-answer-container {
      height: 190px;
    }
  }
}

@media screen and (max-height: 600px) {
  .quiz-page {
    &__image-large {
      height: 196px;

      &__with-boosters {
        height: 172px;
      }
    }

    &__image-large-max {
      height: 250px;

      &__with-boosters {
        height: 232px;
      }
    }
  }
}
