@import '../../../styles/common';

.oppa {
  &__back-button {
    position: fixed;
    top: 20px;
    left: 16px;
    background: none;

    &-white {
      @extend .oppa__back-button;
      & path {
        fill: $color-white;
      }
    }
    &-black {
      @extend .oppa__back-button;
      & path {
        fill: $color-black;
      }
    }
  }

  &__mainPage {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
  }

  &__button {
    font-size: 17px;
    min-width: 250px;
    align-self: stretch;
    color: $color-white;
    padding: 20px 50px 19px;
    border-radius: 8px;
    font-weight: bold;
    background: none;
    -webkit-tap-highlight-color: transparent;
    flex-grow: 5;
  }
}
