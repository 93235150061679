@import '../../../styles/common';

$button_background_color: $color-aquamarin;
$background_white_color: $color-white;
$copy_text_gray_color: $color_gray4;

.invite-code-page {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background-color: $background_white_color;
}

.invite {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__code-section {
    margin-top: 90px;

    &-image {
     @media screen and (max-height: 620px) {
       height: 126px;
     } 

     @media screen and (min-height: 720px) {
       height: 300px;
     }

     height: 202px;
    }

    &-text {
      font-size: 22px;
      font-weight: bold;
      line-height: 26px;
      letter-spacing: 4px;
      text-align: center;
      color:  $color-aquamarin;
    }
  
    &-copy-text {
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: $copy_text_gray_color;
    }
  }

  &__bottom-section {
    margin: 30px 16px 30px 16px;

    &-caption {
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      font-weight: bold;
    }
  
    &-description {
      margin-top: 10px;
      font-size: 17px;
      line-height: 20px;
      text-align: center;
    }

    &-button {
      margin-top: 30px;
      width: 100%;
      height: 60px;
      background: $button_background_color;
      border: 1px solid $color-aquamarin;
      border-radius: 8px;
      color: $color-white;
      font-size: 17px;
      line-height: 20px;
      font-weight: bold;
      text-align: center;
    }
  }
}
