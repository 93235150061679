@import '../../../styles/common';

$header_and_button_background_color: $color-aquamarin;
$background_white_color: $color-white;
$text_white_color: $color-white;
$text_black_color: $color-black;

.multibrand-quiz-page {
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  justify-content: center;

  &__no-bonus-game {
    background-color: $background_white_color;
    width: 100%;

    &__content-area {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      &-caption {
        margin: 0 16px 10px 16px;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: $text_black_color;
        font-weight: bold;
      }

      &-schedule-container {
        min-height: 285px;
        width: 100%;
      }
      @media screen and (max-height: 650px) {
        &-schedule-container {
          margin-top: 10px;
          max-height: 285px;
        }
      }
      &-description {
        font-size: 16px;
        line-height: 21px;
        text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.0305423);
        font-weight: normal;
        margin: 0 16px 40px 16px;
      }
      @media screen and (max-height: 600px) {
        &-description {
          margin: 0 16px 25px 16px;
        }
      }
      .btn-component {
        border-radius: 8px;
        height: 60px;
        width: calc(100% - 32px);
        margin: auto;
        background: $header_and_button_background_color;
        font-size: 17px;
        font-weight: bold;
        color: $text_white_color;
      }
    }
    @media screen and (max-height: 600px) {
      &__bottom-text {
        margin-bottom: 30px;
      }
    }
    &__button-container {
      width: 100%;
      display: flex;
    }
  }

  &__game {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &__leave-game {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 14px;
    }

    &__leave-game-icon {
      width: 9px;
      height: 9px;
      margin-right: 5px;
    }

    &__progress {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      margin-top: 30px;
    }
  }

  &__final {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../../images/background-images/bg-image-2.png');
    display: flex;
    flex-direction: column;

    &__body {
      height: calc(100% - 152px);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color_white;
      padding-bottom: 5px;
      border-bottom: 2px solid $color_white;
      margin: 0 36px 0 36px;
      text-align: center;
    }

    &__result {
      margin-top: 70px;
      font-weight: bold;
      font-size: 36px;
      line-height: 43px;
      text-align: center;
      color: $color_white;
    }

    &__result-text {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: $color_white;
    }
  }
}
