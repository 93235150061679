@import '../../../styles/common';

$button_background_color: $color-aquamarin;
$border_color: $color-aquamarin;
$text_white_color: $color-white;
$background_white_color: $color-white;

.transfer-tutorial-fifth-step {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 600;

  &__content {
    width: 100%;
    position: absolute;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  &__description {
    width: calc(100% - 64px);
    padding: 0 16px;
    background: $background_white_color;
    margin-bottom: 30px;
    border: 1px solid $border_color;
    box-sizing: border-box;
    border-radius: 16px;

    &-step-number {
      margin: 29px 0 0 0;
      font-size: 10px;
      line-height: 11px;
      text-align: center;
    }
    &-title {
      margin: 10px 0 0 0;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }
    &-text {
      margin: 10px 0 50px 0;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
    }
  }

  &__arrow-button {
    justify-self: flex-end;
    align-self: flex-end;
    width: 51px;
    height: 51px;
    padding: 0;
    background: transparent;
    border-radius: 50%;
    margin: 0 16px 0 0;
  }
}
