@import '../../styles/common';

$background-color: $color-white;
$text_white_color: $color-white;

.passport-signing-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: $background-color;

  &__header-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $text_white_color;
  }

  &__content {
    height: calc(100% - 228px);
    width: 100%;
  }
}
