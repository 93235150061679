@import '../../../styles/common';

.daily-rewards {
  overflow: hidden;
  padding: 80px 20px 30px 20px;
  box-sizing: border-box;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  &__spinner {
    position: relative;
    overflow: hidden;
    height: 200px;
    display: flex;
  }
  &__spinner-content {
    margin-right: 10px;
    margin-left: 10px;
  }
  &__finish-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    color: $color-white;
    &__image {
      width: 100%;
      max-width: 144px;
      height: auto;
      margin-bottom: 10px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 30px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
    }
    &__time-left {
      margin: auto;
      width: 60px;
    }
    &__points {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }
    &__button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 100%;
      text-align: center;
      background-color: $color-white;
      color: $color-black;
      border-radius: 8px;
    }
  }
  &__start-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
    justify-content: space-between;
    &__header {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: $color-white;
      height: 30px;
      margin-bottom: 10px;
    }
    &__content {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: $color-white;
    }
    &__prizes {
      height: 83px;
      width: 83px;
      color: $color-white;
      &__wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-row-gap: 23px;
        padding: 30px 0 20px;
        justify-content: center;
      }
      &-active {
        background: $color-green1;
        border: 2px solid $color-white;
        box-sizing: border-box;
        box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.7);
        border-radius: 100px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        &__content {
          color: $color-white;
          height: 20px;
        }
      }
      &-hidden {
        background: $color-green1;
        opacity: 0.5;
        border: 1px solid $color-white;
        box-sizing: border-box;
        border-radius: 100px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-complete {
        background: $color-green1;
        border: 2px solid $color-white;
        box-sizing: border-box;
        box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.7);
        border-radius: 100px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        &-content {
          padding-top: 5px;
        }
      }
      &__super-prize {
        &__icon {
          width: 100px;
          height: 100px;
          border-radius: 50px;
          display: flex;
          margin: auto;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background-color: $color-white;
          &-hidden {
            opacity: 0.2;
          }
        }
        &__text {
          margin-top: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          color: $color-white;
        }
      }
    }
  }
}
