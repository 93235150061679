@import '../../../../styles/common';

.ticket {
  padding: 15px;
  background-color: $color-white;
  border-radius: 8px;
  border: 1px solid black;
  border-top: 3px solid black;
  position: relative;

  .newMessageBadge {
    position: absolute;
    right: -5px;
    top: -6px;
  }

  .name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 40px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .status {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 14px;

      > *:first-child {
        margin-right: 4px;
      }
    }

    .time {
      color: $color-gray4;
      font-size: 10px;
    }
  }
}

.ticket.checking {
  border-color: $color-moderation;
}

.ticket.completed {
  border-color: $color-success;
}

.ticket.noAnswer {
  border-color: $color-waiting;
}