@import '../../styles/common';

$background_white_color: $color-white;
$text_white_color: $color-white;
$text_black_color: $color-black;
$button_background_color: $color-aquamarin;

.profile-page {
  background-color: $background_white_color;
  width: 100%;
  overflow: hidden;

  &__got-promo-code-link {
    padding: 20px 16px;
    text-align: center;
  }

  &__got-promo-code-button {
    height: 60px;
    width: 100%;
    border-radius: 8px;
    background: $button_background_color;
    font-size: 17px;
    color: $text_white_color;
  }

  &__header-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: $text_white_color;
    margin: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-bottom: $footer-height;

    &-info-block {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 233px;

      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 16px 30px;
      position: relative;

      &-settings-button {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 88%;
        top: 25px;
      }

      &-photo {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
        margin-bottom: 20px;
      }
      .with-user-photo {
        background: transparent;
      }

      &-name {
        margin: 0;
        font-size: 24px;
        line-height: 29px;
        font-weight: bold;
        padding-bottom: 30px;

        text-align: center;

        color: $text_white_color;
      }

      &-balance {
        margin: 0 0 30px;
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        color: $text_white_color;
      }

      &-games-results-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 70px;
          margin: 0 5px;

          &-caption {
            margin: 0;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: $text_white_color;
            opacity: 0.5;
          }

          &-amount {
            font-size: 18px;
            line-height: 21px;
            opacity: 1;
          }
        }
        &-info:first-child {
          margin: 0 5px 0 0;
        }
        &-info:last-child {
          margin: 0 0 0 5px;
        }
      }

      &-achievements-summary {
        width: 100%;
      }
    }

    &-leaderboard {
      &-brands-rating {
        border: 0;
        margin: 0 auto;
        padding: 0;
        border-radius: 0;
        background-color: $background_white_color;
        font-family: SF Pro Display, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-align-last: center;
      }
      &-select-brand-form {
        margin-top: 10px;
        display: flex;
        margin-bottom: 15px;
      }
      &-select-filter-block {
        display: flex;
        flex-direction: column;
        padding: 30px 0 20px;
        align-items: center;
        justify-items: center;
        margin: 0 32px;

        &__mystery-shopper {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 0 16px;
          align-items: center;
          width: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
          border-radius: 16px;
          background-image: url('../../images/background-images/mystery-shopper-recommendations-bg.png');
          height: 80px;
          margin-bottom: 30px;

          &-title {
            color: $color-white;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: center;
            width: 200px;
          }

          &-arrow {
            position: absolute;
            top: 31px;
            right: 20px;
          }
        }

        &-brands {
          height: 130px;
        }

        &-caption {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: center;

          &-brands {
            margin: 0;
          }
        }

        &-filters {
          width: 60%;
          display: flex;
          margin-top: 25px;
          flex-direction: row;
          justify-content: space-between;

          &-filter {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 25px;
            border: 1px solid transparent;
            border-radius: 100px;

            &-name {
              margin: 0;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 14px;
              display: flex;
              align-items: flex-end;
              text-align: center;
            }
          }
          .selected {
            border: 1px solid black;
          }
        }
      }
    }

    &-links-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0 10px;

      &__clear-cache {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $color-gray1;
        border-radius: 16px;
        margin-bottom: 40px;
        padding: 0 15px;
        height: 85px;
        width: calc(100% - 64px);

        &-title {
          font-weight: 700;
          font-size: 12px;
          line-height: 120%;
          max-width: 150px;
        }

        &-button {
          border: 1px solid var(--theme-color);
          background: transparent;
          padding: 10px 13.5px;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          border-radius: 8px;
        }
      }

      &-link {
        margin-bottom: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        text-decoration-line: underline;

        color: $text_black_color;
      }
    }

    &-invite-code-block {
      display: flex;
      flex-direction: column;
      margin: 60px 16px 30px 16px;

      &-caption {
        font-weight: bold;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        text-align: left;
        margin-bottom: 15px;

        color: $text_black_color;
      }

      &-descrpition {
        font-style: normal;
        font-size: 17px;
        line-height: 20px;
        text-align: left;

        color: $text_black_color;
      }

      &-button {
        margin-top: 30px;
        height: 60px;
        width: 100%;
        background: $background_white_color;
        border: 1px solid $color-aquamarin;
        border-radius: 8px;
        font-style: normal;
        font-size: 17px;
        font-weight: bold;
        line-height: 20px;
        color: $color-aquamarin;
      }
    }
  }
}
