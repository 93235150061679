@import 'colors';

$header-height: 68px;
$footer-height: 85px;
$navigation-menu-height: 53px;
$disclaimer-height: 15px;
$spinner-height: 60px;
$spinner-width: 100px;

:export {
  headerHeight: $header-height;
  spinnerHeight: $spinner-height;
  spinnerWidth: $spinner-width;

  successColor: $color-green5;
  failedColor: $color-red1;
  moderationColor: $color-orange;
}
