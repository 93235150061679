@import "../../../styles/common";

.arrow-button {
  justify-self: flex-end;
  align-self: flex-end;
  width: 51px;
  height: 51px;
  padding: 0;
  background: transparent;
  border-radius: 50%;
  margin: 20px 16px 30px 0;
}

.arrow-button:disabled {
  opacity: 0.3;
}
