@import 'src/styles/common';

.container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: $header-height;

  .label {
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: $color-white;
  }

  .title {
    color: $color-white;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }

  .content {
    margin: 0 16px;

    .caption {
      display: block;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: $color-white;
      margin: 44px 0 30px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 60px 16px 0;

    .title {
      color: $color-white;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 15px;
    }

    .description {
      color: $color-white;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 30px;
    }

    .button {
      background-color: $color-white;
      color: $color-black;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      border-radius: 8px;
      height: 60px;
      width: 100%;
    }
  }
}