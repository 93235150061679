@import "src/styles/common";

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 0 10px 15px;
  position: relative;
  height: 70px;
  margin: 0 16px 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 16px;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $color-white;
  }

  .date {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: $color-white;
  }

  .arrow {
    position: absolute;
    right: 21px;
    top: 50%;
  }
}