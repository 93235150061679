@import '../../styles/common';

.profile-page {
  width: 100%;
  overflow: hidden;
  height: 100%;

  &__swipeable-header {
    transition: all 0.5s linear;
    display: flex;
    top: 0;
    z-index: 2;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 68px;
    //background-color: $color-green1;
    color: $color-white;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }

  &__tabs {
    width: 100%;
  }

  
  &__tab,&__active-tab {
    width: 50%;
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
    color: $color_white;
    opacity: 0.5;
    font-weight: bold;
  }

  &__active-tab {
    opacity: 1;
    padding: 10px 0 9px 0;
    border-bottom: 1px solid;
    box-sizing: border-box;
  }

  &__views {
    position: sticky;
    width: 100%;
    height: calc(100% - 68px);
    overflow: hidden;

    > div {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }
}
