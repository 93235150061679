@import '../../../../styles/common';

.addCardPage {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;

  .backButton {
    align-self: flex-start;
    margin: 20px 0 0 16px;
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: $color-white;
    border-radius: 25px 25px 0 0;

    .faq {
      align-self: flex-end;
      margin: 20px 16px 10px 0;

      & path {
        fill: $color-black;
      }

      & circle {
        stroke: $color-black;
      }
    }

    .title {
      font-size: 18px;
      font-weight: 700;
      margin: 5px 0;
    }

    .description {
      text-align: center;
      font-size: 18px;
      margin: 5px 0 8px 0;
    }

    .button {
      border-radius: 8px;
      height: 60px;
      width: 70%;
      color: $color-white;
      font-size: 18px;
      padding: 20px 50px 19px;
      margin-bottom: 5px;
    }

    .link {
      text-decoration: underline;
      color: $color-green1;
      margin: 20px;
    }
  }
}
