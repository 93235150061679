@import '../../../styles/common';

.item-container {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(25% - 30px);
  align-items: center;
  height: 60px;
  margin: 0 15px;

  &__item-logo-wrap {
    display: flex;
    -webkit-flex: none;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 60px;
  }
  
  &__item-logo {
    width: 40px;
    height: 40px;
    border: 1px solid $color-green1;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: $color-white;    
    overflow: hidden;

    &-unread {
      position: absolute;
      right: 12px;
      top: 0;
      width: 8px;
      height: 8px;
      border-radius: 5px;
      background: $color-red1;
    }
  }

  &__item-picture {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

.item-name {
  font-size: 10px;
  line-height: 12px;
  margin-top: 8px;
  text-align: center;
}

@media screen and (min-width: 320px) {
  .item-container {
    flex: 0 0 calc(25% - 17px);
    margin: 0 9px;
  }
}

@media screen and (max-width: 340px) {
  .item-container:first-child {
    margin-left: 0;
  }
}
