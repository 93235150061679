@import 'src/styles/common';

.messageBlock {
  padding: 0 16px;
}

.messageBlock.chat {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-bottom: 30px;
  }

  .date {
    margin: 35px 0 15px;
    padding: 5px 15px;
    font-size: 10px;
    border-radius: 20px;
    color: $color_gray6;
    background-color: $color-gray2;
  }

  .messages {
    width: 100%;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;

    .message:not(:last-of-type) {
      margin-bottom: 50px;
    }
  }
}

.messageBlock.notifications {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-child {
    margin-bottom: 35px;
  }

  .date {
    margin: 35px 0 15px;
    font-size: 10px;
    color: $color_gray4;
  }

  .messages {
    width: 100%;

    .message:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}

.withoutSpace {
  padding: 0;
}
