@import '../../../../styles/common';

.mainPage {
  position: relative;
  display: flex;
  flex-direction: column;

  .header {
    color: $color-white;
    font-size: 18px;
    font-weight: bold;
  }

  .container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url('../../../../images/background-images/unicard-bg-image.png');

    .settings {
      align-self: flex-end;
      margin: 20px 16px 0 0;
    }

    .wallet {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $color-white;

      .title {
        margin: 6px 0;
        font-size: 16px;
        font-weight: bold;
      }

      .walletNumber {
        margin: 0 0 30px 0;
        font-size: 22px;
        font-weight: normal;
      }
    }

    .history {
      position: relative;
      display: flex;
      flex-direction: column;

      .faq {
        position: relative;
        align-self: flex-end;
        margin-right: 16px;
        top: 20px;
        border: 1px solid $color-black;
        border-radius: 50%;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1));
    padding: 15px 0;

    position: fixed;
    .transferButton {
      height: 60px;
      width: 90%;
      border-radius: 8px;
      background: $color-green1;
      font-size: 17px;
      color: $color-white;
      font-weight: bold;

      &:disabled {
        opacity: 0.3;
      }
    }
  }
}
