@import '../../styles/common';

.KB-choose-brand {
  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-component {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: 10px;

      .header-arrow {
        height: 21px;
        fill: $color-white;
      }
    }
  }
  &__brand-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__hungaryHeader {
    background-color: white !important;

    &-hungaryText {
      color: $color-black;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__header-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin: 0;
  }

  &__brands {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-row-gap: 44px;
    padding: 40px 24px 20px;
    justify-content: center;
    &_hungary,
    &_belarus {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      padding-bottom: $footer-height;
      box-sizing: border-box;
    }
    &_belarus {
      padding-bottom: 50px;
    }
    &-brand {
      max-width: 80px;
      min-width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      justify-self: center;

      &_hungary,
      &_belarus {
        width: calc(100% - 32px);
        height: 112px;
        border-radius: 8px;
        margin-bottom: 10px;
        background-size: auto 112px;
        background-position: center center;
        background-repeat: repeat-x;
      }
      &_belarus {
        background-size: cover;
      }

      &-icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-bottom: 8px;
      }
      &-name {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
      }
    }
  }

  &__warning_belarus {
    // For sticky position(if needed in future)
    // position: fixed;
    // bottom: 45px;
    // padding: 5px 0px;
    // background: white;
    width: calc(100% - 32px);
    > svg {
      height: 40px;
      width: 100%;
    }
  }
}
