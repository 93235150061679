@import "src/styles/common";

.container {
  background-image: url('../../../images/background-images/mystery-shopper-bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  min-height: 100%;
  flex-direction: column;
  position: relative;
  padding: 0 16px;

  .form {
    margin-bottom: 20px;
  }

  .currentQuestion {
    color: $color-white;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 30px 0 10px;
  }

  .questionText {
    color: $color-white;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    margin-bottom: 70px
  }

  .priceQuestionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .imageWrapper {
      position: relative;
      max-height: 300px;
      max-width: 300px;
      margin-bottom: 50px;

      .image {
        width: 100%;
        height: 100%;
      }

      .disclaimer {
        position: absolute;
        bottom: -25px;
        left: 0;
        width: 100%;
        height: 30px;
      }
    }

    .hint {
      text-align: center;
      margin-top: 20px;
      color: $color-white;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
    }

    .priceQuestionInput {
      background-color: transparent;
      border: 1px solid white;
      text-align: center;
      border-radius: 16px;
      width: 62px;
      height: 78px;
      padding: 0;
      color: $color-white;
    }

    .correctAnswer {
      color: white;
      background-color: #4CBF8F;;
    }

    .incorrectAnswer {
      color: white;
      background-color: $color-red1;
    }
  }

  .nextButton {
    background: transparent;
    position: fixed;
    bottom: 15px;
    right:15px ;
  }
}