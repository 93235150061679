#snow {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #e7edf0;
  top: 0;
  left: 0;
  pointer-events: none;
}

.snowflake {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #fff;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  animation: fall 5s infinite linear, rotate 10s infinite linear;
  opacity: 0.8;
}

@keyframes fall {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
}