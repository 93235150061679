@import '../../../styles/common';

$range-thumb-width: 5px;

.user-container {
  width: calc(100% - 32px);
  margin: 30px auto;
  display: flex;
  justify-content: space-between;

  &__greetings {
    display: flex;
    font-size: 12px;
    color: $color_black;
    align-items: center;
  }
  &__georgia-greetings {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: $color_black;
    align-items: self-start;

    &__name {
      font-weight: 700;
    }
  }

  &__user-photo-block {
    width: 32px;
  }
  &__user-photo-item {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    object-fit: cover;
  }
  &__progress-bar {
    &-block {
      width: 100%;
      flex-direction: column;
      display: flex;
      justify-content: space-around;
      padding-left: 15px;
    }
    &-headline {
      font-size: 10px;
      line-height: 14px;
      margin-top: 4px;
      color: $color_black;
      letter-spacing: -0.3px;
    }
  }
}

.progress-bar {
  &__wrapper {
    display: flex;
    width: 100%;
    height: 3px;
    background-color: #f1f1f1;
    border-radius: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
  &__lower-range-trace {
    height: 3px;
    width: 73%;
    background-color: #ffac30;
    border-radius: 10px 0px 0px 10px;
  }
  &__thumb {
    height: 5px;
    width: 5px;
    background-color: #ffac30;
    border-radius: 100%;
    margin-left: calc(-#{$range-thumb-width} / 2);
  }
}
