@import '../../../styles/common';

.back-arrow-button {
  position: absolute;
  background: transparent;
  border-radius: 50%;
  margin: 20px 0px 20px 0px;
}

.back-arrow-button:disabled {
  opacity: 0.3;
}

.header-arrow {
  width: 21px;
  height: 21px;
  fill: var(--theme-color);
}
