@import '../../styles/common';

$text_color: $color_gray4;
$border_color: $color-gray3;
$badge_background_color: $color-gray12;
$badge_background_color_reward_high: $color-orange2;
$badge_background_color_reward_medium: $color-orange3;
$badge_text_color_reward_high: $color-white;
$badge_text_color_reward_medium: $color-white;

.quiz-card-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 64px;
  padding: 20px 16px 15px;
  border-bottom: 1px solid $border_color;
  justify-content: space-between;

  @mixin badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    padding: 3px 10px;
    border-radius: 16px;
    background: $badge_background_color;
    color: $text_color;
    margin: 0;
    margin-right: 5px;

    svg {
      margin-right: 3px;
    }
  }

  &-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    &-name {
      width: 100%;
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
    }
    &-unread-badge {
      position: unset;
      margin: 0;
      padding: 0;
      min-width: 10px;
      min-height: 10px;
      width: 10px;
      height: 10px;
    }
  }

  &-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &-block {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-caption {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        margin: 0;
        color: $text_color;
      }

      &-badge {
        @include badge;
      }

      &-reward-high {
        @include badge;
        background: $badge_background_color_reward_high;
        color: $badge_text_color_reward_high;
      }

      &-reward-medium {
        @include badge;
        background: $badge_background_color_reward_medium;
        color: $badge_text_color_reward_medium;
      }
    }
    &-brand {
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
    }
    &-description {
      font-size: 12px;
      color: $color_gray4;
    }
    &-counter {
      margin-right: 5px;
      font-size: 14px;
      color: $color_gray4;
    }
  }
}
