@import '../../../styles/common';

$background_white_color: $color-white;
$background_grey_color: $color-gray3;
$text_grey_color: $color-gray5;
$copied_green_color: $color-green5;

.invite-code-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: round;
  background-size: 100%;
}

.invite {
  &__top-section {
    height: $header-height;
    width: 100%;
    display: flex;

    &-back-button {
      background-color: transparent;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__card {
    display: flex;
    flex-direction: column;
    margin: 0 16px 50px 16px;
    border-radius: 16px;
    background-color: $background_white_color;

    &-info {
      margin: 50px 16px 40px 16px;
      &-caption {
        font-weight: bold;
        font-style: normal;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
      }

      &-text {
        margin-top: 10px;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
      }
    }

    &-code {
      margin: 0 16px 20px 16px;
      border: 1px solid $background_grey_color;
      border-radius: 16px;
      background-color: $background_grey_color;
      box-sizing: border-box;

      &-copy-status {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 10px;
        font-size: 10px;
        line-height: 100%;
        color: $text_grey_color;
        opacity: 0;
        &-img {
          margin-left: 5px;
        }

        &-copied {
          @extend .invite__card-code-copy-status;
          opacity: 1;
        }

        &-copied[slide_in='1'] {
          @extend .invite__card-code-copy-status;
          -webkit-animation: 0.2s slide_in;
          animation: 0.2s slide_in;
          opacity: 1;
        }
      }

      &-copied {
        @extend .invite__card-code;
        border-color: $copied_green_color;
      }

      &-text {
        margin: 0 10px 35px 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }

    &-button {
      height: 60px;
      margin: 0 16px 50px 16px;
      background: $color-aquamarin;
      border-radius: 8px;
      color: $color-white;
      font-size: 17px;
      line-height: 20px;
      font-weight: bold;
      text-align: center;
      box-sizing: border-box;

      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      &:active {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
      }
    }
  }
}

@keyframes slide_in {
  from {
    opacity: 0%;
    transform: translateY(10px);
    -webkit-transform: translateY(10px);
  }

  to {
    opacity: 100%;
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
