@import '../../../styles/common';

.progress-page {
  min-height: calc(100% - 68px);
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;

  &__time {
    margin-top: 20px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: $color_white;
  }

  &__faq {
    position: absolute;
    top: 20px;
    right: 16px;
    line-height: 20px;
    box-sizing: border-box;
  }

  &__animation-container {
    display: flex;
    margin-top: 20px;
    width: 100%;
    min-height: 240px;
    justify-content: center;
    position: relative;
  }

  &__progress {
    margin-top: 72px;
    position: absolute;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: $color_white;
    opacity: 0.4;
  }

  &__current-points {
    margin-top: 94px;
    position: absolute;
    font-size: 44px;
    line-height: 52px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $color_white;
  }

  &__hints {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: $color_white;
    margin: 20px 16px 0 16px;
  }

  &__devider {
    display: flex;
    width: calc(100% - 32px);
    height: 1px;
    margin: 30px auto 0 auto;
    background: $color_white;
    opacity: 0.2;
  }

  &__activity-header {
    margin-top: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $color_white;
  }
  &__activities {
    width: calc(100% - 32px);
    margin: 20px auto 78px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__activity-container {
    display: flex;
    flex-basis: calc(25% - 10px);
    flex-direction: column;
    align-items: center;
    margin: 0 5px 18px;
    .item-name {
      color: $color_white;
    }

    .item-container__item-logo-unread {
      right: 12px;
    }

    .item-container__item-logo {
      border: none;
    }
  }
}

@media screen and (min-width: 350px) {
  .progress-page {
    &__activity-container {
      flex: 0 0 calc(25% - 18px);
      margin: 0 9px 18px;
    }
  }
}
