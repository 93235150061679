@import '../../styles/common';

$header_and_button_background_color: $color-aquamarin;
$background_white_color: $color-white;
$text_white_color: $color-white;
$text_black_color: $color-black;
$bottom_line_color: $color-black;

.home-page {
  background-color: $background_white_color;
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: sticky;

  &__header-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: $text_white_color;
    margin: 0;
  }

  &__content-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 80px;
  }

  &__without-alignment {
    align-items: normal;
    padding-top: 0;
  }
}
