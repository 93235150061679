@import '../../../styles/common';

$button_background_color: $color-aquamarin;
$background_white_color: $color-white;
$text_white_color: $color-white;
$phone_back_color: $color-dark;
$text_grey_color: $color_gray4;
$divider_color: $color-gray7;

.relative-container {
  height: 100%;
  position: relative;
}
.money-for-the-phone-page {
  background-color: $background_white_color;
  width: 100%;

  &__header {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $text_white_color;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;

      &-phone-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 84px;
        width: calc(100% - 32px);
        background: $phone_back_color;
        color: $text_white_color;

        &-caption {
          margin: 5px 0 5px 0;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
        }

        &-phone {
          margin: 0;
          font-size: 22px;
          line-height: 26px;
          text-align: center;
        }
      }

      &-faq {
        margin-top: -30px;
        margin-left: calc(100% - 10px);
      }
    }

    &-history {
      display: flex;
      flex-direction: column;
      padding: 30px 36px 180px 36px;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      margin-top: -20px;
      width: calc(100% - 72px);
      background: $background_white_color;

      &-empty {
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: $text_grey_color;
        position: absolute;
        width: calc(100% - 72px);
        top: 50%;
      }

      &-caption {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        margin: 0 0 15px 0;
      }

      &-list {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 44px;
          margin-bottom: 5px;

          &-info {
            display: flex;
            flex-direction: column;

            &-amount {
              font-size: 14px;
              line-height: 17px;
              margin: 5px 0;
            }

            &-date {
              font-size: 10px;
              line-height: 12px;
              margin: 0 0 5px 0;
              opacity: 0.2;
            }
          }

          &-status {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 17px;

            &-icon {
              margin-left: 5px;
              margin-top: 3px;
            }
          }
        }

        &-divider {
          width: 100%;
          border-top: 1px solid $divider_color;
          opacity: 0.2;
          margin-bottom: 5px;
        }
      }
    }

    &-btn-container {
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 1));
      padding: 15px 0;
      display: flex;
      justify-content: center;
      position: fixed;
      bottom: 15px;

      &-button {
        height: 60px;
        width: calc(100% - 32px);
        border-radius: 8px;
        background: $button_background_color;
        font-size: 17px;
        color: $text_white_color;
        font-weight: bold;
      }
    }
  }
}
