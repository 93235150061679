@import "../../../../styles/common";

.container {
  background-color: $color-black;
  width: 100%;
  height: 100%;
}

.answerInput {
  display: none;
}

.label {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid $color-white;
  margin-bottom: 10px;
  padding-left: 50px;
  padding-right: 20px;
  color: $color-white;

  &:after {
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    border: 1px solid $color-white;
    background: transparent;
    border-radius: 44px;
    top: 20px;
    left: 17px;
  }

  &:before {
    top: 28px;
    left: 25px;
    width: 10px;
    height: 10px;
    position: absolute;
    content: '';
    z-index: 1;
    border-radius: 44px;
  }
}

input[type=radio]:checked + .label:before {
  background-color: $color-black;
}

input[type=radio]:checked + .label {
  background-color: $color-white;
  color: $color-black;
}

input[type=radio]:checked + .label:after {
  border: 1px solid black;
}

input[type=radio]:checked + .label:after {
  border: 1px solid black;
}

input[type=radio]:checked + .incorrectAnswer {
  background-color: #FF6666;
  color: $color-white;

  &:before {
    background-color: $color-white;
  }

  &:after {
    border: 1px solid $color-white
  }
}

input[type=radio]:checked + .correctAnswer {
  background-color: #4CBF8F !important;
  color: $color-white !important;
  border: 1px solid $color-white !important;

  &:before {
    display: block;
    background-color: $color-white !important;
  }

  &:after {
    border: 1px solid $color-white;
  }
}

.showCorrectAnswer {
  border: 1px solid #4CBF8F !important;
  background: rgba(76, 191, 143, 0.2) !important;

  &:before {
    display: none;
  }
}