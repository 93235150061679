@import 'src/styles/common';

.container {
  background-image: url('../../../images/background-images/mystery-shopper-start-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 118px);
  padding: 68px 0 50px;
  text-align: center;

  .wrapper {
    display: flex;
    flex-direction: column;

    .image {
      margin: 0 auto;
      width: 375px;
      height: 196px;

      @media screen and (max-width: 375px) {
        width: 320px;
      }
    }

    .title {
      margin-top: 10px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: $color-white;
    }

    .description {
      margin-top: 15px;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: $color-white;
      text-align: center;
      padding: 0 16px;
    }
  }

  .backButton {
    background: transparent;
    position: absolute;
    top: 25px;
    left: 10px;
  }

  .button {
    height: 60px;
    color: $color-black;
    background: $color-white;
    border-radius: 8px;
    width: calc(100% - 32px);
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
  }
}
