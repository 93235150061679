@import '../../../styles/common';

.inputContainer {
  .checkbox {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid $color-white;
    background: none;
    border-radius: 6px;
    position: relative;

    &:before {
      content: url('../../../images/checked.png');
      position: absolute;
      height: 24px;
      width: 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: .2s;
    }
  }

  .input {
    display: none;
  }

  .input:checked + .checkbox:before {
    opacity: 1;
  }

}
