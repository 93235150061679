@import '../../styles/common';

.get-digital-frame-photo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__video-player {
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  &__canvas {
    position: absolute;
    z-index: 100;
  }

  &__photo-button {
    margin: auto;
    margin-bottom: 30px;
    margin-top: 0px;
    width: calc(100% - 32px);
    height: 60px;
    background: $color-green1;
    color: $color-white;
    border: none;
    border-radius: 8px;
  }
  &__result-button {
    margin: auto;
    margin-bottom: 30px;
    margin-top: 0px;
    width: 100%;
    max-width: 35%;
    height: 60px;
    background: $color-green1;
    color: $color-white;
    border: none;
    border-radius: 8px;
  }

  &__upload-button {
    margin: auto;
    margin-bottom: 10px;
    width: calc(100% - 32px);
    height: 60px;
    background: transparent;
    color: $color-white;
    border: 1px solid $color-green1;
    border-radius: 8px;
  }

  &__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 101;

    &__top {
      width: 100%;
      flex-grow: 0.4;
    }

    &__close-icon-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 40px;
      opacity: 1;
    }

    &__close-icon {
      fill: $color-white;

      width: 15px;
      height: 15px;
      margin-right: 32px;
    }

    &__bottom {
      display: flex;
      height: auto;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      flex-grow: 1;
    }
  }
}

@media only screen and (max-height: 600px) {
  .get-digital-frame-photo {
    &__mask {
      &__frame {
        min-height: 204px;
      }
    }
  }
}
