@import '../../styles/colors';

.container {
  position: relative;
  height: 100%;
  display: flex;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    .imageContainer {
      position: relative;
      display: flex;

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .labels {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;

      .title {
        font-size: 18px;
        text-align: center;
        margin: 5px;
      }

      .description {
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        margin: 5px;
      }
    }

    .button {
      height: 60px;
      width: calc(100% - 32px);
      border-radius: 8px;
      font-size: 17px;
      color: $color-white;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}
