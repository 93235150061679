  @import '../../styles/colors';

  .check-in-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../images/background-images/bg-image-2.png');
  }

  .check-in {
    &__top-section {
      padding-left: 16px;
    padding-right: 16px;
    margin-top: 32px;
    }

    &__description-section {
      margin-top: 20px;
    }
    
    &__top-button {
      background: transparent;
      border: 0;
      padding: 0;
    }
  
    &__top-button:focus {
      outline: none;
    }
  
    &__img-section {
      margin-top: 27px;
    }

    &__top-arrow {
      height: 21px;
    fill: $color-white;
    margin-left: -5px;
    }

    &__bold-text {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 125%;
        margin-bottom: 0px;
        background: transparent;
        color: $color-white; 
    }
    
    &__title {
      margin: 0;
      margin-top: 30px;
      font-size: 24px;
      line-height: 29px;
      color: $color-white;
    }

    &__pre-title {
      max-width: 300px;
      color: $color-white;
      margin: 0;
      margin-top: 5px;
    }

    &__text{
      margin-top: 2px;
      font-style: normal;
      font-size: 14px;
      line-height: 125%;
      background: transparent;
      color: $color-white;
      margin-bottom: 16px;
    }
    
    &__bot-section {
      display: flex;
      flex-flow: column;
    }
  }

    