@import '../../styles/common.scss';

$font_family: SF Pro Display;

.notificationMessageBlock {
  padding: 15px;
  background-color: $color-gray12;
}

.newNotification {
  background-color: $color-blue5;
}

.notificationMessageLink {
  padding: 10px;
}

.notificationMessageContent {
  display: flex;
}

.notificationMessageImageBlock {
  display: flex;
  align-items: center;
  width: 30%;
  margin-right: 16px;

  .image {
    width: 100%;
    height: auto;
    min-height: 100px;
    min-width: 100px;
    border-radius: 8px;
    object-fit: cover;
  }
}

.notificationMessageTextBlock {
  width: 70%;
}

.time {
  margin-bottom: 10px;
  color: $color-gray13;
  font-family: $font_family;
  font-size: 10px;
  font-weight: 400;
}

.caption {
  margin-bottom: 10px;
  color: $color-black;
  font-family: $font_family;
  font-size: 16px;
  font-weight: 600;
}

.text {
  color: $color-black;
  font-family: $font_family;
  font-size: 14px;
  font-weight: 400;
}
