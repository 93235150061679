@import '../../../../styles/common';

$background-color: $color-white;
$text_white_color: $color-white;
$text_black_color: $color-black;
$button_confirm_background_color: $color-aquamarin;
$line_confirm_background_color: $color-aquamarin;
$button_cancel_background_color: $color-white;
$cancel_button_border_color: $color-red1;
$cancel_button_text_color: $color-red1;

.signing-disclaimer-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: $background-color;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  &__content {
    width: calc(100% - 32px);
    color: $text_black_color;

    &-title {
      margin-top: 50px;
      margin-bottom: 20px;
      font-size: 42px;
      font-weight: 700;
      line-height: 50px;
    }

    &-line {
      width: 200px;
      height: 5px;
      border-radius: 3px;
      background: $line_confirm_background_color;
      margin-bottom: 30px;
    }

    &-description {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;

      &-block {
        margin-bottom: 30px;
      }
    }
  }
}

.cancel-button {
  height: 60px;
  width: 100%;
  border: 1px solid $cancel_button_border_color;
  border-radius: 8px;
  margin-bottom: 10px;
  background: $button_cancel_background_color;
  font-size: 17px;
  color: $cancel_button_text_color;
}

.confirm-button {
  height: 60px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 30px;
  background: $button_confirm_background_color;
  font-size: 17px;
  color: $text_white_color;
}
