@import '../../../../styles/common';

.support-stub {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-bottom: 10rem;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.tabaterra_az {
    background: url('./tabaterra_az_stub.jpg');
    background-size: cover;
    background-position: center;
    h1,
    h3 {
      display: none;
    }
  }

  h1 {
    margin: 0;
  }
}
