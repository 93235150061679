@import '../../styles/common';

.boosters-success-page {
  &__content {
    height: calc(100% - 136px);
    margin: 60px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    &-bottom {
      width: 100%;
      &-caption {
        margin-bottom: 10px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
      }

      &-description {
        margin: 0;
        margin-bottom: 40px;
        text-align: center;
        font-size: 18px;
        line-height: 21px;
      }
      &-button {
        width: 100%;
        height: 60px;
        border-radius: 8px;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        color: $color-white;
      }
    }
  }
}
